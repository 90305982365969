import { FormEventHandler, useState } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterOrganization } from '../../../api/auth/use-register-organization';
import { SubmitButton } from '../../../components/library/submit-button';
import { TogglePasswordInputGroup } from '../../../components/library/toggle-password-input-group';
import { useAuth } from '../../../utils/auth/use-auth';
import { isValidEmail } from '../../../utils/is-valid-email';
import { useAuthPagesContext } from '../auth-pages-container/use-auth-pages-context';

export const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email, setEmail, password, setPassword } = useAuthPagesContext();
  const [invalidEmail, setInvalidEmail] = useState<string>('');
  const { termsUrl, privacyUrl } = useAuth();

  const onPhpRegisterError = () => {
    console.error('php register error');
  };

  const onPhpRegisterSuccess = () => {
    console.info('php register success');
    navigate('success');
  };

  const {
    mutate: registerOrganization,
    isLoading,
    error,
    reset,
  } = useRegisterOrganization({ onError: onPhpRegisterError, onSuccess: onPhpRegisterSuccess });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setInvalidEmail(email);
      return;
    }

    setInvalidEmail('');

    registerOrganization({ email, password });
  };

  let errorMessage = '';
  if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    errorMessage = error.message;
  }
  let errorViolations: { propertyPath: string; message: string }[] = [];
  if (
    error &&
    typeof error === 'object' &&
    'violations' in error &&
    typeof error.violations === 'object' &&
    Array.isArray(error.violations)
  ) {
    errorViolations = error.violations.map((violation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      return { propertyPath: violation.propertyPath, message: violation.message };
    });
  }
  const emailError = errorViolations.find(({ propertyPath }) => propertyPath === 'email');
  const passwordError = errorViolations.find(
    ({ propertyPath }) => propertyPath === 'password' || propertyPath === 'plainPassword',
  );

  const onPasswordChange = (password: string) => {
    setPassword(password);
    // reset query state
    reset();
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    // reset query state
    reset();
  };

  return (
    <>
      <h2 className={'mb-5'}>Erstelle dein neues Unternehmenskonto</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="formBasicEmail">
          <FormControl
            type="email"
            value={email}
            name="email"
            placeholder="E-Mail"
            required
            onChange={(e) => onEmailChange(e.target.value)}
            autoFocus
            isInvalid={!!emailError}
          />
          {emailError && <label className="text-danger tw-mt-2">{emailError.message}</label>}
        </FormGroup>

        <FormGroup className="mb-3" controlId="formBasicPassword">
          <TogglePasswordInputGroup password={password} setPassword={onPasswordChange} isInvalid={!!passwordError} />
          {passwordError && <label className="text-danger pt-3">{passwordError.message}</label>}
          <p className={'pt-3 mb-0 text-size-2'}>{t('password_requirements_description')}</p>
        </FormGroup>
        {invalidEmail ? (
          <p className="text-danger tw-mt-2">Bitte geben Sie eine gültige E-Mail-Adresse ein.</p>
        ) : errorMessage ? (
          <p className={'text-danger'}>{JSON.stringify(errorMessage)}</p>
        ) : (
          ''
        )}
        <div className="d-grid gap-2">
          <SubmitButton loading={isLoading} variant={'primary-gradient'}>
            Anmelden
          </SubmitButton>
        </div>
        <p className="mt-6 text-size-2 fw-bold">
          Mit deiner Registrierung akzeptierst du die{' '}
          <a className={'text-decoration-underline'} href={privacyUrl} target={'_blank'} rel="noreferrer">
            Datenschutzrichtlinien
          </a>{' '}
          und{' '}
          <a href={termsUrl} className={'text-decoration-underline'} target={'_blank'} rel="noreferrer">
            AGB
          </a>
        </p>
        <p className="mt-6">
          <Link to="/auth" className={'text-primary fw-bold'}>
            Anmelden mit deinem Konto
          </Link>
          <br />
        </p>
      </Form>
    </>
  );
};
