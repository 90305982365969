import { useEffect, useRef } from 'react';
import { formatTimestamp } from '../../../../utils/format-timestamp';
import { useAuth } from '../../../../utils/auth/use-auth';
import { ImageMessage } from './image-message';
import './messages.scss';
import { TextMessage } from './text-message';
import { Message, MessageType } from './type';
import { useTranslation } from 'react-i18next';

interface MessagesProps {
  messages: Message[];
  className?: string;
  loading?: boolean;
  totalMessageCount: number;
}

export const Messages = ({ messages, className = '', loading, totalMessageCount }: MessagesProps) => {
  const { t } = useTranslation();
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const { firebaseUser } = useAuth();

  const loggedInUserId = firebaseUser?.uid;

  const scrollToBottom = () => {
    // set 1ms timeout to always scroll fully to the bottom and not stop in the middle of the scroll
    setTimeout(() => {
      if (latestMessageRef.current) {
        latestMessageRef?.current?.scrollIntoView({ behavior: 'instant' });
      }
    }, 1);
  };

  // always scroll to bottom if there are new messages
  useEffect(() => {
    scrollToBottom();
  }, [totalMessageCount]);

  const onImageLoaded = (mediaObject?: string) => () => {
    // if the latest message is an image scroll to the bottom once it's loaded
    const latestMessage = messages[messages.length - 1];
    if (mediaObject && latestMessage.type === 'media' && latestMessage.mediaObject === mediaObject) {
      scrollToBottom();
    }
  };

  return (
    <div className={`messages p-5 tw-h-full tw-w-full ${className}`}>
      {loading
        ? t('loading')
        : messages.map((message, i) => {
            const { timestamp, type, userId: messageUserId } = message;

            // align message on the right if it's the message of the logged in user
            const alignMessageRight = String(messageUserId) === loggedInUserId;
            // add timestamp suffix if it's the last message
            const isLastMessage = i === messages.length - 1;
            const suffix = (
              <p className="text-size-2 text-color-gray m-0 pt-1">
                {!!timestamp?.seconds && formatTimestamp(timestamp.seconds)}
              </p>
            );

            const messageProps = {
              message,
              alignRight: alignMessageRight,
              suffix,
            };

            return (
              <div
                key={JSON.stringify(timestamp)}
                ref={isLastMessage ? latestMessageRef : undefined}
                className={`message d-flex w-100 pb-3 ${alignMessageRight ? 'right' : 'left'}`}
              >
                {
                  (
                    {
                      text: <TextMessage {...messageProps} />,
                      media: (
                        <ImageMessage
                          {...messageProps}
                          // scroll to the bottom when an image is loaded
                          // because the height of the container changes
                          onImageLoaded={onImageLoaded}
                        />
                      ),
                    } as Record<MessageType, React.JSX.Element>
                  )[type]
                }
              </div>
            );
          })}
    </div>
  );
};
