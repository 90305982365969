import { Dot } from '../../../library/dot/dot';

interface MessagesButtonProps {
  onClick: () => void;
  className?: string;
  unreadMessageCount?: number;
}

export const MessagesButton = ({ className, onClick, unreadMessageCount = 0 }: MessagesButtonProps) => {
  return (
    <div className={`tw-w-auto tw-flex tw-items-center messages-button cursor-pointer ${className}`} onClick={onClick}>
      <div className="icon-container">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.95247e-06 12.0068C-0.00102813 10.201 0.405547 8.41812 1.18942 6.79122C1.97333 5.16425 3.11436 3.7352 4.52745 2.6106C5.94053 1.48599 7.58923 0.694843 9.35064 0.29612C11.112 -0.102604 12.9407 -0.0986156 14.7004 0.307787C16.46 0.71419 18.1053 1.51252 19.5134 2.64328C20.9216 3.77404 22.0564 5.20805 22.8332 6.83842C23.61 8.4688 24.0088 10.2535 23.9999 12.0594C23.9914 13.7701 23.6173 15.4581 22.9039 17.0106L23.9863 22.9969C24.0412 23.3005 23.9287 23.6101 23.6918 23.8077C23.4549 24.0053 23.1301 24.0603 22.8413 23.9518L18.2712 22.2341C16.5513 23.2883 14.591 23.8933 12.5711 23.9895C10.4029 24.0928 8.24735 23.6061 6.33393 22.5812C4.4205 21.5563 2.8209 20.0315 1.7055 18.1694C0.590145 16.3074 0.000721082 14.1776 2.07168e-06 12.0071M2.73379 7.53533C2.06187 8.92988 1.71337 10.4581 1.71429 12.0061L1.71429 12.0063C1.71487 13.8669 2.22009 15.6924 3.17615 17.2885C4.1322 18.8846 5.50329 20.1915 7.14337 21.07C8.78345 21.9485 10.6311 22.3657 12.4895 22.2772C14.348 22.1886 16.1476 21.5976 17.6967 20.5672C17.9262 20.4146 18.215 20.3816 18.473 20.4785L22.0307 21.8157L21.168 17.0448C21.1355 16.8652 21.1612 16.6799 21.2413 16.516C21.921 15.1252 22.2779 13.5989 22.2856 12.0509C22.2933 10.503 21.9514 8.97325 21.2856 7.57579C20.6198 6.17833 19.6471 4.94918 18.4401 3.97996C17.2331 3.01074 15.8229 2.32645 14.3146 1.9781C12.8063 1.62976 11.2389 1.62634 9.72912 1.9681C8.21934 2.30987 6.80617 2.988 5.59495 3.95194C4.38374 4.91589 3.40571 6.14079 2.73379 7.53533Z"
            fill="#212122"
          />
        </svg>
        {!!(unreadMessageCount && unreadMessageCount !== 0) && <Dot />}
      </div>
      <span className="tw-hidden xl:tw-inline lg:tw-ms-2">Nachrichten</span>
    </div>
  );
};
