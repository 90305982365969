import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMultiResponse } from '../type';
import { Organization } from './type';

type Response = HydraMultiResponse<Organization>;

export const ORGANIZATIONS_QUERY_KEY = 'organizations';

export const useGetOrganizations = () => {
  const { phpToken } = useAuth();

  const getOrganizations = async () => {
    const organizations = await httpFetch<Response>(`/api/organizations.jsonld`, { phpToken });
    return organizations;
  };

  return useQuery<Response>([ORGANIZATIONS_QUERY_KEY, phpToken], getOrganizations);
};
