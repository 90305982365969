import { useEffect } from 'react';
import { useAuth } from '../../utils/auth/use-auth';

// custom logout page for usage in admin panel logout
export default function AdminLogoutPage() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    // route back to admin panel login
    window.location.href = `${import.meta.env.VITE_BACKEND_HOST}/login`;
  }, [logout]);

  return <>Logging out...</>;
}
