import { FormEventHandler, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useRequestPasswordReset } from '../../../api/auth/use-request-password-reset';
import { isValidEmail } from '../../../utils/is-valid-email';
import { useAuthPagesContext } from '../auth-pages-container/use-auth-pages-context';
import { SubmitButton } from '../../../components/library/submit-button';

export const RequestPasswordResetPage = () => {
  const navigate = useNavigate();
  const { email, setEmail, setPassword } = useAuthPagesContext();
  const [invalidEmail, setInvalidEmail] = useState<string>('');

  // clear local password
  useEffectOnce(() => setPassword(''));

  const onRequestPasswordResetError = () => {
    console.error('php request password reset error');
  };

  const onRequestPasswordResetSuccess = () => {
    console.info('php request password reset success');
    navigate('success');
  };

  const {
    mutate: requestPasswordReset,
    isLoading,
    error,
  } = useRequestPasswordReset({ onError: onRequestPasswordResetError, onSuccess: onRequestPasswordResetSuccess });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setInvalidEmail(email);
      return;
    }

    setInvalidEmail('');

    requestPasswordReset({ email });
  };

  return (
    <>
      <h2 className={'mb-5'}>Passwort zurücksetzen</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="email"
            value={email}
            name="email"
            placeholder="E-Mail"
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
        </Form.Group>
        {invalidEmail ? (
          <p className="text-danger">Bitte geben Sie eine gültige E-Mail-Adresse ein.</p>
        ) : error ? (
          <p className={'text-danger'}>{JSON.stringify(error)}</p>
        ) : (
          ''
        )}
        <div className="d-grid gap-2">
          <SubmitButton loading={isLoading} variant={'primary-gradient'}>
            Passwort zurücksetzen
          </SubmitButton>
        </div>
      </Form>
      <p className={'mt-7'}>
        <Link to="/auth" className={'text-primary fw-bold'}>
          Anmelden mit deinem Konto
        </Link>
        <br />
        <Link to="/auth/register/landing" className={'text-primary fw-bold'}>
          Neues Konto erstellen
        </Link>
      </p>
    </>
  );
};
