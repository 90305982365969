import 'react-quill/dist/quill.snow.css';
import './rich-text-editor.scss';
import ReactQuill from 'react-quill';

interface RichTextEditorProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

export const RichTextEditor = ({ value, placeholder = 'Beschreibung eingeben ...', onChange }: RichTextEditorProps) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      modules={{
        toolbar: ['bold', 'italic', 'underline', 'link', { list: 'bullet' }],
      }}
    />
  );
};
