import { useEffect, useState } from 'react';
import { Badge, Offcanvas } from 'react-bootstrap';
import { useUnreadMessageCount } from '../../../api/messaging/use-unread-message-count';
import { useGetOrganization } from '../../../api/organization/use-get-organization';
import { HydraMember } from '../../../api/type';
import { User } from '../../../api/user/type';
import { useGetCurrentPhpUser } from '../../../api/user/use-get-current-php-user';
import { usePostSupportConversation } from '../../../api/user/use-post-support-conversation';
import { extractURLId } from '../../../utils/extract-url-id';
import { PinIcon } from '../icons/pin-icon';
import { SimplyHookedIcon } from '../icons/simply-hooked-icon';
import { Chat } from './chat/chat';
import './conversation-list-item.scss';

interface SupportConversationListItemProps {
  className?: string;
}

export const SupportConversationListItem = ({ className = '' }: SupportConversationListItemProps) => {
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [supportConversationId, setSupportConversationId] = useState<string | undefined>(undefined);
  const { count: unreadMessageCount } = useUnreadMessageCount({ conversationId: supportConversationId });

  const { data: usersResponse } = useGetCurrentPhpUser();

  const user: HydraMember<User> | undefined =
    usersResponse && usersResponse?.['hydra:member']?.length > 0 ? usersResponse?.['hydra:member'][0] : undefined;

  const organizationId: number | undefined = user?.organization ? extractURLId(user?.organization) : undefined;

  const { data: organizationResponse } = useGetOrganization(organizationId);

  const onCreatedSupportConversation = ({ conversationId }: { conversationId: string }) => {
    setSupportConversationId(conversationId);
  };

  const { mutate: createSupportConversation } = usePostSupportConversation({
    onSuccess: onCreatedSupportConversation,
    onError: console.error,
  });

  useEffect(() => {
    if (organizationResponse) {
      if (!organizationResponse.supportConversationId) {
        console.info('Creating support conversation...');
        createSupportConversation();
      } else {
        setSupportConversationId(organizationResponse.supportConversationId);
      }
    }
  }, [createSupportConversation, organizationResponse]);

  const hideChat = () => setChatOpen(false);

  const showChat = () => setChatOpen(true);

  return (
    <div className={`conversation-list-item d-flex justify-content-between ${className}`}>
      <div onClick={showChat} className="d-flex pt-4 pb-4 gap-5 w-100">
        <div className="image-container">
          <div className="simply-hooked-avatar d-flex justify-content-center align-items-center">
            <SimplyHookedIcon />
          </div>
          {!!(unreadMessageCount && unreadMessageCount !== 0) && (
            <Badge bg="primary" className="icon-badge">
              {unreadMessageCount}
            </Badge>
          )}
        </div>
        <div>
          <h4>SimplyHooked</h4>
          <p>Wie können wir dir weiterhelfen?</p>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <PinIcon />
      </div>
      <Offcanvas show={chatOpen} onHide={hideChat} placement={'end'}>
        {supportConversationId && (
          <Chat conversationId={supportConversationId} onClose={hideChat} isSupportConversation={true} />
        )}
      </Offcanvas>
    </div>
  );
};
