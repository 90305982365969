import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { USER_QUERY_KEY } from '../user/use-get-current-php-user';

interface UseResetPasswordProps {
  onSuccess: () => void;
  onError: () => void;
}

export const useRegisterOrganization = ({ onSuccess, onError }: UseResetPasswordProps) => {
  const queryClient = useQueryClient();

  const registerOrganization = ({ email, password }: { email: string; password: string }) => {
    return httpFetch('/api/organizations/register', {
      init: {
        method: 'POST',
        body: JSON.stringify({ email: email, plainPassword: password }),
        headers: { 'Content-Type': 'application/json' },
      },
      isCustomErrorResponse: true,
    });
  };

  return useMutation(registerOrganization, {
    onError: () => onError(),
    onSuccess: async () => {
      await queryClient.refetchQueries([USER_QUERY_KEY]);
      onSuccess();
    },
  });
};
