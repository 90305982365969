import { ChangeEventHandler, useRef } from 'react';
import './image-upload.scss';
import { ImageIcon } from '../icons/image-icon';

interface ImageUploadProps {
  src: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const ImageUpload = ({ src, onChange }: ImageUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFilePicker = () => {
    fileInputRef?.current?.click();
  };

  return (
    <div
      onClick={openFilePicker}
      className="image-upload tw-max-w-xs tw-h-52"
      style={{
        backgroundImage: src ? `url(${src})` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: "center",
        position: 'relative', // Required for overlay positioning
      }}
    >
      <input type="file" accept=".jpg,.png" ref={fileInputRef} onChange={onChange} style={{ display: 'none' }} />
      <div className={`tw-px-6 tw-py-8 overlay ${!src ? 'no-image' : ''}`}>
        <ImageIcon />
        <strong>{src ? 'Bild ersetzen' : 'Bild hochladen'}</strong>
      </div>
    </div>
  );
};
