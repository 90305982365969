import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { USER_QUERY_KEY } from '../user/use-get-current-php-user';

interface UseResetPasswordProps {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const useResetPassword = ({ onSuccess, onError }: UseResetPasswordProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const resetPasswordHandler = ({
    id,
    newPassword,
    currentPassword,
  }: {
    id: number;
    newPassword: string;
    currentPassword: string;
  }) => {
    return httpFetch(`/api/users/${id}`, {
      init: {
        method: 'PATCH',
        body: JSON.stringify({ plainPassword: newPassword, currentPassword }),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Accept: 'application/ld+json',
        },
      },
      parseJsonResponse: false,
      isCustomErrorResponse: true,
      phpToken,
    });
  };

  return useMutation(resetPasswordHandler, {
    onError: (error) => onError(error),
    onSuccess: async () => {
      await queryClient.refetchQueries([USER_QUERY_KEY]);
      onSuccess();
    },
  });
};
