import { useTranslation } from 'react-i18next';
import { useGetInfluencer } from '../../../../api/influencer/use-get-influencer';
import { extractURLId } from '../../../../utils/extract-url-id';
import { MediaKitButton } from './mediakit-button';
import { MessagesButton } from './messages-button';

interface InfluencerInfoProps {
  currentFollowerCount: number;
  handleMediaKitClick: () => void;
  showChat: () => void;
  unreadMessageCount?: number;
  hideIdentity?: boolean;
  influencer?: string;
}

export const InfluencerInfo = ({
  currentFollowerCount,
  handleMediaKitClick,
  showChat,
  unreadMessageCount = 0,
  hideIdentity = false,
  influencer,
}: InfluencerInfoProps) => {
  const { t } = useTranslation();
  const { data: influencerResponse, isLoading } = useGetInfluencer(extractURLId(influencer ?? ''));

  const fullName = isLoading
    ? t('loading')
    : influencerResponse?.firstname
      ? `${influencerResponse?.firstname} ${influencerResponse?.lastname}`
      : 'Name unbekannt';

  return (
    <div className={'tw-flex tw-justify-between tw-self-stretch'}>
      <div className="tw-flex tw-items-center">
        <div>
          <strong>{hideIdentity ? 'Anonym' : fullName}</strong>
          <br />
          {currentFollowerCount} Follower
        </div>
      </div>
      <div className="lg:tw-hidden tw-flex tw-items-center tw-gap-5">
        <MediaKitButton onClick={handleMediaKitClick} className="tw-inline-block" />
        {!hideIdentity && (
          <MessagesButton onClick={showChat} unreadMessageCount={unreadMessageCount} className="tw-inline-block" />
        )}
      </div>
    </div>
  );
};
