import { MouseEventHandler } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteCampaign } from '../../api/campaign/use-delete-campaign';
import { SubmitButton } from '../library/submit-button';

interface DeleteCampaignModalProps {
  campaignId: number;
  show: boolean;
  handleClose: () => void;
}

export const DeleteCampaignModal = ({ campaignId, show, handleClose }: DeleteCampaignModalProps) => {
  const navigate = useNavigate();

  const { mutate: deleteCampaign, isLoading } = useDeleteCampaign({
    onSuccess: () => {
      toast('Die Kampagne wurde erfolgreich gelöscht!', { type: 'success' });
      handleClose();
      navigate('/app/campaigns');
    },
    onError: (error) => {
      console.error('Error submitting proposal:', { error });
      if (error && typeof error === 'object' && 'hadAnyActiveCooperations' in error && error.hadAnyActiveCooperations) {
        toast('Eine Kampagne mit akzeptierten Verhandlungsangeboten kann nicht gelöscht werden.', { type: 'error' });
      } else {
        toast('Beim Löschen der Kampagne gab es einen Fehler.', { type: 'error' });
      }
    },
  });

  const onDeleteClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    deleteCampaign(campaignId);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <strong className="mb-4">Möchtest du wirklich diese Kampagne unwiederbringlich löschen?</strong>
      <div className="model-dialog-actions">
        <SubmitButton onClick={onDeleteClick} variant={'primary-gradient'} loading={isLoading}>
          Kampagne löschen
        </SubmitButton>
        <Button onClick={handleClose} variant="link" className={'btn-primary-text'}>
          Abbrechen
        </Button>
      </div>
    </Modal>
  );
};
