import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import './main.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './utils/auth/auth-provider.tsx';

// init i18n
void i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'de',
    lng: 'de',
    interpolation: { escapeValue: true },
    defaultNS: ['common'],
    ns: ['common'],
  });

// create a react-query client
const queryClient = new QueryClient();

export const Main = () => {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Suspense fallback={<div>{t('loading')}</div>}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ToastContainer position="bottom-right" />
            <App />
          </AuthProvider>
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);
