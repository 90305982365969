import { Dispatch, SetStateAction, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../utils/auth/use-auth';

export interface AuthPagesOutletContext {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  cardClassName: string;
  setCardClassName: Dispatch<SetStateAction<string>>;
}

export const AuthPagesContainer = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [cardClassName, setCardClassName] = useState<string>('tw-w-[486px]');
  const { legalNoticeUrl, termsUrl, privacyUrl } = useAuth();

  return (
    <div className="tw-h-screen tw-overflow-y-auto">
      <div className="tw-h-full tw-flex tw-flex-col">
        <main className="tw-grow tw-flex tw-justify-center tw-items-center tw-p-4">
          <Card className={cardClassName}>
            <Card.Body>
              <Image src="/simply-hooked.svg" alt="Simply Hooked" width={200} height={50} className={'mb-6'} />
              <Outlet context={{ email, setEmail, password, setPassword, cardClassName, setCardClassName } satisfies AuthPagesOutletContext} />
            </Card.Body>
          </Card>
        </main>
        <footer className="tw-flex tw-flex-row tw-gap-5 tw-justify-center tw-p-4 tw-text-[color:var(--sh-color-cool-gray-80)]">
          <span>© Simply-Hooked AG</span>
          {[
            { text: 'AGB', href: termsUrl },
            { text: 'Datenschutz', href: privacyUrl },
            { text: 'Impressum', href: legalNoticeUrl },
          ].map(({ text, href }) => (
            <a key={href} href={href} target="_blank" rel="noreferrer" className="tw-text-inherit">
              {text}
            </a>
          ))}
        </footer>
      </div>
    </div>
  );
};
