import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { Campaign, PartialCampaign } from './type';
import { CAMPAIGNS_QUERY_KEY } from './use-get-campaigns';

interface UsePostCampaignProps {
  onSuccess: (campaign: Campaign) => void;
  onError: (error: unknown) => void;
}

export const usePostCampaign = ({ onSuccess, onError }: UsePostCampaignProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const postCampaignHandler = (campaign: PartialCampaign) => {
    return httpFetch<Campaign>(`/api/campaigns`, {
      init: {
        method: 'POST',
        body: JSON.stringify(campaign),
        headers: { 'Content-Type': 'application/json' },
      },
      isCustomErrorResponse: true,
      phpToken,
    });
  };

  return useMutation(postCampaignHandler, {
    onError: (error) => onError(error),
    onSuccess: (campaign) => {
      void queryClient.refetchQueries([CAMPAIGNS_QUERY_KEY]);
      onSuccess(campaign);
    },
  });
};
