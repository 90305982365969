import { format } from 'date-fns';

/**
 * Formats a message timestamp.
 * @param timestamp UNIX timestamp
 * @returns The formatted string as short date like "10. Jun 09:41"
 */
export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds

  return format(date, 'dd. MMM HH:mm');
};
