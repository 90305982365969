import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CampaignRemunerationType as CampaignDataRemunerationType } from '../../api/@types/campaign-remuneration-type';
import { UpdateFormFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';

interface CampaignRemunerationTypeProps {
  value?: CampaignDataRemunerationType;
  updateForm: UpdateFormFn;
  isPublished: boolean;
}

export const CampaignRemunerationType = ({ value, updateForm, isPublished }: CampaignRemunerationTypeProps) => {
  const { t } = useTranslation();

  return (
    <CampaignFormGroup title={'Vergütungsart'} controlId={'campaignFormCampaignRemunerationType'} required={true}>
      {isPublished ? (
        <span className={'text-size-2'}>{t(`campaignRemunerationType.${value}`, { defaultValue: `${value}` })}</span>
      ) : (
        <>
          <ToggleButtonGroup
            type="radio"
            name="campaignRemunerationType"
            className={'btn-group-with-gutter d-inline-block'}
            onChange={(value) =>
              updateForm({
                name: 'campaignRemunerationType',
                value: value as CampaignDataRemunerationType,
              })
            }
            value={value}
          >
            {Object.values(CampaignDataRemunerationType).map((campaignRemunerationType, idx) => (
              <ToggleButton key={idx} id={`campaignRemunerationType-${idx}`} value={campaignRemunerationType} variant={'outline-secondary'} className={'mb-2'}>
                {t(`campaignRemunerationType.${campaignRemunerationType}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <p className={'text-size-2 pt-2 mb-0'}>
            {value ? (
              <>
                {t(`remunerationTypeDescription.${value}`)}
                <br />
                <br />
              </>
            ) : null}
            Eine Kampagne kann nur einer Vergütungsart zugewiesen werden.
          </p>
        </>
      )}
    </CampaignFormGroup>
  );
};
