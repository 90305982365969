import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useResendRegistrationConfirmEmail } from '../../api/user/use-resend-registration-confirm-email';
import { useAuth } from '../../utils/auth/use-auth';
import { SubmitButton } from './submit-button';

interface UserEmailNotConfirmedModalProps {
  show: boolean;
}

export const UserEmailNotConfirmedModal = ({ show }: UserEmailNotConfirmedModalProps) => {
  const { logout } = useAuth();
  const [successfullyResentEmail, setSuccessfullyResentEmail] = useState(false);
  const {
    mutate: resendRegistrationConfirmEmail,
    isLoading,
    error,
  } = useResendRegistrationConfirmEmail({
    onSuccess: () => {
      console.log('Successfully resent registration confirm email.');
      setSuccessfullyResentEmail(true);
    },
    onError: () => {
      console.error('Failed to resend registration confirm email.');
    },
  });

  const errorMessage =
    error &&
    typeof error === 'object' &&
    'type' in error &&
    'retry_after' in error &&
    typeof error.retry_after === 'number' &&
    typeof error.type === 'string'
      ? error?.type === 'TOO_MANY_REQUESTS'
        ? `Bitte versuche es in ${error.retry_after} Sekunden erneut`
        : 'Ein unbekannter Fehler ist aufgetreten.'
      : null;

  return (
    <Modal show={show} centered backdrop="static">
      <strong className="mb-4">Um Simply-Hooked zu nutzen, musst du zuerst deine E-Mail-Adresse bestätigen.</strong>
      <div className="model-dialog-actions d-flex flex-column flex-md-row gap-2">
        <SubmitButton
          onClick={() => {
            setSuccessfullyResentEmail(false);
            resendRegistrationConfirmEmail();
          }}
          variant="primary-gradient"
          loading={isLoading}
          disabled={isLoading}
        >
          Bestätigungs-E-Mail erneut senden
        </SubmitButton>
        <SubmitButton onClick={logout} variant="outline-primary">
          Abmelden
        </SubmitButton>
      </div>
      {errorMessage ? (
        <p className="text-danger my-0 mt-3">{errorMessage}</p>
      ) : successfullyResentEmail ? (
        <p className="text-success my-0 mt-3">Die Bestätigungs-E-Mail wurde erneut gesendet.</p>
      ) : null}
    </Modal>
  );
};
