import { SVGProps } from 'react';

export const CrossedEyeIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1054_6756)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4415 8.98338C20.7577 8.62997 21.3005 8.59981 21.6539 8.91602C22.333 9.52359 22.9024 10.1111 23.3266 10.5706L23.3331 10.5777L23.3331 10.5777C23.7603 11.0511 23.9968 11.666 23.9968 12.3036C23.9968 12.9413 23.7603 13.5562 23.3331 14.0295L23.3247 14.0388L23.3247 14.0387C22.3955 15.0387 20.8634 16.5239 18.9304 17.764C17.0019 19.0012 14.6161 20.0316 11.9968 20.0316H11.3098C10.8356 20.0316 10.4512 19.6471 10.4512 19.1729C10.4512 18.6987 10.8356 18.3143 11.3098 18.3143H11.9968C14.1687 18.3143 16.2308 17.4556 18.0031 16.3186C19.7676 15.1866 21.1873 13.8155 22.0617 12.875C22.2019 12.7178 22.2794 12.5144 22.2794 12.3036C22.2794 12.0925 22.2016 11.8887 22.0609 11.7314C21.6612 11.2985 21.133 10.7543 20.5088 10.1958C20.1554 9.87963 20.1253 9.33679 20.4415 8.98338Z"
        fill="#3E98FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93114 11.7327C1.79095 11.8899 1.71341 12.0933 1.71341 12.3041C1.71341 12.516 1.79174 12.7203 1.9333 12.8779C3.40787 14.4932 5.1322 15.8614 7.04047 16.9301C7.45422 17.1619 7.60178 17.6851 7.37005 18.0989C7.13833 18.5126 6.61506 18.6602 6.20131 18.4285C4.13181 17.2694 2.26189 15.7856 0.662958 14.0335L0.659756 14.03L0.659766 14.03C0.232566 13.5567 -0.00390625 12.9417 -0.00390625 12.3041C-0.00390625 11.6665 0.232566 11.0515 0.659766 10.5782L0.668113 10.569L0.668179 10.569C1.59731 9.56909 3.12946 8.0838 5.06245 6.84373C6.99097 5.60652 9.37674 4.57617 11.9961 4.57617L12.014 4.57636C14.0467 4.61884 16.036 5.17178 17.7989 6.1843C18.2102 6.42048 18.3521 6.94531 18.1159 7.35654C17.8797 7.76776 17.3549 7.90967 16.9437 7.67349C15.433 6.80588 13.7287 6.3314 11.9872 6.2935C9.81848 6.29584 7.75962 7.15375 5.98975 8.28917C4.22523 9.42117 2.80561 10.7922 1.93114 11.7327Z"
        fill="#3E98FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0477 2.2515C22.3831 2.58682 22.3831 3.1305 22.0477 3.46582L3.15723 22.3563C2.8219 22.6917 2.27823 22.6917 1.9429 22.3563C1.60757 22.021 1.60757 21.4773 1.9429 21.142L20.8334 2.2515C21.1687 1.91617 21.7124 1.91617 22.0477 2.2515Z"
        fill="#3E98FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9946 9.72708C11.3121 9.72756 10.6576 9.99891 10.1749 10.4816C9.69227 10.9642 9.42092 11.6187 9.42044 12.3013C9.4238 12.9827 9.69702 13.6351 10.1803 14.1155C10.5167 14.4498 10.5183 14.9935 10.184 15.3298C9.84965 15.6662 9.30598 15.6678 8.96966 15.3335C8.1634 14.532 7.70793 13.4435 7.70313 12.3067L7.70312 12.3031C7.70312 11.1644 8.15545 10.0724 8.9606 9.26724C9.76575 8.46209 10.8578 8.00977 11.9964 8.00977H12V8.00977C13.1369 8.01457 14.2254 8.47004 15.0268 9.2763C15.3611 9.61262 15.3595 10.1563 15.0232 10.4906C14.6869 10.8249 14.1432 10.8233 13.8089 10.487C13.3284 10.0037 12.676 9.73044 11.9946 9.72708Z"
        fill="#3E98FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4187 13.2798C15.828 13.5192 15.9657 14.0452 15.7262 14.4545C15.3441 15.1077 14.7961 15.6483 14.1377 16.0215C13.7252 16.2554 13.2012 16.1105 12.9673 15.698C12.7334 15.2855 12.8783 14.7614 13.2908 14.5276C13.6858 14.3036 14.0147 13.9793 14.2439 13.5873C14.4834 13.178 15.0093 13.0403 15.4187 13.2798Z"
        fill="#3E98FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1054_6756">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
