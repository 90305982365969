import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { Cooperation } from './type';
import { COOPERATIONS_QUERY_KEY } from './use-get-cooperations';

interface UsePatchCooperationProps {
  onSuccess: () => void;
  onError: () => void;
  cooperationId: number;
}

type CooperationStatusTransition = 'accept' | 'decline' | 'proposal-withdraw' | 'finish';

export const usePatchCooperationStatus = ({ onSuccess, onError, cooperationId }: UsePatchCooperationProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const patchCooperationStatusHandler = (status: CooperationStatusTransition) => {
    return httpFetch<Cooperation>(`/api/cooperations/${cooperationId}/${status}`, {
      init: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        body: JSON.stringify({}),
      },
      parseJsonResponse: false,
      phpToken,
    });
  };

  return useMutation(patchCooperationStatusHandler, {
    onError: () => onError(),
    onSuccess: async () => {
      await queryClient.refetchQueries([COOPERATIONS_QUERY_KEY]);
      onSuccess();
    },
  });
};
