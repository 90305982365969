interface TextBadgeProps {
  children: JSX.Element | string;
  className: string;
}

export const TextBadge = ({ children, className }: TextBadgeProps) => {
  return (
    <div
      className={`tw-w-min tw-text-xs tw-font-medium lh-0 tw-py-5 tw-px-5 
                  tw-uppercase tw-rounded-full 
                  tw-tracking-wide tw-text-[color:var(--sh-color-cool-gray-100)] ${className}`}
    >
      {children}
    </div>
  );
};
