import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AppContainer } from './components/app-container/app-container';
import AdminLogoutPage from './pages/admin-logout/admin-logout-page';
import { AuthPagesContainer } from './pages/auth/auth-pages-container/auth-pages-container';
import { LoginPage } from './pages/auth/login-page/login-page';
import { RegisterPage } from './pages/auth/register-page/register-page';
import { SuccessfulRegisteredPage } from './pages/auth/register-page/successful-registered-page/successful-registered-page';
import { RequestPasswordResetPage } from './pages/auth/request-password-reset-page/request-password-reset-page';
import { SuccessRequestedPasswordResetPage } from './pages/auth/request-password-reset-page/success-requested-password-reset-page/success-requested-password-reset-page';
import { CampaignPage } from './pages/campaigns/campaign-page/campaign-page';
import { CampaignsPage } from './pages/campaigns/campaigns-page';
import { CreateCampaignPage } from './pages/campaigns/create-campaign-page/create-campaign-page';
import { EditCampaignPage } from './pages/campaigns/edit-campaign-page/edit-campaign-page';
import { MessagesPage } from './pages/messages-page/messages-page';
import { OrganizationEditPage } from './pages/organization/organization-edit-page/organization-edit-page';
import { OrganizationPage } from './pages/organization/organization-page';
import { ChangePasswordPage } from './pages/settings/change-password-page/change-password-page';
import { SettingsPage } from './pages/settings/settings-page';
import { MessagesAdminPage } from './pages/messages-page/messages-admin-page/messages-admin-page';
import {RegisterLandingPage} from "./pages/auth/register-page/register-landing-page/register-landing-page.tsx";


export const App = () => {
  return (
    <Routes>
      <Route path="auth" element={<AuthPagesContainer />}>
        <Route index element={<LoginPage />} />
        <Route path="register">
          <Route index element={<RegisterPage />} />
          <Route path="success" element={<SuccessfulRegisteredPage />} />
          <Route path="landing" element={<RegisterLandingPage />} />
        </Route>
        <Route path="reset-password">
          <Route index element={<RequestPasswordResetPage />} />
          <Route path="success" element={<SuccessRequestedPasswordResetPage />} />
        </Route>
        <Route path="admin-logout" element={<AdminLogoutPage />} />
      </Route>

      <Route path="app" element={<AppContainer />}>
        <Route index element={<Navigate to="campaigns" />} />
        <Route path="organization">
          <Route index element={<OrganizationPage />} />
          <Route path="edit" element={<OrganizationEditPage />} />
        </Route>
        <Route path="settings">
          <Route index element={<SettingsPage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />
        </Route>
        <Route path="messages">
          <Route index element={<MessagesPage />} />
          <Route path="admin" element={<MessagesAdminPage />} />
        </Route>
        <Route path="campaigns">
          <Route index element={<CampaignsPage />} />
          <Route path=":id" element={<CampaignPage />} />
          <Route path="create" element={<CreateCampaignPage />} />
          <Route path=":id/edit" element={<EditCampaignPage />} />
        </Route>
      </Route>

      {/* Route 404 paths to "/app/campaigns" */}
      <Route path="*" element={<Navigate to="/app/campaigns" />} />
    </Routes>
  );
};
