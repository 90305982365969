import linkifyHtml from 'linkify-html';
import { SanitizedHtml } from '../../../../utils/sanitized-html';
import './text-message.scss';
import { Message } from './type';

interface TextMessageProps {
  message: Message;
  alignRight: boolean;
  suffix?: React.JSX.Element;
}

export const TextMessage = ({ message: { text = '' }, alignRight, suffix }: TextMessageProps) => {
  // convert text into HTML with clickable links
  const htmlWithLinks = linkifyHtml(text, {
    defaultProtocol: 'https', // set default protocol for URLs without a protocol
    attributes: {
      target: '_blank',
      class: 'inline',
    },
  });

  return (
    <div className={`text-message text-size-h5 ${alignRight ? 'right' : 'left'}`}>
      <SanitizedHtml unsanitizedHtml={htmlWithLinks} className="text-break" />
      {suffix}
    </div>
  );
};
