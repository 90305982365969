import { Offcanvas } from 'react-bootstrap';
import { useGetMediakit } from '../../api/influencer/use-get-mediakit';
import { Mediakit } from './mediakit';

interface OffcanvasMediakitProps {
  show: boolean;
  onClose: () => void;
  influencerId?: number;
  cooperationId: number;
  hideIdentity: boolean;
}

export const OffcanvasMediakit = ({
  show,
  onClose,
  influencerId,
  cooperationId,
  hideIdentity = false,
}: OffcanvasMediakitProps) => {
  const { data: mediakitResponse } = useGetMediakit(influencerId);

  return (
    <Offcanvas show={show} onHide={onClose} placement={'end'} style={{ width: 766 }}>
      <div className="h-100 d-flex flex-column">
        <div className="flex-grow-0 w-100 d-flex justify-content-between border-bottom px-5 py-3">
          <strong>
            {hideIdentity
              ? 'Anonym'
              : mediakitResponse
                ? mediakitResponse.firstname
                  ? `${mediakitResponse.firstname} ${mediakitResponse.lastname ?? ''}`
                  : 'Name unbekannt'
                : 'Loading...'}
          </strong>
          <a onClick={onClose} className={'text-primary fw-semibold cursor-pointer'}>
            Schliessen
          </a>
        </div>
        <div className="overflow-auto flex-grow-1 overflow-y-auto">
          <Mediakit influencerId={influencerId} cooperationId={cooperationId} hideIdentity={hideIdentity} />
        </div>
      </div>
    </Offcanvas>
  );
};
