import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CountryType } from '../../api/@types/country-type';
import { CampaignFormGroup } from '../campaign-form/campaign-form-group';
import { PartialBillingAddress } from '../../api/organization/type';
import { UpdateFormFn } from './organization-form';
import { ChangeEventHandler } from 'react';

interface OrganizationBillingAddressProps {
  value?: PartialBillingAddress;
  updateForm: UpdateFormFn;
}

export const OrganizationBillingAddress = ({ value, updateForm }: OrganizationBillingAddressProps) => {
  const { t } = useTranslation();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateForm({ name: e.target.name, value: e.target.value });
  };

  const onChangeSelection: ChangeEventHandler<HTMLSelectElement> = (e) => {
    updateForm({ name: e.target.name, value: e.target.value });
  };

  return (
    <CampaignFormGroup title={'Rechnungsadresse'} controlId={'billingAddress'} hideBorder>
      <Form.Control
        type="text"
        value={value?.street ?? ''}
        placeholder="Strasse ..."
        onChange={onChange}
        name={'street'}
        required
        className="mb-3"
      />
      <Row>
        <Col lg={4}>
          <Form.Control
            type="text"
            value={value?.zipCode ?? ''}
            placeholder="Postleitzahl ..."
            onChange={onChange}
            name={'zipCode'}
            required
            className="mb-3"
          />
        </Col>
        <Col lg={8}>
          <Form.Control
            type="text"
            value={value?.city ?? ''}
            placeholder="Ort ..."
            onChange={onChange}
            name={'city'}
            required
            className="mb-3"
          />
        </Col>
      </Row>
      <Form.Select
        value={value?.country ?? ''}
        aria-label="Land ..."
        name={'country'}
        className="mb-3"
        required
        onChange={onChangeSelection}
      >
        <option value="">Land ...</option>
        {Object.values(CountryType).map((countryType) => (
          <option key={countryType} value={countryType}>
            {t(`countryType.${countryType}`, { defaultValue: countryType })}
          </option>
        ))}
      </Form.Select>
    </CampaignFormGroup>
  );
};
