import { SupportConversationList } from '../../../components/library/messaging/admin/support-conversation-list';
import PageContainer from '../../../components/page-container';

export const MessagesAdminPage = () => {
  return (
    <PageContainer title="Chat">
      <SupportConversationList />
    </PageContainer>
  );
};
