import Cookies from 'js-cookie';

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, { secure: true, sameSite: 'strict' });
  // fallback: set cookie manually to make it work in an iframe
  window.document.cookie = `${key}=${value}; Secure; SameSite=None; path=/`;
};

export const unsetCookie = (key: string) => {
  Cookies.remove(key);
  // fallback: remove cookie manually to make it work in an iframe
  window.document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None; path=/`;
};

export const getCookie = (key: string) => {
  let value = Cookies.get(key);
  if (!value) {
    // fallback: get cookie manually to make it work in an iframe
    value = readCookieFallback(key);
  }
  return value;
};

const readCookieFallback = (cookieName: string) => {
  const urlCookieName = cookieName + '=';
  const decodedCookie = decodeURIComponent(window.document.cookie);
  const cookies = decodedCookie.split(';');
  for (const cookie of cookies) {
    let trimmedCookie = cookie;
    while (trimmedCookie.startsWith(' ')) {
      trimmedCookie = trimmedCookie.substring(1);
    }
    if (cookie.startsWith(urlCookieName)) {
      return cookie.substring(urlCookieName.length, cookie.length);
    }
  }
  return '';
};
