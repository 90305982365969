import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CampaignType as CampaignDataType } from '../../api/@types/campaign-type';
import { UpdateFormFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';

interface CampaignTypeProps {
  value?: CampaignDataType;
  updateForm: UpdateFormFn;
  isPublished: boolean;
}

export const CampaignType = ({ value, updateForm, isPublished }: CampaignTypeProps) => {
  const { t } = useTranslation();

  return (
    <CampaignFormGroup title={'Kampagnenart'} controlId={'campaignFormCampaignType'} required={true}>
      {isPublished ? (
        <span className={'text-size-2'}>{t(`campaignType.${value}`, { defaultValue: `${value}` })}</span>
      ) : (
        <>
          <ToggleButtonGroup
            type="radio"
            name="campaignType"
            className={'btn-group-with-gutter d-inline-block'}
            onChange={(value) =>
              updateForm({
                name: 'campaignType',
                value: value as CampaignDataType,
              })
            }
            value={value}
          >
            {Object.values(CampaignDataType).map((campaignType, idx) => (
              <ToggleButton key={idx} id={`campaignType-${idx}`} value={campaignType} variant={'outline-secondary'} className={'mb-2'}>
                {t(`campaignType.${campaignType}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <p className={'text-size-2 pt-2 mb-0'}>
            {value ? (
              <>
                {t(`campaignTypeDescription.${value}`)}
                <br />
                <br />
              </>
            ) : null}
            Eine Kampagne kann nur einer Kampagnenart zugewiesen werden.
          </p>
        </>
      )}
    </CampaignFormGroup>
  );
};
