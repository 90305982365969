import { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetPassword } from '../../../api/auth/use-reset-password';
import { HydraMember } from '../../../api/type';
import { User } from '../../../api/user/type';
import { useGetCurrentPhpUser } from '../../../api/user/use-get-current-php-user';
import { FullscreenModal } from '../../../components/fullscreen-modal/fullscreen-modal';
import { LabeledFormGroupRow } from '../../../components/library/labeled-form-group-row/labeled-form-group-row';
import { SubmitButton } from '../../../components/library/submit-button';
import { extractURLId } from '../../../utils/extract-url-id';
import './change-password-page.scss';

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
}

interface ChangePasswordFormErrors {
  currentPassword?: string;
  newPassword?: string;
  repeatedNewPassword?: string;
}

export const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, data: usersResponse } = useGetCurrentPhpUser();

  const user: HydraMember<User> | undefined =
    usersResponse && usersResponse?.['hydra:member']?.length > 0 ? usersResponse?.['hydra:member'][0] : undefined;

  const [{ currentPassword, newPassword, repeatedNewPassword }, setFormData] = useState<ChangePasswordForm>({
    currentPassword: '',
    newPassword: '',
    repeatedNewPassword: '',
  });
  const [errors, setErrors] = useState<ChangePasswordFormErrors>({
    currentPassword: '',
    newPassword: '',
    repeatedNewPassword: '',
  });

  const savingDisabled = !currentPassword?.trim() || !newPassword?.trim() || !repeatedNewPassword?.trim();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onPasswordResetSuccess = () => {
    toast('Das Passwort wurde erfolgreich gespeichert!', { type: 'success' });
    navigate('/app/settings');
  };

  const onPasswordResetError = (error: unknown) => {
    console.error('Error submitting proposal:', error);
    toast('Beim Speichern des Passwortes gab es einen Fehler. Bitte prüfe deine Eingabe', { type: 'error' });

    const formErrors: ChangePasswordFormErrors = {};
    if (error && typeof error === 'object' && 'code' in error && error.code === 401) {
      // current password is wrong
      formErrors.currentPassword = 'Das eingegebene Passwort ist nicht korrekt.';
    }
    setErrors(formErrors);
  };

  const {
    mutate: resetPassword,
    reset: resetPasswordReset,
    isLoading: isPatchLoading,
    error,
  } = useResetPassword({ onError: onPasswordResetError, onSuccess: onPasswordResetSuccess });

  let errorViolations: { propertyPath: string; message: string }[] = [];
  if (
    error &&
    typeof error === 'object' &&
    'violations' in error &&
    typeof error.violations === 'object' &&
    Array.isArray(error.violations)
  ) {
    errorViolations = error.violations.map((violation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      return { propertyPath: violation.propertyPath, message: violation.message };
    });
  }
  const passwordError = errorViolations.find(
    ({ propertyPath }) => propertyPath === 'password' || propertyPath === 'plainPassword',
  );

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    resetPasswordReset();

    // show error if repeated new password doesn't match new password
    if (newPassword !== repeatedNewPassword) {
      setErrors({ ...errors, repeatedNewPassword: 'Dieses Passwort entspricht nicht dem neuem Passwort.' });
      return;
    }

    if (user?.['@id']) {
      resetPassword({
        id: extractURLId(user?.['@id']),
        newPassword,
        currentPassword,
      });
    }
  };

  const onCancelClick = () => navigate(`/app/settings`);

  const getActions = () => {
    return (
      <>
        <Button variant={'link'} onClick={onCancelClick}>
          Abbrechen
        </Button>
        <SubmitButton
          onClick={handleSubmit}
          loading={isPatchLoading}
          disabled={isLoading || isPatchLoading || savingDisabled}
          variant={'primary-gradient'}
        >
          Speichern
        </SubmitButton>
      </>
    );
  };

  return (
    <FullscreenModal title="Passwort ändern" actions={getActions()}>
      <Container
        fluid={true}
        className="h-100 d-flex justify-content-center align-items-center change-password-container"
      >
        <Form>
          <LabeledFormGroupRow title="Bisheriges Passwort" className="pb-5">
            <Form.Control
              type="password"
              value={currentPassword}
              placeholder="Bisheriges Passwort"
              onChange={handleInputChange}
              name={'currentPassword'}
              isInvalid={!!errors.currentPassword}
              autoFocus
              required
            />
            <Form.Control.Feedback type="invalid">{errors.currentPassword}</Form.Control.Feedback>
          </LabeledFormGroupRow>
          <LabeledFormGroupRow title={'Neues Passwort'} className="pb-5">
            <Form.Control
              type="password"
              value={newPassword}
              placeholder="Neues Passwort ..."
              onChange={handleInputChange}
              name={'newPassword'}
              isInvalid={!!passwordError}
              required
            />
            {passwordError ? <label className="text-danger pt-3">{passwordError.message}</label> : <></>}
            <p className={'pt-3 mb-0 text-size-2'}>{t('password_requirements_description')}</p>
            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
          </LabeledFormGroupRow>
          <LabeledFormGroupRow title={'Neues Passwort wiederholen'} className="pb-5">
            <Form.Control
              type="password"
              value={repeatedNewPassword}
              placeholder="Neues Passwort wiederholen ..."
              onChange={handleInputChange}
              name={'repeatedNewPassword'}
              isInvalid={!!errors.repeatedNewPassword}
              required
            />
            <Form.Control.Feedback type="invalid">{errors.repeatedNewPassword}</Form.Control.Feedback>
          </LabeledFormGroupRow>
        </Form>
      </Container>
    </FullscreenModal>
  );
};
