import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UpdateFormFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';
import { PlatformType } from '../../api/@types/platform-type';

interface CampaignPlatformTypeProps {
  value?: PlatformType;
  updateForm: UpdateFormFn;
  isPublished: boolean;
}

export const CampaignPlatformType = ({ value, updateForm, isPublished }: CampaignPlatformTypeProps) => {
  const { t } = useTranslation();

  return (
    <CampaignFormGroup title={'Plattform'} controlId={'campaignFormPlatformType'} required={true}>
      {isPublished ? (
        <span className={'text-size-2'}>{t(`platformType.${value}`, { defaultValue: `${value}` })}</span>
      ) : (
        <>
          <ToggleButtonGroup
            type="radio"
            name="platformType"
            className={'btn-group-with-gutter'}
            onChange={(value) =>
              updateForm({
                name: 'platformType',
                value: value as PlatformType,
              })
            }
            value={value}
          >
            {Object.values(PlatformType).map((platformType, idx) => (
              <ToggleButton key={idx} id={`platformType-${idx}`} value={platformType} variant={'outline-secondary'}>
                {t(`platformType.${platformType}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <p className={'text-size-2 pt-3 mb-0'}>
            Eine Kampagne kann nur einer Social Media Plattform zugewiesen werden.{' '}
          </p>
        </>
      )}
    </CampaignFormGroup>
  );
};
