import { Form } from 'react-bootstrap';
import { CampaignFormGroup } from '../campaign-form/campaign-form-group';
import { ChangeEventHandler } from 'react';
import { UpdateFormFn } from './organization-form';

interface OrganizationNameProps {
  value?: string;
  updateForm: UpdateFormFn;
  error: string;
}

export const OrganizationName = ({ value, updateForm, error }: OrganizationNameProps) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateForm({ name: e.target.name, value: e.target.value });
  };

  return (
    <CampaignFormGroup title={'Unternehmensname'} controlId={'name'} hideBorder>
      <Form.Control
        type="text"
        value={value ?? ''}
        placeholder="Musterfirma AG ..."
        onChange={onChange}
        name={'name'}
        isInvalid={!!error}
        autoFocus
        required
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      <p className={'pt-3 text-size-2'}>Vollständigen Unternehmensnamen angeben.</p>
    </CampaignFormGroup>
  );
};
