import { useEffect, useState } from 'react';
import { DOMPURIFY_CONFIG } from '../config/dompurify-config';
import DOMPurify from 'dompurify';

interface SanitizeHtmlProps {
  unsanitizedHtml: string;
  className?: string;
}

// sanitizes and renders unsanitizedHtml
export const SanitizedHtml = ({ unsanitizedHtml, className = '' }: SanitizeHtmlProps) => {
  const [sanitizedHtml, setSanitizedHtml] = useState<string>('');

  useEffect(() => {
    setSanitizedHtml(DOMPurify.sanitize(unsanitizedHtml, DOMPURIFY_CONFIG));
  }, [unsanitizedHtml]);

  return <span className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
