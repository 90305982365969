import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Organization } from '../../api/organization/type';
import { useGetOrganizations } from '../../api/organization/use-get-organizations';
import { HydraMember } from '../../api/type';
import { LoadingBoundary } from '../../components/library/loading-boundary';
import { NoResultMessage } from '../../components/library/no-result-message/no-result-message';
import PageContainer from '../../components/page-container';
import { isOrganizationDataComplete } from '../../utils/is-organization-data-complete';

export const OrganizationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: organizations, isLoading, isError, error } = useGetOrganizations();

  const organization: HydraMember<Organization> | undefined =
    organizations && organizations?.['hydra:member']?.length > 0 ? organizations?.['hydra:member'][0] : undefined;

  const { name, billingAddress, contact, email } = organization ?? {};
  const { street, city, zipCode, country } = billingAddress ?? {};

  const missingOrganisationData = !isOrganizationDataComplete(organization);

  const onEditClick = () => navigate('edit');

  return (
    <PageContainer
      title="Unternehmensdaten"
      action={
        <>
          {missingOrganisationData ? (
            ''
          ) : (
            <Button onClick={onEditClick} className={'btn-primary-gradient'}>
              Bearbeiten
            </Button>
          )}
        </>
      }
    >
      <LoadingBoundary error={error} isError={isError} isLoading={isLoading}>
        {missingOrganisationData ? (
          <NoResultMessage
            message={
              <>
                <p>Trage bitte alle erforderlichen Unternehmensdaten ein.</p>
                <Button onClick={onEditClick} className={'btn-primary-gradient btn-wrap'}>
                  Unternehmensdaten erfassen
                </Button>
              </>
            }
          />
        ) : (
          <>
            <h4 className={'fw-semibold mt-3'}>Unternehmensname</h4>
            {name}
            <h4 className={'fw-semibold mt-3'}>Rechnungsadresse</h4>
            {street}
            <br />
            {zipCode} {city}
            <br />
            {country && t(`countryType.${country}`, { defaultValue: country })}
            <h4 className={'fw-semibold mt-3'}>Kontaktperson</h4>
            {contact}
            <br />
            {email}
          </>
        )}
      </LoadingBoundary>
    </PageContainer>
  );
};
