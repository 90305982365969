import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { USER_QUERY_KEY } from '../user/use-get-current-php-user';

interface UseResetPasswordProps {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const usePhpLogin = ({ onSuccess, onError }: UseResetPasswordProps) => {
  const queryClient = useQueryClient();
  const { onLoggedIn } = useAuth();

  const login = async ({ email, password }: { email: string; password: string }) => {
    const response = await httpFetch<LoginResponse>('/auth', {
      init: {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: { 'Content-Type': 'application/json' },
      },
      isCustomErrorResponse: true,
      parseJsonResponse: true,
    });

    const { token: phpToken, refresh_token: phpRefreshToken, firebase_token: firebaseToken, dashboard_access_allowed: dashboardAccessAllowed } = response;

    if (!dashboardAccessAllowed) {
        throw new Error('Zugriff auf das Dashboard nicht erlaubt.');
    }

    onLoggedIn({ phpToken, phpRefreshToken, firebaseToken });
  };

  return useMutation(login, {
    onError: onError,
    onSuccess: async () => {
      await queryClient.refetchQueries([USER_QUERY_KEY]);
      onSuccess();
    },
  });
};

interface LoginResponse {
  token: string;
  refresh_token: string;
  firebase_token: string;
    dashboard_access_allowed: boolean;
}
