import { useNavigate } from 'react-router-dom';
import { OrganizationEditModal } from '../../../components/organization-form/organization-edit-modal';

export const OrganizationEditPage = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate('/app/organization');
  };

  const onCancel = () => {
    navigate(`/app/organization/`);
  };

  return <OrganizationEditModal onSuccess={onSuccess} onCancel={onCancel} />;
};
