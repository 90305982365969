import { MouseEventHandler } from 'react';
import { FormCheckProps } from 'react-bootstrap';
import './checkbox.scss';

interface CheckboxProps {
  label: string;
  onClick: MouseEventHandler;
}

export const Checkbox = (props: CheckboxProps & FormCheckProps) => {
  const { onClick, label } = props;

  return (
    <div className="checkbox" onClick={onClick}>
      <label>
        <input
          type="checkbox"
          {...props}
          onChange={() => {
            /* empty onchange because event is handled by onClick above */
          }}
        />
        <span></span>
      </label>
      <span className="tw-hyphens-auto">{label}</span>
    </div>
  );
};
