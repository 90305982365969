import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Organization } from '../../api/organization/type';
import { useGetOrganizations } from '../../api/organization/use-get-organizations';
import { HydraMember } from '../../api/type';
import { isOrganizationDataComplete } from '../../utils/is-organization-data-complete';
import { OrganizationEditModal } from '../organization-form/organization-edit-modal';
import { UserStatus } from '../../api/@types/user-status';

interface CampaignDraftWarningProps {
  className?: string;
}

export const CampaignDraftWarning = ({ className }: CampaignDraftWarningProps) => {
  const { data: organizations, isLoading, isError } = useGetOrganizations();
  const organization: HydraMember<Organization> | undefined =
    organizations && organizations?.['hydra:member']?.length > 0 ? organizations?.['hydra:member'][0] : undefined;
  const [editOrganizationModalOpen, setEditOrganizationModalOpen] = useState<boolean>(false);

  const missingOrganisationData = !isLoading && !isError && !isOrganizationDataComplete(organization);
  const isOrganizationWaitingForValidation = organization?.status === UserStatus.ReadyForValidation;

  const onEditOrganizationSuccess = () => {
    setEditOrganizationModalOpen(false);
  };

  const onEditOrganizationClick = () => {
    setEditOrganizationModalOpen(true);
  };

  const onEditOrganizationCancel = () => {
    setEditOrganizationModalOpen(false);
  };

  return missingOrganisationData ? (
    <div className={className}>
      <OrganizationEditModal
        onSuccess={onEditOrganizationSuccess}
        onCancel={onEditOrganizationCancel}
        open={editOrganizationModalOpen}
      />
      <Card className="tw-max-w-7xl">
        <Card.Body>
          <p>
            <strong>
              Influencer können Kampagnen erst sehen, sobald alle erforderlichen Unternehmensdaten erfasst und
              verifiziert sind.
            </strong>
          </p>
          <Button onClick={onEditOrganizationClick} className={'btn-primary-gradient btn-wrap'}>
            Unternehmensdaten erfassen
          </Button>
        </Card.Body>
      </Card>
    </div>
  ) : isOrganizationWaitingForValidation ? (
    <div className={className}>
      <Card className="tw-max-w-7xl">
        <Card.Body>
          <strong>Influencer können Kampagnen erst sehen, sobald wir dein Unternehmen verifiziert haben.</strong>
        </Card.Body>
      </Card>
    </div>
  ) : (
    <></>
  );
};
