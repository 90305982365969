import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { UserStatus } from './type';
import { USER_QUERY_KEY } from './use-get-current-php-user';

type Response = UserStatus;

export const useGetCurrentPhpUserStatus = () => {
  const { phpToken } = useAuth();

  const getCurrentPhpUserStatus = async () => {
    const user = await httpFetch<Response>('/api/auth/user_status', {
      phpToken,
    });
    return user;
  };

  return useQuery<Response>([USER_QUERY_KEY, phpToken], getCurrentPhpUserStatus, { enabled: !!phpToken });
};
