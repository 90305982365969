import dayjs from 'dayjs';
import { useState } from 'react';
import { ButtonGroup, Col, Dropdown, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCampaign } from '../../../api/campaign/use-get-campaign';
import { useGetCooperations } from '../../../api/cooperation/use-get-cooperations';
import { CampaignDraftWarning } from '../../../components/campaign-draft-warning/campaign-draft-warning';
import { CampaignStatusBadge } from '../../../components/campaign-status-badge';
import { CooperationList } from '../../../components/cooperation-list/cooperations-list';
import { DeleteCampaignModal } from '../../../components/delete-campaign-modal/delete-campaign-modal';
import { DetailPageContainer } from '../../../components/detail-page-container';
import { FilterToggleButton } from '../../../components/library/filter-toggle-button';
import { LoadingBoundary } from '../../../components/library/loading-boundary';
import { cooperationSorterMap } from '../../../utils/cooperation-sorter-map';
import {
  FilterOptionValue,
  getCooperationStatusOfFilterValue,
} from '../../../utils/get-cooperation-status-of-filter-value';
import { useSearchParamValue } from '../../../utils/use-search-param-value';
import { OffcanvasCampaign } from './offcanvas-campaign';

interface FilterOption {
  name: string;
  value: FilterOptionValue;
}

const AVAILABLE_FILTERS: FilterOption[] = [
  { name: 'Bewerbungen', value: 'applied' },
  { name: 'Verhandlungen', value: 'negotiating' },
  { name: 'Aktiv', value: 'active' },
  { name: 'Abgelehnt', value: 'declined' },
  { name: 'Abgeschlossen', value: 'archived' },
];

export const CampaignPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showOffcanvasDetails, setShowOffcanvasDetails] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [filter, setFilter] = useSearchParamValue<FilterOptionValue>('filter', {
    defaultValue: 'applied',
    createNewHistoryEntry: true,
  });
  const cooperationStatus = getCooperationStatusOfFilterValue(filter);

  let campaignId: number | undefined = undefined;
  try {
    if (!id) throw new Error('Id not set');
    campaignId = parseInt(id);
    if (!campaignId) throw new Error('Parsed id is loosely false');
  } catch (error) {
    console.error('Failed to parse id param. Routing back to campaigns overview', { id, error });
    navigate('/app/campaigns');
  }

  const { data: campaign, isError, isLoading, error } = useGetCampaign(campaignId);

  const {
    data: cooperationsResponse,
    isLoading: isCooperationsLoading,
    isError: hasCooperationsError,
    error: cooperationsError,
  } = useGetCooperations({ campaignId, status: cooperationStatus });

  const cooperations = cooperationsResponse?.['hydra:member'] ?? [];

  const sortedCooperationItems = cooperations.sort(cooperationSorterMap[filter ?? 'applied']);

  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleCloseOffcanvasDetails = () => setShowOffcanvasDetails(false);

  const handleShowOffcanvasDetails = () => setShowOffcanvasDetails(true);

  const handleFilterChange = (val: FilterOptionValue) => {
    setFilter(val);
  };

  const onEditClick = () => {
    navigate(`edit`);
  };

  const getActionDropdown = () => {
    return (
      <Dropdown.Menu className={'shadow border-0'}>
        <Dropdown.Item className={'text-primary'} onClick={handleShowOffcanvasDetails}>
          Ansehen
        </Dropdown.Item>
        <Dropdown.Item className={'text-primary lh-1'} onClick={onEditClick}>
          Bearbeiten
        </Dropdown.Item>
        <Dropdown.Item className={'text-danger'} onClick={handleShowDeleteModal}>
          Löschen
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  };

  return (
    <DetailPageContainer
      title={campaign?.name ?? t('loading')}
      backLink={'/app/campaigns'}
      dropdownMenu={getActionDropdown()}
    >
      <div className="max-lg:tw-w-full tw-py-5 lg:tw-py-10">
        <CampaignDraftWarning className="tw-px-5 lg:tw-px-10 tw-pb-5 lg:tw-pb-10" />
        <LoadingBoundary
          error={error}
          isError={isError}
          isLoading={isLoading}
          loadingElement={<div className="tw-px-5 lg:tw-px-10">{t('loading')}</div>}
        >
          {!campaign || !campaignId ? (
            <div className="tw-px-5 lg:tw-px-10">Kampagne nicht gefunden.</div>
          ) : (
            <div className="tw-relative">
              <div className="tw-px-5 lg:tw-px-10">
                <Row className="pb-5">
                  <Col className="col-3">
                    {campaign.teaserImage && (
                      <Image src={campaign.teaserImage.contentUrl} className="rounded img-fluid" alt="..." />
                    )}
                  </Col>
                  <div className="col-9">
                    <CampaignStatusBadge campaignStatus={campaign.status} />
                    <h4 className={'fw-semibold mt-3'}>{campaign.name}</h4>
                    <p>
                      {t(`platformType.${campaign.platformType}`)}: {t(`campaignType.${campaign.campaignType}`)}
                      <br />
                      {dayjs(campaign.startDate).format('DD.MM.YYYY')} - {dayjs(campaign.endDate).format('DD.MM.YYYY')}
                      <br />
                    </p>
                    <a
                      onClick={handleShowOffcanvasDetails}
                      href="#"
                      className={'text-primary fw-bold mt-3 tw-cursor-pointer'}
                    >
                      Kampagnenbriefing
                    </a>
                  </div>
                </Row>
              </div>
              <div className="background tw-sticky tw-top-0 tw-z-10">
                <div className="tw-w-full tw-overflow-x-auto">
                  <div className="tw-py-4 tw-px-5 lg:tw-px-10">
                    <ButtonGroup className={'btn-group-with-gutter'} defaultValue={AVAILABLE_FILTERS[0].value}>
                      {AVAILABLE_FILTERS.map((option, idx) => (
                        <FilterToggleButton
                          key={option.value}
                          campaignId={campaignId}
                          selectedValue={filter}
                          handleFilterChange={handleFilterChange}
                          option={option}
                          idx={idx}
                          className={idx === AVAILABLE_FILTERS.length - 1 ? 'me-4' : ''}
                        />
                      ))}
                    </ButtonGroup>
                  </div>
                </div>
                {sortedCooperationItems.length > 0 && <div className="border-bottom tw-mx-5 lg:tw-mx-10"></div>}
              </div>
              <div className="tw-px-5 lg:tw-px-10">
                <CooperationList
                  campaign={campaign}
                  cooperations={sortedCooperationItems}
                  isLoading={isCooperationsLoading}
                  isError={hasCooperationsError}
                  error={cooperationsError}
                />
              </div>
              <OffcanvasCampaign
                campaign={campaign}
                show={showOffcanvasDetails}
                onHide={handleCloseOffcanvasDetails}
                onEditClick={onEditClick}
              />
              <DeleteCampaignModal
                campaignId={campaignId}
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
              />
            </div>
          )}
        </LoadingBoundary>
      </div>
    </DetailPageContainer>
  );
};
