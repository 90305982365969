import { MediakitIcon } from '../../../library/icons/mediakit-icon';

interface MediakitButtonProps {
  onClick: () => void;
  className?: string;
}

export const MediaKitButton = ({ onClick, className }: MediakitButtonProps) => {
  return (
    <div className={`tw-flex tw-items-center cursor-pointer ${className}`} onClick={onClick}>
      <MediakitIcon />
      <span className="tw-hidden xl:tw-inline lg:tw-ms-2">Mediakit</span>
    </div>
  );
};
