import { MouseEventHandler, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CampaignType } from '../../../api/@types/campaign-type';
import { PartialCampaign } from '../../../api/campaign/type';
import { useGetCampaign } from '../../../api/campaign/use-get-campaign';
import { usePatchCampaign } from '../../../api/campaign/use-patch-campaign';
import { usePostCampaignMediaObject } from '../../../api/campaign/use-post-campaign-media-object';
import { MediaObject } from '../../../api/mediaobject/type';
import { HydraMember } from '../../../api/type';
import { CampaignForm } from '../../../components/campaign-form/campaign-form';
import { FullscreenModal } from '../../../components/fullscreen-modal/fullscreen-modal';
import { LoadingBoundary } from '../../../components/library/loading-boundary';
import { SubmitButton } from '../../../components/library/submit-button';
import { extractURLId } from '../../../utils/extract-url-id';
import { getApiErrorViolationsMap } from '../../../utils/get-api-error-violations-map';

export const EditCampaignPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  let campaignId: number | undefined = undefined;
  try {
    if (!id) throw new Error('Id not set');
    campaignId = parseInt(id);
    if (!campaignId) throw new Error('Parsed id is loosely false');
  } catch (error) {
    console.error('Failed to parse id param. Routing back to campaigns overview', { id, error });
    navigate('/app/campaigns');
  }

  const { data: campaign, isError, isLoading, error } = useGetCampaign(campaignId);

  const [selectedImage, setSelectedImage] = useState<File>();
  const [formData, setFormData] = useState<PartialCampaign>({});

  const campaignWithChanges: PartialCampaign = {
    ...campaign,
    categories: (campaign?.categories ?? []).map((c) => `${extractURLId(c)}`),
    ...formData,
    internalId: `${campaignId}`,
  };
  const { name, campaignType, platformType, startDate, endDate, remuneration, categories, currency } =
    campaignWithChanges;

  const publishingDisabled =
    !name?.trim() ||
    !campaignType ||
    !platformType ||
    !startDate ||
    !endDate ||
    !remuneration?.trim() ||
    categories?.length === 0 ||
    // disabled when type is product placement and currency is not set
    // when type is not product placement only remuneration needs to be set
    (campaignType == CampaignType.ProductPlacement && !currency);

  const onPatchCampaignImageError = () => {
    console.error('onPatchCampaignImageError');
    // image upload failed but data saved
  };

  const onPatchCampaignImageSuccess = () => {
    onSuccessfullEdit();
  };

  const { mutate: patchCampaignImage, isLoading: loadingPatchCampaignImage } = usePatchCampaign({
    onError: onPatchCampaignImageError,
    onSuccess: onPatchCampaignImageSuccess,
  });

  const onPostCampaignMediaObjectError = () => {
    console.error('onPostCampaignMediaObjectError');
    // image upload failed but data saved
  };

  const onPostCampaignMediaObjectSuccess = (mediaobject: HydraMember<MediaObject>) => {
    console.info('onPostCampaignMediaObjectSuccess');
    patchCampaignImage({ teaserImage: `/api/media-objects/${mediaobject.internalId}`, internalId: `${campaignId}` });
  };

  const { mutate: postCampaignMediaObject, isLoading: loadingPostCampaignMediaObject } = usePostCampaignMediaObject({
    onError: onPostCampaignMediaObjectError,
    onSuccess: onPostCampaignMediaObjectSuccess,
  });

  const onSuccessfullEdit = () => {
    toast('Die Kampagne wurde erfolgreich veröffentlicht!', { type: 'success' });
    navigate(`/app/campaigns/${campaignId}`);
  };

  const onPatchCampaignError = () => {
    console.error('onPatchCampaignError');
    console.error('Error submitting proposal:', error);
    toast('Beim Veröffentlichen der Kampagne gab es einen Fehler. Bitte prüfe deine Eingabe', { type: 'error' });
  };

  const onPatchCampaignSuccess = () => {
    if (selectedImage) {
      postCampaignMediaObject(selectedImage);
    } else {
      onSuccessfullEdit();
    }
  };

  const {
    mutate: patchCampaign,
    reset: resetPatchCampaign,
    error: patchCampaignError,
    isLoading: loadingPatchCampaign,
  } = usePatchCampaign({ onError: onPatchCampaignError, onSuccess: onPatchCampaignSuccess });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    resetPatchCampaign();

    patchCampaign({
      ...formData,
      teaserImage: undefined,
      categories: formData?.categories?.map((category) => `/api/campaign-categories/${category}`),
      internalId: campaignWithChanges.internalId,
    });
  };

  const onCancelClick = () => navigate(`/app/campaigns/${campaignId}`);

  const getActions = () => {
    return (
      <>
        <Button variant={'link'} onClick={onCancelClick}>
          Abbrechen
        </Button>
        <SubmitButton
          onClick={handleSubmit}
          loading={
            loadingPatchCampaign || (selectedImage && (loadingPatchCampaignImage || loadingPostCampaignMediaObject))
          }
          disabled={publishingDisabled}
          variant={'primary-gradient'}
        >
          Veröffentlichen
        </SubmitButton>
      </>
    );
  };

  return (
    <FullscreenModal title={'Kampagne bearbeiten'} actions={getActions()}>
      <Container fluid={true}>
        <LoadingBoundary error={error} isError={isError} isLoading={isLoading}>
          <CampaignForm
            errors={getApiErrorViolationsMap(patchCampaignError)}
            formData={campaignWithChanges}
            setFormData={setFormData}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </LoadingBoundary>
      </Container>
    </FullscreenModal>
  );
};
