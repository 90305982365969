interface ApiErrorType {
  violations?: {
    propertyPath: string;
    message: string;
  }[];
}
type ErrorMessages = Record<string, string>;

// On POST-Requests the PHP API sometimes returns error messages for specific fields.
// This method checks if there are such error messages and transforms then into an object
// with the field names as keys and error messages as values.
export const getApiErrorViolationsMap = (apiError: unknown): ErrorMessages => {
  let errorViolations: { propertyPath: string; message: string }[] = [];

  if (apiError && typeof apiError === 'object' && 'violations' in apiError) {
    const typedError = apiError as ApiErrorType;
    if (typedError.violations && Array.isArray(typedError.violations)) {
      errorViolations = typedError.violations.map((violation) => ({
        propertyPath: violation.propertyPath,
        message: violation.message,
      }));
    }
  }

  return errorViolations.reduce<ErrorMessages>((result, item) => {
    result[item.propertyPath] = item.message;
    return result;
  }, {});
};
