import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Organization, PartialOrganization } from '../../api/organization/type';
import { useGetOrganizations } from '../../api/organization/use-get-organizations';
import { usePatchOrganization } from '../../api/organization/use-patch-organization';
import { HydraMember } from '../../api/type';
import { isOrganizationDataComplete } from '../../utils/is-organization-data-complete';

type UseOrganizationEditModal = (props: { onSuccess: () => void }) => {
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
  error: unknown;
  isError: boolean;
  publishingDisabled: boolean;
  isFirstTimeEditing?: boolean;
  patchOrganizationErrors: unknown;
  isPatchLoading: boolean;
  setFormData: Dispatch<SetStateAction<PartialOrganization>>;
  organizationWithChanges?: PartialOrganization;
};

export const useOrganizationEditModal: UseOrganizationEditModal = ({ onSuccess }) => {
  const { data: organizations, isLoading, isError, error } = useGetOrganizations();
  const organization: HydraMember<Organization> | undefined =
    organizations && organizations?.['hydra:member']?.length > 0 ? organizations?.['hydra:member'][0] : undefined;
  const [isFirstTimeEditing, setIsFirstTimeEditing] = useState<boolean>();
  const [formData, setFormData] = useState<PartialOrganization>({});

  const organizationWithChanges = organization?.internalId
    ? {
        ...organization,
        ...formData,
        billingAddress: { ...organization.billingAddress, ...formData.billingAddress },
        internalId: organization.internalId,
        // By default the organization has the users email as it's name,
        // but we want to use the users email as the default value for the organizations name.
        // Therefore only use the form data name and not the API responses name when the
        // user hasn't provided the organization name.
        name: isFirstTimeEditing ? formData.name ?? '' : formData.name ?? organization.name,
      }
    : undefined;
  const { name, billingAddress, contact, email } = organizationWithChanges ?? {};
  const { street, city, zipCode, country } = billingAddress ?? {};

  useEffect(() => {
    // if name is not set the contact is editing the organization data the first time
    setIsFirstTimeEditing(!isOrganizationDataComplete(organization));
  }, [organization]);

  const publishingDisabled =
    !name?.trim() ||
    !street?.trim() ||
    !zipCode?.trim() ||
    !city?.trim() ||
    !country?.trim() ||
    !contact?.trim() ||
    !email?.trim();

  const onPatchOrganizationSuccess = () => {
    toast('Die Unternehmensdaten wurden erfolgreich gespeichert!', { type: 'success' });
    onSuccess();
  };

  const onPatchOrganizationError = () => {
    console.error('Error submitting proposal:', error);
    toast('Beim Speichern der Unternehmensdaten gab es einen Fehler. Bitte prüfe deine Eingabe', { type: 'error' });
  };

  const {
    mutate: patchOrganization,
    reset: resetPatchOrganization,
    error: patchOrganizationErrors,
    isLoading: isPatchLoading,
  } = usePatchOrganization({ onError: onPatchOrganizationError, onSuccess: onPatchOrganizationSuccess });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    resetPatchOrganization();

    if (organizationWithChanges) {
      patchOrganization(organizationWithChanges);
    }
  };

  return {
    handleSubmit,
    isLoading,
    error,
    isError,
    publishingDisabled,
    isFirstTimeEditing,
    patchOrganizationErrors,
    isPatchLoading,
    setFormData,
    organizationWithChanges,
  };
};
