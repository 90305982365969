import { useCallback, useEffect, useState } from 'react';

export type SearchParam<T> = readonly [
  searchParamValue: T | undefined,
  setSearchParamValue: (newState?: T) => void,
  reset: () => void,
];

interface Options<T> {
  createNewHistoryEntry?: boolean;
  defaultValue?: T;
}

type UseSearchParamValue = <T>(searchParamKey: string, options?: Options<T>) => SearchParam<T>;

// useSearchParams wrapper that provides a useState-like interface
// T is encoded using JSON.stringify & decoded using JSON.parse
export const useSearchParamValue: UseSearchParamValue = <T>(searchParamKey: string, options?: Options<T>) => {
  const defaultOptions: Options<T> = { createNewHistoryEntry: false, defaultValue: undefined };
  const { createNewHistoryEntry, defaultValue } = { ...defaultOptions, ...options };

  const [searchParamValue, setSearchParamValueState] = useState<T | undefined>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(searchParamKey);
    if (param) {
      try {
        return JSON.parse(param) as T;
      } catch (error) {
        console.error('Failed to parse search param value as JSON', { searchParamKey, param });
      }
    }
    return defaultValue;
  });

  const setSearchParamValue = useCallback(
    (value?: T) => {
      const urlParams = new URLSearchParams(window.location.search);
      const valueIsStrictlyFalse = value === false;
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (value || valueIsStrictlyFalse) {
        // only allow strict false values
        urlParams.set(searchParamKey, JSON.stringify(value));
      } else {
        urlParams.delete(searchParamKey);
      }

      const newSearch = urlParams.toString();
      const newStateParams: [object, string, string] = [
        {},
        '',
        window.location.pathname + (newSearch ? '?' + newSearch : ''),
      ];
      if (createNewHistoryEntry) window.history.pushState(...newStateParams);
      else window.history.replaceState(...newStateParams);

      setSearchParamValueState(value ?? defaultValue);
    },
    [searchParamKey, defaultValue, createNewHistoryEntry],
  );

  useEffect(() => {
    const popstateHandler = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const param = urlParams.get(searchParamKey);
      if (param) {
        try {
          setSearchParamValueState(JSON.parse(param) as T);
        } catch (error) {
          console.error('Failed to parse search param value as JSON', { searchParamKey, param });
          setSearchParamValueState(defaultValue);
        }
      } else {
        setSearchParamValueState(defaultValue);
      }
    };

    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, [searchParamKey, defaultValue]);

  const reset = () => setSearchParamValue();

  return [searchParamValue, setSearchParamValue, reset];
};
