import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { COOPERATIONS_QUERY_KEY } from './use-get-cooperations';

interface UsePatchCooperationProps {
  onSuccess: () => void;
  onError: () => void;
  cooperationId: number;
}

export const usePatchCooperationStatusSubmitProposal = ({
  onSuccess,
  onError,
  cooperationId,
}: UsePatchCooperationProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const patchCooperationStatusSubmitProposalHandler = (compensationNumericOrTextValue?: string | number) => {
    return httpFetch(`/api/cooperations/${cooperationId}/proposal-submit`, {
      init: {
        method: 'PATCH',
        body: JSON.stringify({ compensationValue: `${compensationNumericOrTextValue}` }),
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      parseJsonResponse: false,
      phpToken,
    });
  };

  return useMutation(patchCooperationStatusSubmitProposalHandler, {
    onError: () => onError(),
    onSuccess: async () => {
      await queryClient.refetchQueries([COOPERATIONS_QUERY_KEY]);
      onSuccess();
    },
  });
};
