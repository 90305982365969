import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { USER_QUERY_KEY } from './use-get-current-php-user';

interface UsePostSupportConversationProps {
  onSuccess: (conversation: { conversationId: string }) => void;
  onError: () => void;
}

export const usePostSupportConversation = ({ onSuccess, onError }: UsePostSupportConversationProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const postSupportConversationHandler = () => {
    return httpFetch<{ conversationId: string }>(`/api/support-conversation`, {
      init: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
      parseJsonResponse: false,
      phpToken,
    });
  };

  return useMutation(postSupportConversationHandler, {
    onError: () => onError(),
    onSuccess: (conversation) => {
      void queryClient.refetchQueries([USER_QUERY_KEY]);
      onSuccess(conversation);
    },
  });
};
