import { OverflowTooltip } from '../../../library/overflow-tooltip/overflow-tooltip';

interface RemunerationInfoProps {
  compensationValue: string;
  compensationType: 'text' | 'number';
  currency?: string;
}

export const RemunerationInfo = ({ compensationType, compensationValue, currency }: RemunerationInfoProps) => {
  const content = `Vergütung${compensationValue ? ':' : ''} ${compensationValue ?? ''} ${compensationType === 'number' ? currency : ''}`;

  return <OverflowTooltip title={content}>{content}</OverflowTooltip>;
};
