import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UNREAD_COUNT_SUPPORT_KEY } from '../../components/library/messaging/chat/chat';
import { useAuth } from '../../utils/auth/use-auth';
import { useSearchParamValue } from '../../utils/use-search-param-value';

type UseUnreadMessageCount = (params: { conversationId?: string }) => {
  count: number | undefined;
};

export const useUnreadMessageCount: UseUnreadMessageCount = ({ conversationId }) => {
  const [count, setCount] = useState<number | undefined>(undefined);
  const { firebaseUser, db } = useAuth();
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });

  const userId = firebaseUser?.uid;

  useEffect(() => {
    if (conversationId && userId && db) {
      try {
        // count numbers of unread messages in conversation with conversationId as id
        const conversation = doc(db, 'conversations', conversationId);

        return onSnapshot(conversation, (docSnapshot) => {
          if (docSnapshot.exists()) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const unreadCount =
              docSnapshot.data()[`unreadCount-${isInsideAdminPanel ? UNREAD_COUNT_SUPPORT_KEY : userId}`];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setCount(unreadCount ?? 0);
          } else {
            console.error('Failed to read conversation', { conversationId });
            toast('Beim Zählen der ungelesenen Nachrichten gab es einen Fehler.', { type: 'error' });
          }
        });
      } catch (error) {
        console.error('Requested conversation document does not exist', {
          conversationId,
          error,
        });
      }
    }
  }, [conversationId, db, isInsideAdminPanel, userId]);

  return { count };
};
