import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { Influencer } from './type';
import { INFLUENCERS_QUERY_KEY } from './use-get-influencers';

type Response = HydraMember<Influencer>;

export const useGetInfluencer = (id?: number) => {
  const { phpToken } = useAuth();

  const getInfluencer = async () => {
    const influencer = await httpFetch<Response>(`/api/influencers/${id}.jsonld`, { phpToken });
    return influencer;
  };

  return useQuery<Response>([INFLUENCERS_QUERY_KEY, id, phpToken], getInfluencer, {
    enabled: !!id || id === 0,
  });
};
