import { CooperationStatus } from '../api/@types/cooperation-status';

export type FilterOptionValue = 'applied' | 'negotiating' | 'active' | 'declined' | 'archived';

export const getCooperationStatusOfFilterValue = (value?: FilterOptionValue) => {
  let statusFilter: CooperationStatus[] | undefined = undefined;
  switch (value) {
    case 'applied':
      statusFilter = [CooperationStatus.Applied];
      break;
    case 'negotiating':
      statusFilter = [CooperationStatus.Negotiating, CooperationStatus.ProposalSubmitted];
      break;
    case 'active':
      statusFilter = [CooperationStatus.ContentCreation, CooperationStatus.ContentPublished];
      break;
    case 'archived':
      statusFilter = [CooperationStatus.CreateInvoice, CooperationStatus.Finished];
      break;
    case 'declined':
      statusFilter = [CooperationStatus.Declined];
      break;
    default:
      statusFilter = [CooperationStatus.Applied];
      break;
  }
  return statusFilter;
};
