import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMultiResponse } from '../type';
import { Influencer } from './type';

type Response = HydraMultiResponse<Influencer>;

export const INFLUENCERS_QUERY_KEY = 'influencers';

export const useGetInfluencers = () => {
  const { phpToken } = useAuth();

  const getInfluencers = async () => {
    const influencers = await httpFetch<Response>(`/api/influencers.jsonld`, {
      phpToken,
    });
    return influencers;
  };

  return useQuery<Response>([INFLUENCERS_QUERY_KEY, phpToken], getInfluencers);
};
