import { Dispatch, SetStateAction } from 'react';
import { Alert } from 'react-bootstrap';
import { PartialOrganization } from '../../api/organization/type';
import { OrganizationBillingAddress } from './organization-billing-address';
import { OrganizationContact } from './organization-contact';
import { OrganizationName } from './organization-name';

interface OrganizationFormProps {
  errors: Record<string, string>;
  formData?: PartialOrganization;
  setFormData: Dispatch<SetStateAction<PartialOrganization>>;
}

export type UpdateFormFn = (e: { name: string; value: string }) => void;

export const OrganizationForm = ({ errors, formData, setFormData }: OrganizationFormProps) => {
  const updateForm: UpdateFormFn = ({ name, value }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateFormBillingAddress: UpdateFormFn = ({ name, value }) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        billingAddress: {
          ...prevState?.billingAddress,
          [name]: value,
        },
      };
    });
  };

  return (
    <>
      <OrganizationName value={formData?.name} updateForm={updateForm} error={errors.name} />
      <OrganizationBillingAddress value={formData?.billingAddress} updateForm={updateFormBillingAddress} />
      <OrganizationContact
        contactValue={formData?.contact}
        emailValue={formData?.email}
        updateForm={updateForm}
        contactError={errors.contact}
        emailError={errors.email}
      />
      {errors.general && <Alert variant="danger">{errors.general}</Alert>}
    </>
  );
};
