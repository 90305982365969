import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../utils/auth/use-auth';
import { httpFetch } from '../../utils/http-fetch';
import { Currency } from './type';

type Response = Currency[];

export const CURRENCIES_LIST_QUERY_KEY = 'currencies-list';

export const useGetCurrencies = () => {
  const { phpToken } = useAuth();

  const getCurrencies = async () => {
    const currencies = await httpFetch<Response>(`/api/currencies`, { phpToken });
    return currencies;
  };

  return useQuery<Response>([CURRENCIES_LIST_QUERY_KEY, phpToken], getCurrencies);
};
