import { Button as BsButton, ButtonProps, Spinner } from 'react-bootstrap';

interface ButtonProps2 {
  buttonProps: ButtonProps;
  children: React.JSX.Element | string;
  loading?: boolean;
}

export const Button = ({ buttonProps, children, loading }: ButtonProps2) => {
  const { className = '' } = buttonProps;

  return (
    <BsButton {...buttonProps} className={`${className}`}>
      <span className="position-relative tw-flex tw-justify-center">
        <span className={loading ? 'tw-invisible' : ''}>{children}</span>
        <span
          className={`${loading ? '' : 'tw-invisible'} 
                    tw-absolute tw-flex tw-items-center`}
        >
          <Spinner animation="grow" size="sm" className="tw--mt-2" />
        </span>
      </span>
    </BsButton>
  );
};
