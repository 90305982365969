import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { CampaignCategory } from './type';
import { CAMPAIGN_CATEGORIES_QUERY_KEY } from './use-get-campaign-categories';

type Response = HydraMember<CampaignCategory>;

export const useGetCampaignCategory = (id: number) => {
  const { phpToken } = useAuth();

  const getCampaignCategory = async () => {
    const campaigns = await httpFetch<Response>(`/api/campaign-categories/${id}`, { phpToken });
    return campaigns;
  };

  return useQuery<Response>([CAMPAIGN_CATEGORIES_QUERY_KEY, id, phpToken], getCampaignCategory, {
    enabled: !!id || id === 0,
  });
};
