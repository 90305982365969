import React, { useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { UpdateFormDatesFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';

interface CampaignDateSelectorProps {
  updateFormDates: UpdateFormDatesFn;
  isPublished: boolean;
  value?: {
    startDate?: string;
    endDate?: string;
  };
}

export const CampaignDateSelector = ({
  updateFormDates,
  value: { startDate, endDate } = {},
  isPublished,
}: CampaignDateSelectorProps) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleSelectDateClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setShow(!show);
    setTarget(event.target as HTMLElement);
  };

  const selectedRange =
    startDate && endDate
      ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
      : '';

  const onSelect: SelectRangeEventHandler = (range) => {
    if (range) {
      updateFormDates({ fromDate: range.from ?? (startDate ? new Date(startDate) : undefined), endDate: range.to });
      if (range.from && range.to) {
        setShow(false);
      }
    } else {
      updateFormDates({});
    }
  };

  return (
    <CampaignFormGroup title={'Zeitraum'} controlId={'campaignDate'} required={true}>
      {isPublished ? (
        <span className={'text-size-2'}>{selectedRange}</span>
      ) : (
        <div ref={ref}>
          <Button onClick={handleSelectDateClick} variant={'outline-primary'}>
            Zeitraum definieren
          </Button>
          {selectedRange && <span className={'ms-3 text-size-2'}>{`Zeitraum: ${selectedRange}`}</span>}
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref}
            containerPadding={20}
            rootClose={true}
          >
            <Popover id="popover-contained" className="mw-100">
              <Popover.Body>
                <DayPicker
                  mode="range"
                  selected={{
                    from: startDate ? new Date(startDate) : undefined,
                    to: endDate ? new Date(endDate) : undefined,
                  }}
                  onSelect={onSelect}
                />
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      )}
    </CampaignFormGroup>
  );
};
