import { SVGProps } from 'react';

export const DropdownIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Group 194">
      <circle id="Ellipse 11" cx="2" cy="2" r="2" fill="#212122" />
      <circle id="Ellipse 12" cx="10" cy="2" r="2" fill="#212122" />
      <circle id="Ellipse 13" cx="18" cy="2" r="2" fill="#212122" />
    </g>
  </svg>
);
