import { ChangeEventHandler, useEffect, useState } from 'react';
import { ImageUpload } from '../library/image-upload/image-upload';
import { CampaignFormGroup } from './campaign-form-group';

interface CampaignTeaserImageProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  selectedImage?: File;
  existingImage?: string;
}

export const CampaignTeaserImage = ({ onChange, selectedImage, existingImage }: CampaignTeaserImageProps) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (!selectedImage) {
      setPreviewUrl(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  return (
    <CampaignFormGroup title={'Titelbild'} required={true}>
      <div className="tw-flex tw-gap-5 max-md:tw-flex-wrap">
        <ImageUpload src={previewUrl ?? existingImage ?? ''} onChange={onChange} />
        <div className="text-size-2 tw-grow">
          <p>
            Das Titelbild erscheint in der Kampagnenvorschau sowie auf der Detailseite zusammen mit allen
            Informationen.
          </p>
          <ul className={'mb-0'}>
            <li>Technische Informationen</li>
            <li>Bildauflösung mind. 2000 x 1500 px</li>
            <li>Bildverhältnis 4:3</li>
            <li> Fileformat: JPEG, PNG</li>
          </ul>
        </div>
      </div>
    </CampaignFormGroup>
  );
};
