import {Col, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';

interface CampaignFormGroupProps {
  title: string;
  children: React.JSX.Element | React.JSX.Element[];
  controlId?: string;
  lastElement?: boolean;
  hideBorder?: boolean;
  noBottomPadding?: boolean;
  required?: boolean;
}

export const CampaignFormGroup = ({
  title,
  controlId,
  children,
  lastElement = false,
  hideBorder = false,
  noBottomPadding = false,
  required = false,
}: CampaignFormGroupProps) => {
  const renderTooltip = (props: React.ComponentProps<typeof Tooltip>) => (
      <Tooltip id="button-tooltip" {...props}>
        Dies ist ein Pflichtfeld
      </Tooltip>
  );
  return (
    <Form.Group
      as={Row}
      className={`mb-6 ${noBottomPadding ? '' : 'pb-6'} ${!lastElement && !hideBorder ? 'border-bottom' : ''}`}
      controlId={controlId}
    >
      <Form.Label column sm={3} className={'pt-0'}>
        <div className="h4-container">
          {required ? (
              <OverlayTrigger
                  placement="right"
                  delay={{show: 50, hide: 400}}
                  overlay={renderTooltip}
              >
                <h4>{title} *</h4>
              </OverlayTrigger>
          ) : (
              <h4>{title}</h4>
          )}
        </div>
      </Form.Label>
      <Col sm={9}>{children}</Col>
    </Form.Group>
  );
};
