import { ChangeEventHandler } from 'react';
import { Badge, Form } from 'react-bootstrap';
import { UpdateFormFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';

const MAX_CAMPAIGN_TITLE_LENGTH = 70;

interface CampaignTitleProps {
  value?: string;
  updateForm: UpdateFormFn;
  error?: string;
}

export const CampaignTitle = ({ value, updateForm, error }: CampaignTitleProps) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateForm({ name: 'name', value: e.target.value });
  };

  const remainingValueLength = MAX_CAMPAIGN_TITLE_LENGTH - (value?.length ?? 0);

  return (
    <CampaignFormGroup title={'Kampagnentitel'} controlId={'campaignTitle'} required={true}>
      <div className="tw-relative">
        <Form.Control
          type="text"
          value={value}
          placeholder="Kampagnenname eingeben"
          onChange={onChange}
          // use "campaign-name" instead of name to prevent password managers autocompleting info
          name="campaign-name"
          isInvalid={!!error}
          autoFocus
          maxLength={MAX_CAMPAIGN_TITLE_LENGTH}
          style={{ paddingRight: '2.75rem' }}
          required
        />
        <Badge
          bg={remainingValueLength < 5 ? 'danger' : 'success'}
          className="tw-absolute icon-badge tw-right-2 tw-bottom-2 border-0"
        >
          {remainingValueLength}
        </Badge>
      </div>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      <p className={'pt-3 text-size-2'}>
        Tipp: Marken- oder Firmenname integrieren, da kein Logo oder Name angezeigt wird.
      </p>
    </CampaignFormGroup>
  );
};
