import { ToggleButton } from 'react-bootstrap';
import { useCountCooperationsCount } from '../../api/cooperation/use-get-cooperations-count';
import {
  FilterOptionValue,
  getCooperationStatusOfFilterValue,
} from '../../utils/get-cooperation-status-of-filter-value';

interface FilterToggleButtonProps {
  option: { name: string; value: FilterOptionValue };
  idx: number;
  campaignId?: number;
  handleFilterChange: (val: FilterOptionValue) => void;
  selectedValue?: FilterOptionValue;
  className?: string;
}

export const FilterToggleButton = ({
  option,
  idx,
  campaignId,
  handleFilterChange,
  selectedValue,
  className = '',
}: FilterToggleButtonProps) => {
  const cooperationStatus = getCooperationStatusOfFilterValue(option.value);

  const { data: count, isLoading } = useCountCooperationsCount({ campaignId, status: cooperationStatus });

  return (
    <ToggleButton
      variant="outline-secondary"
      onChange={() => handleFilterChange(option.value)}
      key={idx}
      id={`radio-${idx}`}
      type="radio"
      name="radio"
      value={option.value}
      checked={selectedValue === option.value}
      className={className}
    >
      {option.name}&nbsp;&nbsp;{isLoading ? '?' : count}
    </ToggleButton>
  );
};
