import { Image } from 'react-bootstrap';
import './image-message.scss';
import { Message } from './type';

interface ImageMessageProps {
  message: Message;
  alignRight: boolean;
  suffix?: React.JSX.Element;
  onImageLoaded: (mediaObject?: string) => () => void;
}

export const ImageMessage = ({
  alignRight,
  message: { mediaObjectId, mediaObject },
  suffix,
  onImageLoaded,
}: ImageMessageProps) => {
  const imageSrc = `${import.meta.env.VITE_BACKEND_HOST}${mediaObject ?? `/media/object/${mediaObjectId}/download`}`;

  return (
    <div className={`image-message ${alignRight ? 'right' : 'left'}`}>
      <Image src={imageSrc} loading="lazy" onLoad={onImageLoaded(mediaObject)} />
      {suffix}
    </div>
  );
};
