import { FormEventHandler, useState } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { usePhpLogin } from '../../../api/auth/use-php-login';
import { SubmitButton } from '../../../components/library/submit-button';
import { TogglePasswordInputGroup } from '../../../components/library/toggle-password-input-group';
import { useSearchParamValue } from '../../../utils/use-search-param-value';
import { useAuthPagesContext } from '../auth-pages-container/use-auth-pages-context';
import { isValidEmail } from '../../../utils/is-valid-email';

export const LoginPage = () => {
  const { email, setEmail, password, setPassword } = useAuthPagesContext();
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });
  const [invalidEmail, setInvalidEmail] = useState<string>('');
  const navigate = useNavigate();

  const onPhpLoginError = () => {
    console.error('php login error');
  };

  const onPhpLoginSuccess = () => {
    console.info('php login success');
    navigate('/app');
  };

  const {
    mutate: loginPhp,
    isLoading,
    error,
  } = usePhpLogin({ onError: onPhpLoginError, onSuccess: onPhpLoginSuccess });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setInvalidEmail(email);
      return;
    }

    setInvalidEmail('');

    loginPhp({ email, password });
  };

  let errorMessage = '';
  if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    errorMessage = error.message;
  }

  return (
    <>
      <h2 className={'mb-5'}>Melde dich mit deinem Unternehmenskonto an</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="formBasicEmail">
          <FormControl
            type="email"
            value={email}
            name="email"
            placeholder="E-Mail"
            required
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
        </FormGroup>

        <FormGroup className="mb-3" controlId="formBasicPassword">
          <TogglePasswordInputGroup password={password} setPassword={setPassword} />
        </FormGroup>
        {invalidEmail ? (
          <p className="text-danger">Bitte geben Sie eine gültige E-Mail-Adresse ein.</p>
        ) : errorMessage ? (
          <p className={'text-danger'}>{errorMessage}</p>
        ) : (
          ''
        )}
        <div className="d-grid gap-2">
          <SubmitButton loading={isLoading} variant={'primary-gradient'}>
            Anmelden
          </SubmitButton>
        </div>
        <p className={'mt-7'}>
          <Link to="/auth/reset-password" className={'text-primary fw-bold'}>
            Passwort vergessen?
          </Link>
          <br />
          {!isInsideAdminPanel && (
            <Link to="/auth/register/landing" className={'text-primary fw-bold'}>
              Neues Konto erstellen
            </Link>
          )}
        </p>
      </Form>
    </>
  );
};
