export enum CooperationStatus {
  Applied = 'applied',
  Negotiating = 'negotiating',
  ProposalSubmitted = 'proposal_submitted',
  ContentCreation = 'content_creation',
  ContentPublished = 'content_published',
  CreateInvoice = 'create_invoice',
  Finished = 'finished',
  Declined = 'declined',
  Archived = 'archived',
}
