import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { Organization } from './type';
import { ORGANIZATIONS_QUERY_KEY } from './use-get-organizations';

type Response = HydraMember<Organization>;

export const useGetOrganization = (id?: number) => {
  const { phpToken } = useAuth();

  const getOrganization = async () => {
    const organizations = await httpFetch<Response>(`/api/organizations/${id}.jsonld`, { phpToken });
    return organizations;
  };

  return useQuery<Response>([ORGANIZATIONS_QUERY_KEY, id], getOrganization, {
    enabled: !!id || id === 0,
  });
};
