import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { DOMPURIFY_CONFIG } from '../../config/dompurify-config';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { Campaign } from './type';
import { CAMPAIGNS_QUERY_KEY } from './use-get-campaigns';

type Response = HydraMember<Campaign>;

export const useGetCampaign = (id?: number) => {
  const { phpToken } = useAuth();

  const getCampaign = async () => {
    const campaign = await httpFetch<Response>(`/api/campaigns/${id}.jsonld`, {
      phpToken,
    });
    // sanitize values with HTML content
    campaign.approach = DOMPurify.sanitize(campaign.approach, DOMPURIFY_CONFIG);
    campaign.requirements = DOMPurify.sanitize(campaign.requirements, DOMPURIFY_CONFIG);
    campaign.productInformation = DOMPurify.sanitize(campaign.productInformation, DOMPURIFY_CONFIG);
    return campaign;
  };

  return useQuery<Response>([CAMPAIGNS_QUERY_KEY, id, phpToken], getCampaign, {
    enabled: !!id || id === 0,
  });
};
