import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { Campaign, PartialCampaign } from './type';
import { CAMPAIGNS_QUERY_KEY } from './use-get-campaigns';

interface UsePatchCampaignProps {
  onSuccess: (campaign: Campaign) => void;
  onError: () => void;
}

export const usePatchCampaign = ({ onSuccess, onError }: UsePatchCampaignProps) => {
  const { phpToken } = useAuth();
  const queryClient = useQueryClient();

  const patchCampaignHandler = (campaign: PartialCampaign & { teaserImage?: string }) => {
    return httpFetch<Campaign>(`/api/campaigns/${campaign.internalId}`, {
      init: {
        method: 'PATCH',
        body: JSON.stringify(campaign),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Accept: 'application/ld+json',
        },
      },
      phpToken,
    });
  };

  return useMutation(patchCampaignHandler, {
    onError: () => onError(),
    onSuccess: async (campaign) => {
      await queryClient.refetchQueries([CAMPAIGNS_QUERY_KEY, campaign.internalId]);
      onSuccess(campaign);
    },
  });
};
