import { useTranslation } from 'react-i18next';
import { Influencer } from '../../../../api/influencer/type';
import { useGetInfluencers } from '../../../../api/influencer/use-get-influencers';
import { useFetchLatestMessageTimestamps } from '../../../../api/messaging/use-fetch-latest-message-timestamp';
import { useFetchMessageExistence } from '../../../../api/messaging/use-fetch-message-existence';
import { Organization } from '../../../../api/organization/type';
import { useGetOrganizations } from '../../../../api/organization/use-get-organizations';
import { NoResultMessage } from '../../no-result-message/no-result-message';
import { SupportConversationListItem } from './support-conversation-list-item';

export const SupportConversationList = () => {
  const { t } = useTranslation();

  const { data: influencerResponse, isLoading: loadingInfluencers } = useGetInfluencers();

  const { data: organizationsResponse, isLoading: loadingOrganizations } = useGetOrganizations();

  const influencers = influencerResponse?.['hydra:member'] ?? [];
  const organizations = organizationsResponse?.['hydra:member'] ?? [];

  // treat influencers and organizations as one unit
  const messagePartners = [
    ...influencers.map((i) => ({ ...i, type: 'influencer' })),
    ...organizations.map((o) => ({ ...o, type: 'organization' })),
  ];

  const supportConversationIds = messagePartners.map(({ supportConversationId }) => supportConversationId);

  const { hasMessages, loadingHasMessages } = useFetchMessageExistence(supportConversationIds);

  const { latestMessageTimestamps, isLoading: loadingConversationTimestamps } =
    useFetchLatestMessageTimestamps(supportConversationIds);

  const noResultsMessage = (
    <NoResultMessage
      className="mt-6"
      message={
        <>
          <p>Keine Nachrichten gefunden.</p>
        </>
      }
    />
  );

  const filteredMessagePartners = messagePartners
    // filter out conversations without messages
    .filter((influencer) => !!latestMessageTimestamps[influencer.supportConversationId]);

  // check existence of messages
  if (!loadingOrganizations && !loadingInfluencers && !loadingHasMessages && !hasMessages) return noResultsMessage;

  return (
    <div className="mt-6">
      {loadingInfluencers || loadingOrganizations || loadingConversationTimestamps
        ? t('loading')
        : filteredMessagePartners
            // sort conversations by the latest message timestamp
            .sort((partnerA, partnerB) => {
              const partnerALatestMessageTimestamp =
                latestMessageTimestamps[partnerA.supportConversationId]?.seconds || 0;
              const partnerBLatestMessageTimestamp =
                latestMessageTimestamps[partnerB.supportConversationId]?.seconds || 0;
              if (partnerBLatestMessageTimestamp > partnerALatestMessageTimestamp) return 1;
              if (partnerBLatestMessageTimestamp < partnerALatestMessageTimestamp) return -1;
              return 0;
            })

            .map((messagePartner) =>
              messagePartner.type === 'influencer' ? (
                <SupportConversationListItem
                  key={`support-conversation-list-item-${messagePartner.supportConversationId}`}
                  influencer={messagePartner as Influencer}
                  latestMessageTimestamp={latestMessageTimestamps[messagePartner.supportConversationId]?.seconds}
                  className={`border-top`}
                />
              ) : (
                <SupportConversationListItem
                  key={`support-conversation-list-item-${messagePartner.supportConversationId}`}
                  organization={messagePartner as Organization}
                  latestMessageTimestamp={latestMessageTimestamps[messagePartner.supportConversationId]?.seconds}
                  className={`border-top`}
                />
              ),
            )}
    </div>
  );
};
