import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { Organization } from './type';
import { ORGANIZATIONS_QUERY_KEY } from './use-get-organizations';
import { CAMPAIGNS_QUERY_KEY } from '../campaign/use-get-campaigns';

interface UsePatchOrganizationProps {
  onSuccess: () => void;
  onError: () => void;
}

export const usePatchOrganization = ({ onSuccess, onError }: UsePatchOrganizationProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const patchOrganizationHandler = (organization: Organization) => {
    return httpFetch(`/api/organizations/${organization.internalId}`, {
      init: {
        method: 'PATCH',
        body: JSON.stringify(organization),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Accept: 'application/ld+json',
        },
      },
      parseJsonResponse: false,
      isCustomErrorResponse: true,
      phpToken,
    });
  };

  return useMutation(patchOrganizationHandler, {
    onError: () => onError(),
    onSuccess: () => {
      void queryClient.refetchQueries([ORGANIZATIONS_QUERY_KEY]);
      void queryClient.refetchQueries([CAMPAIGNS_QUERY_KEY]);
      onSuccess();
    },
  });
};
