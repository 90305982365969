import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { CooperationStatus } from '../@types/cooperation-status';
import { COOPERATIONS_QUERY_KEY } from './use-get-cooperations';

type Response = number;

export const COOPERATIONS_COUNT_QUERY_KEY = 'cooperations';

interface UseGetCooperationsCountParams {
  campaignId?: number;
  status?: CooperationStatus[];
}

export const useCountCooperationsCount = ({
  campaignId,
  status = [CooperationStatus.Applied],
}: UseGetCooperationsCountParams) => {
  const { phpToken } = useAuth();

  const getCooperationsCount = async () => {
    const statusSearchParam = status ? `${status.map((status) => `status[]=${status}`).join('&')}` : '';
    const cooperations = await httpFetch<object[]>(
      `/api/cooperations/count?campaign=${campaignId}&${statusSearchParam}`,
      { phpToken },
    );
    return cooperations.length ?? 0;
  };

  return useQuery<Response>(
    [COOPERATIONS_COUNT_QUERY_KEY, COOPERATIONS_QUERY_KEY, campaignId, status, phpToken],
    getCooperationsCount,
    {
      enabled: !!campaignId || campaignId === 0,
    },
  );
};
