import { SVGProps } from 'react';

export const RocketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.59161 41.2619C0.234374 45.6191 0 53.5612 0 53.5612C0 53.5612 7.93678 53.3268 12.2993 48.9696C16.6619 44.6123 18.4677 39.3549 16.337 37.2295C14.2063 35.0989 8.94885 36.9046 4.59161 41.2619Z"
      fill="url(#paint0_linear_536_1900)"
    />
    <path
      opacity="0.2"
      d="M13.8494 37.4003C14.6111 37.4003 15.1917 37.5974 15.5806 37.9809C17.0454 39.4457 15.7457 44.0107 11.543 48.2188C8.50674 51.255 3.4517 52.1499 1.16122 52.4056C1.4169 50.1151 2.31179 45.0601 5.34268 42.0239C8.14985 39.2114 11.4897 37.4003 13.8494 37.4003ZM13.8494 36.335C11.2873 36.335 7.70773 38.146 4.59161 41.2675C0.234374 45.6247 0 53.5668 0 53.5668C0 53.5668 7.93678 53.3324 12.2993 48.9752C16.6619 44.618 18.4677 39.3605 16.337 37.2352C15.7297 36.6173 14.8721 36.335 13.8494 36.335Z"
      fill="#424242"
    />
    <path
      d="M5.91193 42.7954C3.16868 45.5386 3.01953 50.5351 3.01953 50.5351C3.01953 50.5351 8.01597 50.3859 10.7592 47.6427C13.5025 44.8994 14.6424 41.5862 13.3001 40.2492C11.9577 38.9122 8.6605 40.0468 5.91193 42.7954Z"
      fill="url(#paint1_linear_536_1900)"
    />
    <path
      d="M11.538 42.8436C14.1481 45.6295 17.5731 45.3365 17.8555 44.8784L20.2418 41.0006C20.3856 40.7662 20.2525 40.3933 20.1513 40.2974L13.7113 33.8575C13.6101 33.7563 13.2532 33.6391 13.0188 33.7829L9.14098 36.1693C8.68821 36.4516 8.17685 39.2481 11.538 42.8436Z"
      fill="#546E7A"
    />
    <path
      d="M29.0744 49.9285C28.5417 50.36 27.8279 50.2641 27.7374 49.6249C27.487 47.8564 27.6522 44.3035 25.4256 42.077C23.2523 39.9037 19.7207 39.5841 19.7207 39.5841L33.0161 33.0322C33.0161 33.0322 35.5197 36.8941 34.1401 42.3912C32.9895 46.9349 31.024 48.3358 29.0744 49.9285Z"
      fill="#0288D1"
    />
    <path
      opacity="0.2"
      d="M32.2626 35.1789C32.3585 35.4399 32.4597 35.7382 32.5502 36.0684C32.9124 37.3895 33.2161 39.4935 32.5822 41.9971C31.7779 45.2037 30.574 46.578 29.1625 47.7819C28.9813 45.7471 28.5872 42.9772 26.547 40.9424C25.8013 40.1966 24.9277 39.632 24.0701 39.2112L32.2626 35.1789ZM33.0083 33.0322L19.7129 39.5841C19.7129 39.5841 23.2498 39.909 25.4178 42.077C27.6443 44.3035 27.4792 47.8564 27.7296 49.6249C27.7828 50.0031 28.0598 50.1948 28.3848 50.1948C28.6085 50.1948 28.8535 50.1043 29.0666 49.9285C31.0161 48.3358 32.9817 46.9349 34.1269 42.3912C35.5172 36.8888 33.0083 33.0322 33.0083 33.0322Z"
      fill="#424242"
    />
    <path
      d="M3.79103 25.4096C3.26901 25.857 3.45544 26.656 4.09465 26.7466C5.86311 26.997 9.41602 26.8318 11.6426 29.0584C13.8159 31.2317 14.2686 34.3105 14.2686 34.3105L20.6926 20.7115C20.6926 20.7115 17.2942 19.1188 12.351 20.4025C8.94194 21.2921 5.55949 23.8862 3.79103 25.4096Z"
      fill="#0288D1"
    />
    <path
      opacity="0.2"
      d="M16.1887 21.4994C17.0783 21.4994 17.8453 21.5953 18.4526 21.7124L14.4576 30.1819C14.0314 29.4202 13.4828 28.6371 12.7743 27.934C10.8833 26.043 8.36914 25.569 6.38761 25.3666C8.10814 24.0562 10.4466 22.5541 12.7477 21.9521C13.8983 21.6485 15.0541 21.4994 16.1887 21.4994ZM16.1887 19.9014C15.0648 19.9014 13.7704 20.0345 12.3535 20.4021C8.94443 21.2916 5.5673 23.8857 3.79884 25.4092C3.27682 25.8566 3.46326 26.6556 4.10246 26.7462C5.87092 26.9965 9.42383 26.8314 11.6504 29.058C13.8237 31.2312 14.2765 34.3101 14.2765 34.3101L20.6951 20.711C20.6951 20.711 18.9693 19.9014 16.1887 19.9014Z"
      fill="#424242"
    />
    <path
      opacity="0.2"
      d="M13.168 35.5729L18.452 40.857L16.7848 43.5683C16.7315 43.5736 16.6729 43.5736 16.609 43.5736C16.0923 43.5736 14.2919 43.4404 12.7045 41.7465C10.6964 39.5999 10.3821 37.922 10.4087 37.2668L13.168 35.5729ZM13.2798 33.7139C13.1893 33.7139 13.0987 33.7352 13.0188 33.7778L9.14097 36.1641C8.68287 36.4465 8.17151 39.243 11.5327 42.8385C13.2425 44.6656 15.304 45.1716 16.6037 45.1716C17.2855 45.1716 17.7542 45.0331 17.8501 44.8733L20.2365 40.9955C20.3803 40.7611 20.2471 40.3882 20.1459 40.2923L13.7113 33.8577C13.6474 33.7938 13.4662 33.7139 13.2798 33.7139Z"
      fill="#424242"
    />
    <path
      d="M37.564 2.95312C39.7213 3.94389 42.6297 5.64843 45.4049 8.42364C46.385 9.40375 47.2213 10.4052 47.9457 11.3853C49.3466 13.2763 50.3054 15.082 50.934 16.4936C49.5331 20.1743 46.891 24.457 44.3076 27.0404C33.7554 37.5926 22.1006 43.6171 16.0708 39.3504C15.7192 39.1001 15.373 38.871 15.0587 38.5887C9.88646 33.9385 15.8097 20.6271 26.5697 9.86718C29.2703 7.16654 33.7714 4.40731 37.564 2.95312Z"
      fill="url(#paint2_radial_536_1900)"
    />
    <path
      d="M34.7564 22.9747C37.3746 22.9747 39.4971 20.8522 39.4971 18.2339C39.4971 15.6157 37.3746 13.4932 34.7564 13.4932C32.1381 13.4932 30.0156 15.6157 30.0156 18.2339C30.0156 20.8522 32.1381 22.9747 34.7564 22.9747Z"
      fill="#455A64"
    />
    <path
      opacity="0.2"
      d="M34.7578 14.5585C35.7379 14.5585 36.6648 14.942 37.3572 15.6345C38.0497 16.327 38.4332 17.2538 38.4332 18.2339C38.4332 19.214 38.0497 20.1409 37.3572 20.8334C36.6648 21.5258 35.7379 21.9093 34.7578 21.9093C33.7777 21.9093 32.8508 21.5258 32.1584 20.8334C30.7255 19.4005 30.7255 17.0674 32.1584 15.6345C32.8508 14.942 33.7724 14.5585 34.7578 14.5585ZM34.7578 13.4932C33.5433 13.4932 32.3288 13.9566 31.402 14.8834C29.5483 16.7371 29.5483 19.7361 31.402 21.5897C32.3288 22.5166 33.5433 22.98 34.7578 22.98C35.9723 22.98 37.1868 22.5166 38.1136 21.5897C39.9673 19.7361 39.9673 16.7371 38.1136 14.8834C37.1868 13.9566 35.9723 13.4932 34.7578 13.4932Z"
      fill="#424242"
    />
    <path
      d="M24.9753 32.0463C26.8699 32.0463 28.4057 30.5105 28.4057 28.6159C28.4057 26.7214 26.8699 25.1855 24.9753 25.1855C23.0808 25.1855 21.5449 26.7214 21.5449 28.6159C21.5449 30.5105 23.0808 32.0463 24.9753 32.0463Z"
      fill="#455A64"
    />
    <path
      d="M37.5622 2.95198C36.5768 7.81525 45.1634 17.3394 50.9322 16.4924C52.7273 11.8529 53.4624 8.70481 53.9525 3.28756C54.2028 0.544314 53.3985 -0.23871 50.8577 0.0595845C44.1194 0.847936 41.4561 1.3593 37.5622 2.95198Z"
      fill="#E53935"
    />
    <path
      opacity="0.2"
      d="M51.7683 1.59801C52.0879 1.59801 52.2477 1.6353 52.3063 1.6566C52.3489 1.75248 52.4555 2.12535 52.3649 3.14275C51.8908 8.38955 51.209 11.3725 49.4459 15.9215C48.0929 19.4637 45.5148 23.5706 43.1764 25.909C35.0212 34.0642 26.4559 39.1352 20.8202 39.1352C19.3021 39.1352 18.013 38.7677 16.9903 38.0433L16.8145 37.9207C16.5429 37.7343 16.3138 37.5692 16.1221 37.3987C15.2591 36.621 14.801 35.4332 14.7584 33.8671C14.6093 28.1676 19.9253 18.7606 27.6917 10.9943C30.126 8.56 34.4193 5.86469 38.1267 4.44779L38.1586 4.43181C41.9246 2.8924 44.5453 2.40767 51.0332 1.64595C51.3156 1.61399 51.5606 1.59801 51.7683 1.59801ZM51.7683 0C51.4913 0 51.193 0.0213071 50.8575 0.058594C44.1192 0.846945 41.4558 1.35831 37.562 2.95099C33.7694 4.39985 29.2684 7.15908 26.5677 9.85971C15.8078 20.6196 9.88451 33.9311 15.0567 38.5813C15.3657 38.8582 15.7119 39.0926 16.0688 39.343C17.4005 40.2858 19.0091 40.7279 20.8202 40.7279C27.2016 40.7279 36.0865 35.2574 44.3109 27.0383C46.8944 24.4549 49.5364 20.1722 50.9374 16.4915C52.7325 11.8519 53.4675 8.70382 53.9576 3.28657C54.1653 0.905539 53.5901 0 51.7683 0Z"
      fill="#424242"
    />
    <path
      opacity="0.2"
      d="M24.9752 26.2509C25.6091 26.2509 26.2004 26.4959 26.6478 26.9434C27.0953 27.3908 27.3403 27.9821 27.3403 28.6159C27.3403 29.2498 27.0953 29.8411 26.6478 30.2885C26.2004 30.736 25.6091 30.981 24.9752 30.981C24.3413 30.981 23.7501 30.736 23.3026 30.2885C22.8552 29.8411 22.6102 29.2498 22.6102 28.6159C22.6102 27.9821 22.8552 27.3908 23.3026 26.9434C23.7554 26.4959 24.3467 26.2509 24.9752 26.2509ZM24.9752 25.1855C24.0963 25.1855 23.2227 25.5211 22.5516 26.1923C21.2146 27.5293 21.2146 29.7026 22.5516 31.0396C23.2227 31.7108 24.0963 32.0463 24.9752 32.0463C25.8541 32.0463 26.7277 31.7108 27.3989 31.0396C28.7359 29.7026 28.7359 27.5293 27.3989 26.1923C26.733 25.5211 25.8541 25.1855 24.9752 25.1855Z"
      fill="#424242"
    />
    <defs>
      <linearGradient
        id="paint0_linear_536_1900"
        x1="17.2233"
        y1="44.9469"
        x2="-0.00532657"
        y2="44.9469"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6D00" />
        <stop offset="0.2487" stopColor="#FF9B00" />
        <stop offset="0.5135" stopColor="#FFC600" />
        <stop offset="0.721" stopColor="#FFE000" />
        <stop offset="0.8439" stopColor="#FFEA00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_536_1900"
        x1="11.3798"
        y1="42.0848"
        x2="2.46023"
        y2="51.2755"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0044475" stopColor="#C62828" />
        <stop offset="0.2711" stopColor="#D75327" />
        <stop offset="0.5983" stopColor="#E98126" />
        <stop offset="0.8504" stopColor="#F59D25" />
        <stop offset="0.9953" stopColor="#F9A825" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_536_1900"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.44 10.4158) rotate(180) scale(19.4281 19.4281)"
      >
        <stop offset="0.0112" stopColor="#F5F5F5" />
        <stop offset="0.9995" stopColor="#BDBDBD" />
      </radialGradient>
    </defs>
  </svg>
);
