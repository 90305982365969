import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetCampaignCategory } from '../api/campaign/use-get-campaign-category';

interface CampaignCategoryBadgeProps {
  categoryId: number;
}

export const CampaignCategoryBadge = ({ categoryId }: CampaignCategoryBadgeProps) => {
  const { t } = useTranslation();
  const { data: campaignCategoryResponse } = useGetCampaignCategory(categoryId);

  const badgeText = campaignCategoryResponse?.slug
    ? t(`campaignCategory.${campaignCategoryResponse?.slug}`)
    : t('loading');

  return (
    <Badge
      pill={false}
      className={'p-3 text-black fw-normal text-uppercase text-size-3 border-radius-50 fw-medium lh-0'}
      bg="secondary"
    >
      {badgeText}
    </Badge>
  );
};
