import { useEffect, useState } from 'react';
import { Button as BsButton, FormControl, FormLabel, InputGroup, Offcanvas } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Campaign } from '../../../api/campaign/type';
import { Cooperation } from '../../../api/cooperation/type';
import { usePatchCooperationStatus } from '../../../api/cooperation/use-patch-cooperation-status';
import { usePatchCooperationStatusSubmitProposal } from '../../../api/cooperation/use-patch-cooperation-status-submit-proposal';
import { useUnreadMessageCount } from '../../../api/messaging/use-unread-message-count';
import { extractURLId } from '../../../utils/extract-url-id';
import { useCooperationPartner } from '../../../utils/use-cooperation-partner';
import { useResponsive } from '../../../utils/use-responsive';
import { Button } from '../../library/button';
import { Chat } from '../../library/messaging/chat/chat';
import { TextBadge } from '../../library/text-badge/text-badge';
import { OffcanvasMediakit } from '../../mediakit/offcanvas-mediakit';
import { InfluencerInfo } from './content/influencer-info';
import { MediaKitButton } from './content/mediakit-button';
import { MessagesButton } from './content/messages-button';
import { RemunerationInfo } from './content/remuneration-info';
import { Timestamp } from './content/timestamp';
import './cooperation-item.scss';
import {CampaignRemunerationType} from "../../../api/@types/campaign-remuneration-type.ts";

interface CooperationItemProps {
  cooperation: Cooperation;
  campaign: Campaign;
  isLastRow?: boolean;
}

export const CooperationItem = ({ cooperation, campaign, isLastRow }: CooperationItemProps) => {
  const [compensationNumericValue, setCompensationNumericValue] = useState<number>();
  const [compensationTextValue, setCompensationTextValue] = useState<string>('');
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [selectedInfluencerId, setSelectedInfluencerId] = useState<number | undefined>(undefined);
  const [mediakitOpen, setMediakitOpen] = useState(false);
  const { cooperationPartnerName } = useCooperationPartner({ cooperation });
  const { count: unreadMessageCount } = useUnreadMessageCount({ conversationId: cooperation.conversationId });
  const { isDesktop } = useResponsive();

  const compensationType = campaign.campaignRemunerationType
    ? [CampaignRemunerationType.FixedRemuneration].includes(campaign.campaignRemunerationType)
      ? 'number'
      : 'text'
    : 'text';

  // update compensation value with initial values
  useEffect(() => {
    if (cooperation.compensationValue) {
      if (compensationType === 'text') {
        setCompensationTextValue(cooperation.compensationValue);
      } else {
        try {
          setCompensationNumericValue(parseFloat(cooperation.compensationValue));
        } catch (error) {
          console.error('Failed to parse numeric compensation value', { error, cooperation });
        }
      }
    }
  }, [compensationType, cooperation]);

  const hideChat = () => setChatOpen(false);

  const showChat = () => setChatOpen(true);

  const closeMediakit = () => {
    setMediakitOpen(false);
    setSelectedInfluencerId(undefined);
  };

  const showMediakit = () => {
    setMediakitOpen(true);
    setSelectedInfluencerId(extractURLId(cooperation.influencer));
  };

  const { mutate: patchCooperationStatus, isLoading: isLoadingStatusChange } = usePatchCooperationStatus({
    cooperationId: cooperation.internalId,
    onSuccess: () => {
      toast.success('Status erfolgreich geändert');
    },
    onError: () => {
      console.error('Error changing cooperation');
    },
  });

  const { mutate: patchCooperationStatusSubmitProposal, isLoading: isLoadingStatusSubmit } =
    usePatchCooperationStatusSubmitProposal({
      cooperationId: cooperation.internalId,
      onSuccess: () => {
        toast.success('Angebot erfolgreich gesendet', { type: 'success' });
      },
      onError: () => {
        console.error('Error submitting proposal:');
      },
    });

  const influencerInfo = (hideIdentity?: boolean) => (
    <InfluencerInfo
      handleMediaKitClick={showMediakit}
      showChat={showChat}
      unreadMessageCount={unreadMessageCount}
      currentFollowerCount={cooperation.currentFollowerCount}
      hideIdentity={hideIdentity}
      influencer={cooperation?.influencer}
    />
  );

  const mediakitButton = <MediaKitButton className="max-lg:tw-hidden" onClick={showMediakit} />;

  const messagesButton = (
    <MessagesButton className="max-lg:tw-hidden" onClick={showChat} unreadMessageCount={unreadMessageCount} />
  );

  const createdAtTimestamp = <Timestamp date={cooperation.createdAt} />;
  const updatedAtTimestamp = <Timestamp date={cooperation.updatedAt} />;

  const acceptAndDeclineButtons = (
    <div>
      <Button
        buttonProps={{
          variant: 'outline-primary',
          className: 'me-1',
          href: '#',
          onClick: () => patchCooperationStatus('accept'),
          disabled: isLoadingStatusChange,
        }}
        loading={isLoadingStatusChange}
      >
        Annehmen
      </Button>
      <Button
        buttonProps={{
          variant: 'outline-tertiary',
          href: '#',
          onClick: () => patchCooperationStatus('decline'),
          disabled: isLoadingStatusChange,
        }}
        loading={isLoadingStatusChange}
      >
        Ablehnen
      </Button>
    </div>
  );

  const compensationInput = (
    <div className="tw-flex tw-items-center tw-gap-2 tw-w-full">
      <FormLabel className={'align-content-center m-0 p-0'}>Vergütung</FormLabel>
      {compensationType === 'text' ? (
        <FormControl
          type="text"
          name="compensationValue"
          value={compensationTextValue ?? ''}
          placeholder={
            'Vergütung ...'
          }
          className={'tw-grow form-control-sm py-2 tw-overflow-ellipsis'}
          onChange={(e) => setCompensationTextValue(e.target.value)}
        />
      ) : (
        <InputGroup>
          <InputGroup.Text id="basic-addon1" className="input-prefix form-control-sm">
            {campaign.currency}
          </InputGroup.Text>
          <FormControl
            type="number"
            min={0}
            name="compensationValue"
            value={compensationNumericValue ?? ''}
            className={'tw-grow form-control-sm py-2 tw-overflow-ellipsis'}
            placeholder="Betrag ..."
            onChange={(e) => {
              try {
                const numericValue = parseFloat(e.target.value);
                setCompensationNumericValue(numericValue);
              } catch (error) {
                console.error('Failed to parse numeric value', { error });
              }
            }}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      )}
    </div>
  );

  const submitProposalButton = (
    <Button
      buttonProps={{
        variant: 'outline-primary',
        className: 'tw-w-max',
        href: '#',
        onClick: () =>
          patchCooperationStatusSubmitProposal(
            compensationType === 'text' ? compensationTextValue : compensationNumericValue ?? 0,
          ),
        disabled: isLoadingStatusSubmit,
      }}
      loading={isLoadingStatusSubmit}
    >
      Angebot senden
    </Button>
  );

  const renumerationInfo = (
    <RemunerationInfo
      compensationType={compensationType}
      currency={campaign.currency}
      compensationValue={cooperation.compensationValue}
    />
  );

  const withdrawProposalButton = (
    <Button
      buttonProps={{
        variant: 'outline-primary',
        href: '#',
        onClick: () => patchCooperationStatus('proposal-withdraw'),
        disabled: isLoadingStatusChange,
      }}
      loading={isLoadingStatusChange}
    >
      Zurückziehen
    </Button>
  );

  const creatingBadge = (
    <TextBadge className="tw-float-right tw-bg-[color:var(--sh-color-blue-20)]">Creating</TextBadge>
  );

  const finishedBadge = (
    <TextBadge className="tw-float-right tw-bg-[color:var(--sh-color-green-20)]">Erledigt</TextBadge>
  );

  const disabledFinishButton = (
    <BsButton variant={'outline-primary'} disabled={true} href="#">
      Abschliessen
    </BsButton>
  );

  const finishButton = (
    <Button
      buttonProps={{
        variant: 'outline-primary',
        href: '#',
        onClick: () => patchCooperationStatus('finish'),
        disabled: isLoadingStatusChange,
      }}
      loading={isLoadingStatusChange}
    >
      Abschliessen
    </Button>
  );

  const content = {
    applied: {
      rows: [influencerInfo(true), mediakitButton, createdAtTimestamp, acceptAndDeclineButtons],
      rightAlignLastCell: true,
    },
    negotiating: {
      rows: [influencerInfo(), mediakitButton, messagesButton, compensationInput, submitProposalButton],
      rightAlignLastCell: true,
    },
    proposal_submitted: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, withdrawProposalButton],
      rightAlignLastCell: true,
    },
    content_creation: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, creatingBadge, disabledFinishButton],
      rightAlignLastCell: true,
    },
    content_published: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, finishedBadge, finishButton],
      rightAlignLastCell: true,
    },
    create_invoice: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, createdAtTimestamp],
      rightAlignLastCell: false,
    },
    archived: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, updatedAtTimestamp],
      rightAlignLastCell: false,
    },
    finished: {
      rows: [influencerInfo(), mediakitButton, messagesButton, renumerationInfo, updatedAtTimestamp],
      rightAlignLastCell: false,
    },
    declined: {
      rows: [influencerInfo(), mediakitButton, messagesButton, updatedAtTimestamp],
      rightAlignLastCell: false,
    },
  }[cooperation.status];

  return (
    <>
      {isDesktop ? (
        // desktop style with table
        <tr
          key={`cooperation-item-${cooperation.internalId}`}
          className={`cooperation-item-row max-lg:tw-hidden ${isLastRow ? '' : 'border-bottom'}`}
        >
          {content.rows.map((row, i) => {
            const isLastElement = i === content.rows.length - 1;
            const shouldAlignRight = isLastElement && content.rightAlignLastCell;

            return (
              <td key={i} className={shouldAlignRight ? 'text-end' : ''}>
                {row}
              </td>
            );
          })}
        </tr>
      ) : (
        // mobile style with flex-col
        <div
          className={`lg:tw-hidden py-3 tw-flex tw-justify-between tw-flex-col tw-items-start tw-gap-2.5 
                      ${isLastRow ? '' : 'border-bottom'}`}
        >
          {content.rows.map((row) => row)}
        </div>
      )}
      <Offcanvas show={chatOpen} onHide={hideChat} placement={'end'}>
        <Chat
          conversationId={cooperation.conversationId}
          onClose={hideChat}
          campaign={campaign}
          conversationPartnerName={cooperationPartnerName}
          hideLoading={true}
          hideCampaignLink={true}
          influencerId={extractURLId(cooperation.influencer)}
          cooperationId={cooperation.internalId}
          cooperationStatus={cooperation.status}
        />
      </Offcanvas>
      <OffcanvasMediakit
        show={mediakitOpen}
        onClose={closeMediakit}
        influencerId={selectedInfluencerId}
        cooperationId={cooperation.internalId}
        hideIdentity={['applied'].includes(cooperation.status)}
      />
    </>
  );
};
