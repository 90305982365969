import { Col, Form, Row } from 'react-bootstrap';

interface LabeledFormGroupRowProps {
  title: string;
  children: React.JSX.Element | React.JSX.Element[];
  className?: string;
}

export const LabeledFormGroupRow = ({ title, children, className = '' }: LabeledFormGroupRowProps) => {
  return (
    <Form.Group as={Row} className={className}>
      <Form.Label column sm={3} className={'pt-0'}>
        <h4>{title}</h4>
      </Form.Label>
      <Col sm={9}>{children}</Col>
    </Form.Group>
  );
};
