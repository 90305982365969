import { SVGProps } from 'react';

export const EyeIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1049_6894)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93114 11.1565C1.79095 11.3138 1.71341 11.5171 1.71341 11.7279C1.71341 11.9387 1.79095 12.1421 1.93114 12.2993C2.80561 13.2398 4.22523 14.6109 5.98975 15.7429C7.76204 16.8798 9.82413 17.7385 11.9961 17.7385C14.1681 17.7385 16.2301 16.8798 18.0024 15.7429C19.767 14.6109 21.1866 13.2398 22.0611 12.2993C22.2012 12.1421 22.2788 11.9387 22.2788 11.7279C22.2788 11.5171 22.2012 11.3138 22.0611 11.1565C21.1866 10.216 19.767 8.845 18.0024 7.713C16.2301 6.57602 14.1681 5.71732 11.9961 5.71732C9.82413 5.71732 7.76204 6.57602 5.98975 7.713C4.22523 8.84499 2.80561 10.216 1.93114 11.1565ZM5.06245 6.26756C6.99097 5.03035 9.37674 4 11.9961 4C14.6154 4 17.0012 5.03035 18.9297 6.26756C20.8627 7.50763 22.3949 8.99292 23.324 9.99284L23.3325 10.002L23.3324 10.002C23.7596 10.4754 23.9961 11.0903 23.9961 11.7279C23.9961 12.3655 23.7596 12.9805 23.3324 13.4538L23.3241 13.4631L23.324 13.463C22.3949 14.4629 20.8627 15.9482 18.9297 17.1883C17.0012 18.4255 14.6154 19.4559 11.9961 19.4559C9.37674 19.4559 6.99097 18.4255 5.06245 17.1883C3.12946 15.9482 1.59731 14.4629 0.668179 13.463L0.659699 13.4539L0.659766 13.4538C0.232566 12.9805 -0.00390625 12.3655 -0.00390625 11.7279C-0.00390625 11.0903 0.232566 10.4754 0.659766 10.002L0.668113 9.99278L0.668179 9.99284C1.59731 8.99292 3.12946 7.50763 5.06245 6.26756Z"
        fill="#3E98FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9964 9.15287C10.5737 9.15287 9.42044 10.3062 9.42044 11.7288C9.42044 13.1515 10.5737 14.3048 11.9964 14.3048C13.4191 14.3048 14.5724 13.1515 14.5724 11.7288C14.5724 10.3062 13.4191 9.15287 11.9964 9.15287ZM7.70312 11.7288C7.70312 9.35772 9.6253 7.43555 11.9964 7.43555C14.3675 7.43555 16.2897 9.35772 16.2897 11.7288C16.2897 14.1 14.3675 16.0221 11.9964 16.0221C9.6253 16.0221 7.70312 14.1 7.70312 11.7288Z"
        fill="#3E98FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1049_6894">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
