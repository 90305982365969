import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { useGetCurrentPhpUserStatus } from '../../api/user/use-get-current-php-user-status';
import { useAuth } from '../../utils/auth/use-auth';
import { useSearchParamValue } from '../../utils/use-search-param-value';
import { Sidebar } from '../library/side-bar';
import { UserDisabledModal } from '../library/user-disabled-modal';
import { UserEmailNotConfirmedModal } from '../library/user-email-not-confirmed-modal';
import { UserIsInfluencerModal } from '../library/user-is-influencer-modal';

export const AppContainer = () => {
  const [hideLoginScreen] = useSearchParamValue<boolean>('hideLoginScreen', { defaultValue: false });
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });
  const { data: userStatus } = useGetCurrentPhpUserStatus();
  const navigate = useNavigate();

  const { has_disabled_role, is_influencer, is_email_confirmed } = userStatus ?? {};

  // navigate to /auth if user isn't logged in
  const { refreshToken } = useAuth();
  useEffect(() => {
    if (!refreshToken && !hideLoginScreen) {
      console.info('Navigating to auth because user is not logged in.');
      navigate('/auth');
    }
  }, [hideLoginScreen, navigate, refreshToken]);

  return (
    <Container fluid={!isInsideAdminPanel} className={'m-0 p-0'}>
      {userStatus && (
        <>
          <UserDisabledModal show={!!has_disabled_role} />
          <UserEmailNotConfirmedModal show={!is_influencer && !is_email_confirmed} />
          <UserIsInfluencerModal show={!!is_influencer && !isInsideAdminPanel} />
        </>
      )}
      {isInsideAdminPanel ? (
        // hide sidebar and main padding when used on admin page
        <div className="tw-max-h-screen">
          <Outlet />
        </div>
      ) : (
        <div className="tw-flex">
          <Sidebar className="tw-grow-0" />
          <div className="tw-grow max-lg:tw-w-full tw-max-h-screen">
            <Outlet />
          </div>
        </div>
      )}
    </Container>
  );
};
