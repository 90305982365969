import { useState } from 'react';
import { Badge, Image, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetCampaign } from '../../../api/campaign/use-get-campaign';
import { Cooperation } from '../../../api/cooperation/type';
import { useUnreadMessageCount } from '../../../api/messaging/use-unread-message-count';
import { extractURLId } from '../../../utils/extract-url-id';
import { formatTimestamp } from '../../../utils/format-timestamp';
import { useCooperationPartner } from '../../../utils/use-cooperation-partner';
import { LoadingBoundary } from '../loading-boundary';
import { Chat } from './chat/chat';
import './conversation-list-item.scss';

interface CooperationConversationListItemProps {
  cooperation: Cooperation;
  className?: string;
  latestMessageTimestamp?: number;
}

export const CooperationConversationListItem = ({
  cooperation,
  className = '',
  latestMessageTimestamp,
}: CooperationConversationListItemProps) => {
  const { t } = useTranslation();
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const { cooperationPartnerName } = useCooperationPartner({ cooperation });
  const { count: unreadMessageCount } = useUnreadMessageCount({ conversationId: cooperation.conversationId });

  const hideChat = () => setChatOpen(false);
  const showChat = () => setChatOpen(true);

  const campaignId = extractURLId(cooperation.campaign);

  const { data: campaignResponse, error, isLoading, isError } = useGetCampaign(campaignId);

  return (
    <div className={`conversation-list-item d-flex justify-content-between ${className}`}>
      <div onClick={showChat} className="d-flex pt-4 pb-4 gap-5 w-100">
        <LoadingBoundary error={error} isLoading={isLoading} isError={isError}>
          <div className="image-container">
            <Image rounded src={campaignResponse?.teaserImage?.contentUrl} className="object-fit-cover" />
            {!!(unreadMessageCount && unreadMessageCount !== 0) && (
              <Badge bg="primary" className="icon-badge">
                {unreadMessageCount}
              </Badge>
            )}
          </div>
          <div>
            <h4>{cooperationPartnerName}</h4>
            <p className="mb-2">
              <strong>{campaignResponse?.name ?? t('loading')}</strong>
              <br />
              {campaignResponse
                ? `${t(`platformType.${campaignResponse.platformType}`)}: ${t(
                    `campaignType.${campaignResponse.campaignType}`,
                  )}`
                : t('loading')}
            </p>
            <p className="text-size-2 text-color-gray m-0">
              {!!latestMessageTimestamp && formatTimestamp(latestMessageTimestamp)}
            </p>
          </div>
        </LoadingBoundary>
      </div>
      <Offcanvas show={chatOpen} onHide={hideChat} placement={'end'}>
        {campaignResponse && (
          <Chat
            conversationId={cooperation.conversationId}
            onClose={hideChat}
            campaign={campaignResponse}
            conversationPartnerName={cooperationPartnerName}
            cooperationId={cooperation.internalId}
            cooperationStatus={cooperation.status}
            influencerId={extractURLId(cooperation.influencer)}
          />
        )}
      </Offcanvas>
    </div>
  );
};
