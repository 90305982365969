import { Badge } from 'react-bootstrap';
import { CampaignStatus } from '../api/@types/campaign-status';
import { useTranslation } from 'react-i18next';

interface CampaignStatusBadgeProps {
  campaignStatus?: CampaignStatus;
}

export const CampaignStatusBadge = ({ campaignStatus }: CampaignStatusBadgeProps) => {
  const { t } = useTranslation();
  let badgeVariant;
  let text: string = campaignStatus?.toString() ?? t('loading');

  // Überprüfe den Status der Kampagne und wähle die richtige Farbe
  switch (campaignStatus) {
    case 'completed':
      badgeVariant = 'success';
      text = 'Abgeschlossen';
      break;
    case 'draft':
      badgeVariant = 'danger';
      text = 'Entwurf';
      break;
    case 'running':
      badgeVariant = 'success-20';
      text = 'Aktiv';
      break;
    default:
      badgeVariant = 'info';
      break;
  }

  return (
    <Badge
      pill={false}
      className={'p-3 text-black fw-normal text-uppercase text-size-3 border-radius-50 fw-medium lh-0'}
      bg={badgeVariant}
    >
      {text}
    </Badge>
  );
};
