import dayjs from 'dayjs';
import { useState } from 'react';
import { Button, Card, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CampaignStatus } from '../../api/@types/campaign-status';
import { useGetCampaigns } from '../../api/campaign/use-get-campaigns';
import { CampaignDraftWarning } from '../../components/campaign-draft-warning/campaign-draft-warning';
import { LoadingBoundary } from '../../components/library/loading-boundary';
import PageContainer from '../../components/page-container';

const AVAILABLE_FILTERS: { name: string; value: CampaignStatus }[] = [
  { name: 'Aktiv', value: 'running' },
  { name: 'Abgeschlossen', value: 'completed' },
];

export const CampaignsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState<CampaignStatus>(AVAILABLE_FILTERS[0]?.value);

  const handleStatusChange = (val: CampaignStatus) => setStatus(val);

  const { data: campaignsResponse, isError, isLoading, error } = useGetCampaigns(status);
  const {
    data: draftCampaignsResponse,
    isError: isDraftError,
    isLoading: isDraftLoading,
    error: draftError,
  } = useGetCampaigns('draft');

  const statusCampaigns = campaignsResponse?.['hydra:member'] ?? [];
  const draftCampaigns = draftCampaignsResponse?.['hydra:member'] ?? [];
  // show draft campaigns on active campaigns list
  const campaigns =
    status === 'completed'
      ? statusCampaigns
      : status === 'running'
        ? [...statusCampaigns, ...draftCampaigns]
        : statusCampaigns;

  const onCreateClick = () => navigate('create');

  return (
    <PageContainer
      title="Kampagne"
      action={
        <Button onClick={onCreateClick} className={'btn-primary-gradient btn-wrap'}>
          Kampagne erstellen
        </Button>
      }
      useFullWidth
    >
      <div>
        <Row>
          <Col fluid>
            <CampaignDraftWarning className="pb-6" />
          </Col>
        </Row>
        <Row>
          <Col className={'pb-6'}>
            <ToggleButtonGroup type="radio" name="options" defaultValue={AVAILABLE_FILTERS[0].value}>
              {AVAILABLE_FILTERS.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`filter-${idx}`}
                  value={radio.value}
                  variant={'outline-secondary'}
                  onChange={() => handleStatusChange(radio.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Col>
        </Row>
        <Row className={'g-5'}>
          <LoadingBoundary
            isError={isError || isDraftError}
            error={error || draftError}
            isLoading={isLoading || isDraftLoading}
            loadingElement={<Col>{t('loading')}</Col>}
          >
            <>
              {campaigns.length === 0 ? (
                <Col>Keine Kampagnen gefunden.</Col>
              ) : (
                campaigns.map((campaign) => (
                  <Col key={campaign.internalId} lg={'3'}>
                    <Card className={'no-border'}>
                      <Link to={`${campaign.internalId}`}>
                        {campaign.teaserImage && <Card.Img src={campaign.teaserImage.contentUrl} />}
                        <Card.Body>
                          <strong>{campaign.name}</strong>
                          <p className={'text-size-2'}>
                            {t(`platformType.${campaign.platformType}`)}: {t(`campaignType.${campaign.campaignType}`)}
                            <br />
                            {dayjs(campaign.startDate).format('DD.MM.YYYY')} -{' '}
                            {dayjs(campaign.endDate).format('DD.MM.YYYY')}
                          </p>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Col>
                ))
              )}
            </>
          </LoadingBoundary>
        </Row>
      </div>
    </PageContainer>
  );
};
