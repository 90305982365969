import { Organization } from '../api/organization/type';

export const isOrganizationDataComplete = (organization?: Organization) => {
  const { name, billingAddress, contact, email } = organization ?? {};
  const { street, city, zipCode, country } = billingAddress ?? {};

  const missingOrganisationData =
    !name?.trim() ||
    !street?.trim() ||
    !city?.trim() ||
    !zipCode?.trim() ||
    !country?.trim() ||
    !contact?.trim() ||
    !email?.trim();

  return !missingOrganisationData;
};
