import { useTranslation } from 'react-i18next';

interface LoadingBoundaryProps {
  isError?: boolean;
  error?: unknown;
  isLoading?: boolean;
  children: React.JSX.Element | React.JSX.Element[] | string;
  loadingElement?: React.JSX.Element;
}

export const LoadingBoundary = ({ error, isError, isLoading, children, loadingElement }: LoadingBoundaryProps) => {
  const { t } = useTranslation();

  if (error) return <>Ein Fehler ist aufgetreten. {JSON.stringify(error)}</>;

  if (isError) return <>Ein unbekannter Fehler ist aufgetreten.</>;

  if (isLoading) return <>{loadingElement ?? t('loading')}</>;

  return <>{children}</>;
};
