import { MouseEventHandler } from 'react';
import { Button } from './button';

interface SubmitButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  children: React.JSX.Element | string;
  variant?: string;
  disabled?: boolean;
}

export const SubmitButton = ({
  onClick,
  loading = false,
  children,
  variant = 'primary',
  disabled = false,
}: SubmitButtonProps) => {
  return (
    <Button
      buttonProps={{
        type: 'submit',
        variant: variant,
        onClick: onClick,
        disabled: loading || disabled,
      }}
      loading={loading}
    >
      {children}
    </Button>
  );
};
