import { useQuery } from '@tanstack/react-query';
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { onlyUnique } from '../../utils/only-unique';
import { useAuth } from '../../utils/auth/use-auth';
import { usePrevious } from '../../utils/use-previous';

type LatestMessageTimestamps = Record<string, { seconds: number }>;

export const useFetchLatestMessageTimestamps = (conversationIds: string[]) => {
  const { db, firebaseUser } = useAuth();
  const [liveTimestamps, setLiveTimestamps] = useState<LatestMessageTimestamps>({});

  const fetchInitialTimestamps = async (): Promise<LatestMessageTimestamps> => {
    const timestamps: LatestMessageTimestamps = {};

    await Promise.all(
      conversationIds.filter(onlyUnique).map(async (conversationId) => {
        if (conversationId) {
          return new Promise<void>((resolve) => {
            const conversationMessagesRef = collection(db!, 'conversations', conversationId, 'messages');
            const q = query(conversationMessagesRef, orderBy('timestamp', 'desc'), limit(1));

            const unsubscribe = onSnapshot(
              q,
              (docSnapshot) => {
                if (!docSnapshot.empty && docSnapshot.docs[0].exists()) {
                  const latestMessage = docSnapshot.docs[0].data();
                  if (latestMessage.timestamp) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    timestamps[conversationId] = latestMessage.timestamp;
                  }
                }
                unsubscribe();
                resolve();
              },
              (error) => {
                console.error('Firebase snapshot to get conversation messages failed', { conversationId, error });
                resolve();
              },
            );
          });
        }
      }),
    );

    return timestamps;
  };

  const {
    data: initialTimestamps,
    isLoading,
    isError,
  } = useQuery<LatestMessageTimestamps>([], fetchInitialTimestamps, {
    enabled: !!conversationIds.length && !!db && !!firebaseUser,
  });

  const prevConversationIds = usePrevious(conversationIds);

  useEffect(() => {
    if (db && firebaseUser && (!prevConversationIds || prevConversationIds.length !== conversationIds.length)) {
      const unsubscribes: (() => void)[] = [];
      conversationIds.filter(onlyUnique).forEach((conversationId) => {
        if (conversationId) {
          const conversationMessagesRef = collection(db, 'conversations', conversationId, 'messages');
          const q = query(conversationMessagesRef, orderBy('timestamp', 'desc'), limit(1));

          const unsubscribe = onSnapshot(q, (docSnapshot) => {
            if (!docSnapshot.empty && docSnapshot.docs[0].exists()) {
              const latestMessage = docSnapshot.docs[0].data();
              if (latestMessage.timestamp) {
                setLiveTimestamps((prev) => ({
                  ...prev,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  [conversationId]: latestMessage.timestamp,
                }));
              }
            }
          });

          unsubscribes.push(unsubscribe);
        }
      });

      return () => {
        unsubscribes.forEach((unsub) => unsub());
      };
    }
  }, [conversationIds, db, firebaseUser, prevConversationIds]);

  return {
    latestMessageTimestamps: { ...initialTimestamps, ...liveTimestamps },
    isLoading,
    isError,
  };
};
