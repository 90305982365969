import { SVGProps } from 'react';

export const SimplyHookedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="24" viewBox="0 0 36 24" fill="none" {...props}>
    <g clipPath="url(#clip0_500_1588)">
      <path
        d="M34.4757 5.69725C33.441 3.88073 32.0249 2.47706 30.2276 1.48624C28.4304 0.495413 26.4425 0 24.2368 0C22.031 0 20.0431 0.495413 18.2458 1.48624C16.4486 2.47706 15.0325 3.88073 13.9977 5.69725C13.1536 7.15596 12.6634 8.80734 12.5 10.6239H18.382C18.5726 9.05505 19.09 7.81651 19.9614 6.88073C21.0779 5.69725 22.4667 5.11927 24.1278 5.11927C25.7889 5.11927 27.1777 5.69725 28.3215 6.88073C29.4652 8.06422 30.0371 9.74312 30.0371 11.945C30.0371 13.4312 29.7647 14.6697 29.2201 15.7156C28.6755 16.7339 27.9402 17.5321 27.0144 18.0275C26.0885 18.5229 25.1082 18.7982 24.0734 18.7982C23.9644 18.7982 23.8827 18.7982 23.7738 18.7706V23.8624H24.0734C26.3063 23.8624 28.3215 23.367 30.1187 22.3761C31.916 21.3853 33.3593 19.9817 34.4213 18.1651C35.4833 16.3486 36.0007 14.2569 36.0007 11.8899C36.0007 9.52294 35.5105 7.51379 34.4757 5.69725Z"
        fill="white"
      />
      <path
        d="M16.0393 17.0368C14.9228 18.2203 13.534 18.7983 11.8729 18.7983C10.2118 18.7983 8.82299 18.2203 7.67927 17.0368C6.53555 15.8533 5.96369 14.1744 5.96369 11.9726C5.96369 10.4864 6.236 9.24784 6.78063 8.20196C7.32526 7.18362 8.06051 6.38545 8.98638 5.89004C9.91225 5.39463 10.8926 5.1194 11.9274 5.1194C12.0363 5.1194 12.118 5.1194 12.2269 5.14692V0.0551758H11.9274C9.69439 0.0551758 7.67927 0.550589 5.88199 1.54141C4.08472 2.53224 2.64145 3.93591 1.57942 5.75242C0.517398 7.56894 0 9.66068 0 12.0277C0 14.3946 0.517397 16.4864 1.55219 18.3029C2.58699 20.1194 4.00302 21.5231 5.8003 22.5139C7.59757 23.5047 9.58547 24.0001 11.7912 24.0001C13.997 24.0001 15.9849 23.5047 17.7821 22.5139C19.5794 21.5231 20.9954 20.1194 22.0302 18.3029C22.8744 16.8442 23.3646 15.1928 23.528 13.3763H17.646C17.4554 14.8625 16.938 16.101 16.0393 17.0368Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_500_1588">
        <rect width="36" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
