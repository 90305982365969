import { CooperationConversationList } from '../../components/library/messaging/cooperation-conversation-list';
import { SupportConversationListItem } from '../../components/library/messaging/support-conversation-list-item';
import PageContainer from '../../components/page-container';

export const MessagesPage = () => {
  return (
    <PageContainer title="Nachrichten">
      <SupportConversationListItem className="border-top border-bottom" />
      <CooperationConversationList />
    </PageContainer>
  );
};
