import {
  CollectionReference,
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CooperationStatus } from '../../../../api/@types/cooperation-status';
import { Campaign } from '../../../../api/campaign/type';
import { useAuth } from '../../../../utils/auth/use-auth';
import { useSearchParamValue } from '../../../../utils/use-search-param-value';
import { OffcanvasMediakit } from '../../../mediakit/offcanvas-mediakit';
import { MessageInput, SendMessageFn } from './message-input';
import { Messages } from './messages';
import { Message } from './type';

export const UNREAD_COUNT_SUPPORT_KEY = 'support';
const PAGE_SIZE = 25;

interface ChatProps {
  onClose: () => void;
  conversationId: string;
  isSupportConversation?: boolean;
  campaign?: Campaign;
  conversationPartnerName?: string;
  hideLoading?: boolean;
  hideMediakit?: boolean;
  hideCampaignLink?: boolean;
  influencerId?: number;
  cooperationId?: number;
  cooperationStatus?: CooperationStatus;
}

export const Chat = ({
  onClose,
  conversationId,
  campaign,
  isSupportConversation = false,
  conversationPartnerName,
  hideMediakit = false,
  hideCampaignLink = false,
  influencerId,
  cooperationId,
  cooperationStatus,
}: ChatProps) => {
  const { t } = useTranslation();
  const [conversationPartnerUID, setConversationPartnerUID] = useState<string | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(true);
  const { db, firebaseUser } = useAuth();
  const userId = firebaseUser?.uid;
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });
  const [loadedPages, setLoadedPages] = useState<number>(1);

  const [mediakitOpen, setMediakitOpen] = useState(false);

  const closeMediakit = () => {
    setMediakitOpen(false);
  };

  const showMediakit = () => {
    setMediakitOpen(true);
  };

  const getConversationRef = useCallback((): DocumentReference | undefined => {
    if (userId) {
      let conversationRef: DocumentReference | undefined;
      try {
        if (!db) {
          console.error('Db connection not initialized');
          return;
        }
        conversationRef = doc(db, 'conversations', conversationId);
      } catch (error) {
        console.error('Requested conversation document does not exist', { conversationId, error });
        toast('Beim Abrufen der Konversation gab es einen Fehler.', { type: 'error' });
      }
      return conversationRef;
    }
  }, [conversationId, db, userId]);

  const getConversationMessagesRef = useCallback((): CollectionReference | undefined => {
    if (userId) {
      let conversationMessagesRef: CollectionReference | undefined;
      try {
        const conversationRef = getConversationRef();
        if (conversationRef) {
          conversationMessagesRef = collection(conversationRef, 'messages');
        }
      } catch (error) {
        console.error('Requested conversation document messages subcollection does not exist', {
          conversationId,
          error,
        });
        toast('Beim Abrufen der Nachrichten gab es einen Fehler.', { type: 'error' });
      }
      return conversationMessagesRef;
    }
  }, [conversationId, getConversationRef, userId]);

  // get conversation partner UID by looking at the userIds array of the conversation
  useEffect(() => {
    const fetchConversationPartnerUID = async () => {
      try {
        const conversationRef = getConversationRef();
        if (conversationRef) {
          const conversation = await getDoc(conversationRef);
          if (conversation.exists()) {
            const data = conversation.data();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const userIds = data?.userIds || [];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const otherUserIds = userIds.filter((u: string) => u !== userId);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (otherUserIds?.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              setConversationPartnerUID(otherUserIds[0]);
            } else if (!isSupportConversation) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              console.warn('Could not determine conversation partners UID', { conversationId, userIds });
              toast('Beim Abrufen des Konversationspartners gab es einen Fehler.', { type: 'error' });
            }
          } else {
            console.error('Requested conversation document does not exist', { conversationId });
            toast('Beim Abrufen der Konversation gab es einen Fehler.', { type: 'error' });
          }
        }
      } catch (error) {
        console.error('Requested conversation document messages subcollection does not exist', { conversationId });
        toast('Beim Abrufen der Konversation gab es einen Fehler.', { type: 'error' });
      }
    };

    if (userId && conversationId) void fetchConversationPartnerUID();
  }, [userId, conversationId, getConversationRef, isSupportConversation]);

  const sendMessage: SendMessageFn = async ({ type, text, contentUrl }) => {
    const conversationRef = getConversationRef();
    const conversationMessagesRef = getConversationMessagesRef();

    if (userId) {
      try {
        // increment unread counter of other participant or of support by 1
        if (conversationRef) {
          await updateDoc(conversationRef, {
            [`unreadCount-${isSupportConversation ? UNREAD_COUNT_SUPPORT_KEY : conversationPartnerUID}`]: increment(1),
          });
        }
        if (conversationMessagesRef) {
          await addDoc(conversationMessagesRef, {
            type,
            timestamp: serverTimestamp(),
            userId,
            ...(type === 'text' ? { text } : { mediaObject: new URL(contentUrl ?? '').pathname }),
          });
        }
        return true;
      } catch (error) {
        console.error('Error sending message:', error);
        toast('Beim Senden der Nachricht gab es einen Fehler.', { type: 'error' });
        return false;
      }
    } else {
      console.error('Tried to send a message while not beeing authenticated to firebase', { userId });
      return false;
    }
  };

  useEffect(() => {
    const conversationRef = getConversationRef();
    const conversationMessagesRef = getConversationMessagesRef();

    try {
      if (conversationMessagesRef) {
        const q = query(conversationMessagesRef, orderBy('timestamp', 'asc'));

        const unsubscribe = onSnapshot(
          q,
          (snapshot) => {
            setMessages(snapshot.docs.map((doc) => doc.data()) as Message[]);
            setLoadingMessages(false);

            // set unread counter of user or of support to 0 every time new messages are read
            if (conversationRef) {
              void updateDoc(conversationRef, {
                [`unreadCount-${isInsideAdminPanel ? UNREAD_COUNT_SUPPORT_KEY : userId}`]: 0,
              });
            }
          },
          (error) => {
            toast('Beim Abrufen der Nachrichten gab es einen Fehler.', { type: 'error' });
            console.error('Firebase snapshot to get conversation messages failed', { error });
          },
        );

        return unsubscribe;
      }
    } catch (error) {
      toast('Beim Abrufen der Nachrichten gab es einen Fehler.', { type: 'error' });
      console.error('Firebase snapshot to get conversation messages failed', { error });
    }
  }, [getConversationMessagesRef, getConversationRef, isInsideAdminPanel, userId]);

  const loadedMessages = messages.slice(-loadedPages * PAGE_SIZE);

  const loadMore = () => setLoadedPages((n) => n + 1);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-grow-0 w-100 d-flex justify-content-between border-bottom px-5 py-3">
        <div>
          {isSupportConversation ? (
            <>
              <h4>SimplyHooked</h4>
              <p className="mb-2">Wie können wir dir weiterhelfen?</p>
            </>
          ) : (
            <>
              <h4>{conversationPartnerName ?? t('loading')}</h4>
              {campaign && (
                <>
                  <p className="mb-2">
                    <strong>{campaign?.name}</strong>
                    <br />
                    {`${t(`platformType.${campaign?.platformType}`)}: ${t(`campaignType.${campaign?.campaignType}`)}`}
                  </p>
                  {!hideCampaignLink && (
                    <Link
                      to={`/app/campaigns/${campaign?.internalId}`}
                      className="me-3 text-primary fw-semibold cursor-pointer"
                    >
                      Kampagne
                    </Link>
                  )}
                </>
              )}
              {!hideMediakit && (
                <a className="text-primary fw-semibold cursor-pointer" onClick={showMediakit}>
                  Media Kit
                </a>
              )}
            </>
          )}
        </div>
        <h5>
          <a onClick={onClose} className={'text-primary fw-semibold cursor-pointer'}>
            Schliessen
          </a>
        </h5>
      </div>
      <div
        id="chat-scrollable-container"
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          flexGrow: 1,
        }}
      >
        <InfiniteScroll
          dataLength={loadedMessages.length}
          next={loadMore}
          hasMore={loadedMessages.length < messages.length}
          loader={<div className="text-center pb-5">{t('loading')}</div>}
          scrollableTarget="chat-scrollable-container"
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
        >
          <Messages messages={loadedMessages} loading={loadingMessages} totalMessageCount={messages.length} />
        </InfiniteScroll>
      </div>
      <footer className="flex-grow-0">
        <MessageInput sendMessage={sendMessage} />
      </footer>
      {cooperationId && cooperationStatus && (
        <OffcanvasMediakit
          show={mediakitOpen}
          onClose={closeMediakit}
          influencerId={influencerId}
          cooperationId={cooperationId}
          hideIdentity={['applied', 'declined'].includes(cooperationStatus)}
        />
      )}
    </div>
  );
};
