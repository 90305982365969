import { SVGProps } from 'react';

export const ImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg strokeWidth={0.5} width="24" height="24" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_768_1886)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.284 21.4283C22.7574 21.4283 23.1412 21.0445 23.1412 20.5711V9.42829C23.1412 8.95491 22.7574 8.57115 22.284 8.57115L7.71261 8.57115C7.23922 8.57115 6.85547 8.95491 6.85547 9.42829V20.5711C6.85547 21.0445 7.23922 21.4283 7.71261 21.4283L22.284 21.4283ZM24.8555 20.5711C24.8555 21.9913 23.7042 23.1426 22.284 23.1426L7.71261 23.1426C6.29245 23.1426 5.14118 21.9913 5.14118 20.5711V9.42829C5.14118 8.00813 6.29245 6.85686 7.71261 6.85686L22.284 6.85686C23.7042 6.85686 24.8555 8.00813 24.8555 9.42829V20.5711Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4269 4.28557C3.19957 4.28557 2.98155 4.37588 2.82081 4.53663C2.66006 4.69737 2.56975 4.91539 2.56975 5.14272V17.9999C2.56975 18.4732 2.186 18.857 1.71261 18.857C1.23922 18.857 0.855469 18.4732 0.855469 17.9999V5.14272C0.855469 4.46073 1.12639 3.80668 1.60862 3.32444C2.09086 2.84221 2.74491 2.57129 3.4269 2.57129H18.8555C19.3289 2.57129 19.7126 2.95504 19.7126 3.42843C19.7126 3.90182 19.3289 4.28557 18.8555 4.28557H3.4269Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3687 15.4002C15.26 15.3534 15.1427 15.3297 15.0243 15.3305C14.906 15.3313 14.789 15.3566 14.6809 15.4049C14.5728 15.4531 14.4759 15.5232 14.3962 15.6108L14.3846 15.6232L7.64748 22.7375C7.32198 23.0812 6.77946 23.096 6.43574 22.7705C6.09202 22.445 6.07725 21.9025 6.40275 21.5588L13.1346 14.4501C13.3724 14.1906 13.661 13.9827 13.9825 13.8393C14.3068 13.6946 14.6576 13.6187 15.0127 13.6163C15.3678 13.6139 15.7196 13.685 16.0458 13.8253C16.3706 13.9649 16.6631 14.17 16.905 14.4278L23.6738 21.5222C24.0006 21.8647 23.9879 22.4072 23.6454 22.734C23.3029 23.0608 22.7603 23.0481 22.4335 22.7056L15.6562 15.6022C15.5753 15.5158 15.4775 15.4469 15.3687 15.4002Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_1886">
        <rect width="24" height="24" fill="white" transform="translate(0.855469 0.857422)" />
      </clipPath>
    </defs>
  </svg>
);
