import {Link} from 'react-router-dom';
import {useAuthPagesContext} from '../../auth-pages-container/use-auth-pages-context';
import {useEffect} from "react";

export const RegisterLandingPage = () => {
  const {setCardClassName} = useAuthPagesContext();

  useEffect(() => {
    setCardClassName("tw-w-[972px]");
    return () => setCardClassName("tw-w-[486px]"); // Reset on unmount
  }, [setCardClassName]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6 pe-md-4  md:tw-border-[0] md:tw-border-r md:tw-border-solid md:tw-border-black">
          <span className={'h3'}>Erstelle dein neues Unternehmenskonto</span>
          <div className={'mt-4'}>
            Registriere dich jetzt und finde zielgerichtet passende Influencer für starke Kampagnen.
          </div>
          <Link to="/auth/register" className={'btn btn-primary-gradient mt-4 tw-w-full sm:tw-w-auto'}>
            Als Unternehmen registrieren
          </Link>
        </div>
        <div className="col-sm-6 ps-md-4 mt-6 mt-md-0">
          <span className={'h3'}>Influencer?</span>
          <div className={'mt-4'}>
            Möchtest du als Influencer eine Kooperation mit Unternehmen starten?
          </div>
          <Link to="https://calendly.com/simplyhooked/sh-app-integration-influencer" target="_blank"
                className={'btn btn-primary-gradient mt-4 tw-w-full sm:tw-w-auto'}>
            Als Influencer registrieren
          </Link>
        </div>
      </div>

      <p className={'mt-7'}>
        <Link to="/auth" className={'text-primary fw-bold'}>
          Anmelden mit deinem Konto
        </Link>
        <br/>
      </p>
    </div>
  );
};
