import { Form } from 'react-bootstrap';
import { CampaignFormGroup } from './campaign-form-group';
import { RichTextEditor } from '../library/rich-text-editor/rich-text-editor';
import { UpdateFormFn } from './campaign-form';

interface CampaignRequirementsProps {
  value?: string;
  updateForm: UpdateFormFn;
  error?: string;
}

export const CampaignRequirements = ({ value, updateForm, error }: CampaignRequirementsProps) => {
  return (
    <CampaignFormGroup title={'Voraussetzungen'} controlId={'campaignRequirements'}>
      <RichTextEditor
        value={value ?? ''}
        onChange={(newValue) => {
          if (newValue !== value) {
            // only update if the value has changed
            updateForm({ name: 'requirements', value: newValue });
          }
        }}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </CampaignFormGroup>
  );
};
