import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { CampaignStatus } from '../@types/campaign-status';
import { HydraMultiResponse } from '../type';
import { Campaign } from './type';

type Response = HydraMultiResponse<Campaign>;

export const CAMPAIGNS_QUERY_KEY = 'campaigns';
export const CAMPAIGNS_LIST_QUERY_KEY = 'campaigns-list';

export const useGetCampaigns = (status: CampaignStatus = 'running') => {
  const { phpToken } = useAuth();

  const getCampaigns = async () => {
    const campaigns = await httpFetch<Response>(`/api/campaigns.jsonld?status=${status}`, { phpToken });
    return campaigns;
  };

  return useQuery<Response>([CAMPAIGNS_LIST_QUERY_KEY, CAMPAIGNS_QUERY_KEY, status, phpToken], getCampaigns);
};
