import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { CrossedEyeIcon } from './icons/crossed-eye-icon';
import { EyeIcon } from './icons/eye-icon';

interface TogglePasswordInputGroupProps {
  password: string;
  setPassword: (password: string) => void;
  isInvalid?: boolean;
}

export const TogglePasswordInputGroup = ({
  password,
  setPassword,
  isInvalid = false,
}: TogglePasswordInputGroupProps) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <InputGroup className={'input-group-inline'}>
      <Form.Control
        required
        type={passwordShown ? 'text' : 'password'}
        value={password}
        name="password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Passwort"
        isInvalid={isInvalid}
      />
      <Button className="togglePassword" id="togglePassword" onClick={togglePassword}>
        {passwordShown ? <CrossedEyeIcon style={{ transform: 'translateY(-0.5px)' }} /> : <EyeIcon />}
      </Button>
    </InputGroup>
  );
};
