import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { MediaObject } from '../mediaobject/type';
import { HydraMember } from '../type';
import { CAMPAIGNS_QUERY_KEY } from './use-get-campaigns';

interface UsePostCampaignMediaObjectProps {
  onSuccess: (uploadedMediaObject: HydraMember<MediaObject>) => void;
  onError: () => void;
}

export const usePostCampaignMediaObject = ({ onSuccess, onError }: UsePostCampaignMediaObjectProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const postCampaignMediaObject = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const uploadedMediaObject = await httpFetch<HydraMember<MediaObject>>(`/api/campaign-media-objects`, {
      init: {
        method: 'POST',
        body: formData,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: 'application/ld+json',
        },
      },
      phpToken,
    });

    return uploadedMediaObject;
  };

  return useMutation(postCampaignMediaObject, {
    onError: () => onError(),
    onSuccess: (uploadedMediaObject) => {
      void queryClient.refetchQueries([CAMPAIGNS_QUERY_KEY]);
      onSuccess(uploadedMediaObject);
    },
  });
};
