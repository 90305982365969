import { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { usePostMediaObject } from '../../../../api/mediaobject/use-post-media-object';
import { MessageType } from './type';
import './message-input.scss';

interface MessageInputProps {
  sendMessage: SendMessageFn;
}

export type SendMessageFn = (params: { type: MessageType; text?: string; contentUrl?: string }) => Promise<boolean>;

export const MessageInput = ({ sendMessage }: MessageInputProps) => {
  const [value, setValue] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFilePicker = () => {
    if (fileInputRef?.current) fileInputRef.current.click();
  };

  const { mutate: postMediaObject } = usePostMediaObject({
    onSuccess: (mediaobject) => {
      void sendMessage({ type: 'media', contentUrl: mediaobject?.contentUrl });
      // reset file value to allow uploading the same image twice
      if (fileInputRef?.current) fileInputRef.current.value = '';
    },
    onError: () => {
      toast('Beim Hochladen des Bildes gab es einen Fehler.', { type: 'error' });
      console.error('Failed to upload image');
    },
  });

  const onImageInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      const file = event.target.files[0];
      postMediaObject(file);
    }
  };

  const onChangeText: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const hasTextInput = !!value?.trim();

  const handleSubmitText = async () => {
    if (value !== '') {
      const success = await sendMessage({ type: 'text', text: value });
      if (success) setValue('');
    }
  };

  const onLabelClick: MouseEventHandler<HTMLLabelElement> = (e) => {
    const clickedOnIcon = (e.target as HTMLLabelElement)?.id == 'message-input-submit-icon';
    if (clickedOnIcon) {
      e.preventDefault();

      if (hasTextInput) void handleSubmitText();
      else openFilePicker();
    }
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') void handleSubmitText();
  };

  return (
    <div className="message-input">
      <div className="px-5 py-3">
        <label
          id="message-input-submit-icon"
          className={`w-100 ${hasTextInput ? 'submit-icon' : 'image-icon'}`}
          onClick={onLabelClick}
        >
          <FormControl
            value={value}
            onChange={onChangeText}
            type="text"
            placeholder="Nachricht schreiben ..."
            className="ps-3"
            onKeyDown={onKeyDown}
          />
        </label>
      </div>
      <input type="file" accept=".jpg,.png" ref={fileInputRef} onChange={onImageInput} style={{ display: 'none' }} />
    </div>
  );
};
