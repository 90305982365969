import { SVGProps } from 'react';

export const MediakitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 5.99993C10.1067 5.99993 8.57171 7.53495 8.57171 9.4285C8.57171 11.322 10.1067 12.8571 12.0003 12.8571C13.8938 12.8571 15.4289 11.322 15.4289 9.4285C15.4289 7.53495 13.8938 5.99993 12.0003 5.99993ZM6.85742 9.4285C6.85742 6.58818 9.15996 4.28564 12.0003 4.28564C14.8406 4.28564 17.1431 6.58818 17.1431 9.4285C17.1431 12.2688 14.8406 14.5714 12.0003 14.5714C9.15996 14.5714 6.85742 12.2688 6.85742 9.4285Z"
      fill="#212122"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75847 17.7861C7.48153 16.2642 9.70126 15.4243 12.0002 15.4243C14.2991 15.4243 16.5188 16.2642 18.2419 17.7861C18.5967 18.0995 18.6303 18.6412 18.3169 18.996C18.0035 19.3508 17.4619 19.3844 17.107 19.071C15.6973 17.8258 13.8811 17.1386 12.0002 17.1386C10.1192 17.1386 8.30311 17.8258 6.89333 19.071C6.53853 19.3844 5.99685 19.3508 5.68347 18.996C5.37009 18.6412 5.40367 18.0995 5.75847 17.7861Z"
      fill="#212122"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1426 17.1428C23.616 17.1428 23.9997 17.5266 23.9997 18V21.4285C23.9997 22.1105 23.7288 22.7646 23.2466 23.2468C22.7643 23.729 22.1103 24 21.4283 24H17.9997C17.5263 24 17.1426 23.6162 17.1426 23.1428C17.1426 22.6694 17.5263 22.2857 17.9997 22.2857H21.4283C21.6556 22.2857 21.8736 22.1954 22.0344 22.0346C22.1951 21.8739 22.2854 21.6559 22.2854 21.4285V18C22.2854 17.5266 22.6692 17.1428 23.1426 17.1428Z"
      fill="#212122"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1426 0.857143C17.1426 0.383756 17.5263 0 17.9997 0H21.4283C22.1103 0 22.7643 0.270918 23.2466 0.753154C23.7288 1.23539 23.9997 1.88944 23.9997 2.57143V6C23.9997 6.47339 23.616 6.85714 23.1426 6.85714C22.6692 6.85714 22.2854 6.47339 22.2854 6V2.57143C22.2854 2.3441 22.1951 2.12608 22.0344 1.96534C21.8736 1.80459 21.6556 1.71429 21.4283 1.71429H17.9997C17.5263 1.71429 17.1426 1.33053 17.1426 0.857143Z"
      fill="#212122"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57143 1.71429C2.3441 1.71429 2.12608 1.80459 1.96534 1.96534C1.80459 2.12608 1.71429 2.3441 1.71429 2.57143V6C1.71429 6.47339 1.33053 6.85714 0.857143 6.85714C0.383756 6.85714 0 6.47339 0 6V2.57143C0 1.88944 0.270918 1.23539 0.753154 0.753154C1.23539 0.270918 1.88944 0 2.57143 0H6C6.47339 0 6.85714 0.383756 6.85714 0.857143C6.85714 1.33053 6.47339 1.71429 6 1.71429H2.57143Z"
      fill="#212122"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.857143 17.1428C1.33053 17.1428 1.71429 17.5266 1.71429 18V21.4285C1.71429 21.6559 1.80459 21.8739 1.96534 22.0346C2.12608 22.1954 2.3441 22.2857 2.57143 22.2857H6C6.47339 22.2857 6.85714 22.6694 6.85714 23.1428C6.85714 23.6162 6.47339 24 6 24H2.57143C1.88944 24 1.23539 23.729 0.753154 23.2468C0.270918 22.7646 0 22.1105 0 21.4285V18C0 17.5266 0.383756 17.1428 0.857143 17.1428Z"
      fill="#212122"
    />
  </svg>
);
