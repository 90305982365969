import dayjs from 'dayjs';
import { Dropdown, Image, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CampaignType } from '../../../api/@types/campaign-type';
import { Campaign } from '../../../api/campaign/type';
import { CampaignCategoryBadge } from '../../../components/campaign-category-badge';
import { CampaignStatusBadge } from '../../../components/campaign-status-badge';
import { DropdownIcon } from '../../../components/library/icons/dropdown-icon';
import { extractURLId } from '../../../utils/extract-url-id';
import { SanitizedHtml } from '../../../utils/sanitized-html';

interface OffcanvasCampaignProps {
  campaign?: Campaign;
  show: boolean;
  onHide: () => void;
  onEditClick: () => void;
}

export const OffcanvasCampaign = ({ campaign, show, onHide, onEditClick }: OffcanvasCampaignProps) => {
  const { t } = useTranslation();

  return (
    <Offcanvas show={show} onHide={onHide} placement={'end'}>
      <Offcanvas.Header className="border-bottom w-100 d-flex justify-content-between">
        <strong>Kampagnenbriefing</strong>
        <span>
          <Dropdown className="d-inline me-3">
            <Dropdown.Toggle variant="link" className="p-0 no-chevron tw-h-5">
              <DropdownIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow border-0">
              <Dropdown.Item className="text-primary lh-1" onClick={onEditClick}>
                <strong>Bearbeiten</strong>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <a href="#" onClick={onHide} className="text-primary fw-semibold cursor-pointer">
            Schliessen
          </a>
        </span>
      </Offcanvas.Header>

      <Offcanvas.Body>
        {campaign?.teaserImage && (
          <Image src={campaign?.teaserImage.contentUrl} className="rounded img-fluid mb-5" alt="..." />
        )}
        <CampaignStatusBadge campaignStatus={campaign?.status} />
        <h4 className={'mt-3 fw-medium mb-5'}>{campaign?.name}</h4>
        <p>
          <strong>Plattfrom</strong>
          <br />
          {campaign?.platformType ? t(`platformType.${campaign.platformType}`) : t('loading')}
          <br />
        </p>
        <p>
          <strong>Kampagnenart</strong>
          <br />
          {campaign?.campaignType ? t(`campaignType.${campaign.campaignType}`) : t('loading')}
          <br />
        </p>
        <p>
          <strong>Vergütungsart</strong>
          <br />
          {campaign?.campaignRemunerationType ? t(`campaignRemunerationType.${campaign.campaignRemunerationType}`) : t('loading')}
          <br />
        </p>
        <p>
          <strong>Vergütung</strong>
          <br />
          {`${campaign?.remuneration} ${campaign?.campaignType === CampaignType.ProductPlacement ? campaign?.currency ?? "" : ""}`}
          <br />
        </p>
        <p>
          <strong>Zeitraum</strong>
          <br />
          {dayjs(campaign?.startDate).format('DD.MM.YYYY')} - {dayjs(campaign?.endDate).format('DD.MM.YYYY')}
          <br />
        </p>
        <p>
          <strong>Bereich</strong>
          <br />
          <span className="block tw-mt-1 tw-flex tw-gap-1 tw-flex-wrap">
            {campaign?.categories?.map((categoryUrl) => (
              <CampaignCategoryBadge key={categoryUrl} categoryId={extractURLId(categoryUrl)} />
            ))}
          </span>
        </p>
        <hr className="tw-my-10" />
        <h3 className={'mt-6'}>Kampagnenbriefing</h3>
        <h4 className={'mt-6'}>Gewünschte Herangehensweise</h4>
        <p>
          <SanitizedHtml unsanitizedHtml={campaign?.approach ?? ''} />
        </p>
        <h4 className={'mt-6'}>Voraussetzungen</h4>
        <p>
          <SanitizedHtml unsanitizedHtml={campaign?.requirements ?? ''} />
        </p>
        <h4 className={'mt-6'}>Produktinformationen</h4>
        <p>
          <SanitizedHtml unsanitizedHtml={campaign?.productInformation ?? ''} />
        </p>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
