import { NavLink } from 'react-router-dom';
import { Button } from '../../../../components/library/button';
import { RocketIcon } from '../../../../components/library/icons/rocket-icon';

export const SuccessRequestedPasswordResetPage = () => {
  return (
    <div>
      <RocketIcon />
      <p className={'h3 my-3'}>Eine Email zur Zurücksetzung des Passworts ist unterwegs zu dir.</p>
      <div className="d-grid gap-2">
        <NavLink to="/login">
          <Button buttonProps={{ variant: 'primary-gradient w-100' }}>Zurück zum Login</Button>
        </NavLink>
      </div>
    </div>
  );
};
