import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UNREAD_COUNT_SUPPORT_KEY } from '../../components/library/messaging/chat/chat';
import { useAuth } from '../../utils/auth/use-auth';
import { useSearchParamValue } from '../../utils/use-search-param-value';

type UseTotalUnreadMessageCount = () => { count: number | undefined };

export const useTotalUnreadMessageCount: UseTotalUnreadMessageCount = () => {
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });
  const [count, setCount] = useState<number | undefined>(undefined);
  const { firebaseUser, db } = useAuth();

  const userId = firebaseUser?.uid;

  useEffect(() => {
    if (userId && db) {
      try {
        // count numbers of total unread messages
        const conversationsRef = collection(db, 'conversations');
        // query all conversations the user has access to
        const q = query(conversationsRef, where('userIds', 'array-contains', userId));

        return onSnapshot(
          q,
          (collection) => {
            if (!collection.empty) {
              const unreadCounts = collection.docs.map((doc) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                doc.get(`unreadCount-${isInsideAdminPanel ? UNREAD_COUNT_SUPPORT_KEY : userId}`),
              );
              setCount(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
                unreadCounts.filter((x) => x).reduce((a, b) => a + b, 0),
              );
            } else {
              console.warn('Conversations collection was empty.');
            }
          },
          (error) => {
            toast('Beim Abrufen der Nachrichten gab es einen Fehler.', {
              type: 'error',
            });
            console.error('Firebase snapshot to get conversations failed', {
              error,
            });
          },
        );
      } catch (error) {
        console.error('Requested conversation documents do not exist', {
          userId,
          error,
        });
      }
    }
  }, [db, isInsideAdminPanel, userId]);

  return { count };
};
