import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './detail-page-container.scss';
import { BackIcon } from './library/icons/back-icon';
import { DropdownIcon } from './library/icons/dropdown-icon';

interface DetailPageContainerProps {
  title: string;
  backLink?: string;
  dropdownMenu?: React.JSX.Element;
  children: React.JSX.Element;
  useFullWidth?: boolean;
}

export const DetailPageContainer = ({
  title,
  backLink,
  children,
  useFullWidth = false,
  dropdownMenu,
}: DetailPageContainerProps) => {
  const getTitle = () => {
    if (!backLink) {
      return <strong>{title}</strong>;
    } else {
      return (
        <Link to={backLink} className="tw-flex tw-items-center back-link">
          <BackIcon />
          <strong>{title}</strong>
        </Link>
      );
    }
  };

  const getDropdownMenu = () => {
    if (!dropdownMenu) {
      return null;
    }

    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" id="dropdown-basic" className={'p-0 no-chevron tw-h-5'}>
          <DropdownIcon />
        </Dropdown.Toggle>
        {dropdownMenu}
      </Dropdown>
    );
  };

  return (
    <div className={'detail-page-container ms-0 ps-0 tw-px-0 tw-w-full tw-h-full tw-flex tw-flex-col'}>
      <div
        className="in-page-navbar border-bottom sticky-top bg-white tw-px-5 lg:tw-px-10 py-4 tw-mx-0 tw-flex tw-items-center tw-justify-between"
        style={{ height: '72px' }}
      >
        <div>{getTitle()}</div>
        <div className={'text-end'}>{getDropdownMenu()}</div>
      </div>
      <main className={`tw-grow tw-w-full tw-overflow-auto`}>
        <div className={`${useFullWidth ? '' : 'tw-max-w-7xl'}`}>{children}</div>
      </main>
    </div>
  );
};
