import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { CooperationStatus } from '../@types/cooperation-status';
import { HydraMultiResponse } from '../type';
import { Cooperation } from './type';

type Response = HydraMultiResponse<Cooperation>;

export const COOPERATIONS_QUERY_KEY = 'cooperations';

interface UseGetCooperationsParams {
  campaignId?: number;
  status?: CooperationStatus[];
}

export const useGetCooperations = ({ campaignId, status }: UseGetCooperationsParams) => {
  const { phpToken } = useAuth();

  const getCooperations = async () => {
    const statusSearchParam = status ? `${status.map((status) => `status[]=${status}`).join('&')}` : '';
    const campaignSearchParam = campaignId ? `campaign=${campaignId}` : '';

    const cooperations = await httpFetch<Response>(
      `/api/cooperations.jsonld?${campaignSearchParam}&${statusSearchParam}`,
      { phpToken },
    );
    return cooperations;
  };

  return useQuery<Response>([COOPERATIONS_QUERY_KEY, campaignId, status, phpToken], getCooperations, {});
};
