import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { Mediakit } from './type';

type Response = HydraMember<Mediakit>;

export const useGetMediakit = (id?: number) => {
  const { phpToken } = useAuth();

  const getMediakit = async () => {
    const mediakit = await httpFetch<Response>(`/api/influencers/${id}/media_kit`, {
      phpToken,
    });
    return mediakit;
  };

  return useQuery<Response>([id, phpToken], getMediakit, { enabled: !!id || id === 0 });
};
