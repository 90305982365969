import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../utils/auth/use-auth';
import { httpFetch } from '../../utils/http-fetch';
import { CAMPAIGNS_LIST_QUERY_KEY } from './use-get-campaigns';

interface UseDeleteCampaignProps {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const useDeleteCampaign = ({ onSuccess, onError }: UseDeleteCampaignProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const deleteCampaignHandler = (campaignId: number) => {
    return httpFetch(`/api/campaigns/${campaignId}`, {
      init: {
        method: 'DELETE',
      },
      parseJsonResponse: false,
      isCustomErrorResponse: true,
      phpToken,
    });
  };

  return useMutation(deleteCampaignHandler, {
    onError: (error) => onError(error),
    onSuccess: () => {
      void queryClient.refetchQueries([CAMPAIGNS_LIST_QUERY_KEY]);
      onSuccess();
    },
  });
};
