import Lightbox from 'bs5-lightbox';
import { useEffect } from 'react';
import { MediakitPlatformMediaObject } from '../../../api/influencer/type';
import { BS5LightboxConfig } from '../../../config/bs5-lightbox-config';
import './platform-preview-gallery.scss';

const MAX_INLINE_VIEWABLE_IMAGES = 12;

interface PlatformPreviewGalleryProps {
  mediaObjects: MediakitPlatformMediaObject[];
}

export const PlatformPreviewGallery = ({ mediaObjects }: PlatformPreviewGalleryProps) => {
  // add listeners of bs5-lightbox to image elements
  useEffect(() => {
    const images = document.querySelectorAll('.lightbox-toggle');
    const elementListenerFns: [Element, EventListenerOrEventListenerObject][] = [];
    images.forEach((el) => {
      const listener: EventListenerOrEventListenerObject = (e) => {
        e.preventDefault();
        const lightbox = new Lightbox(el as HTMLElement, BS5LightboxConfig);
        lightbox.show();
      };
      el.addEventListener('click', listener);
      elementListenerFns.push([el, listener]);
    });

    // cleanup old listeners when mediaobjects change
    return () => elementListenerFns.forEach(([el, listener]) => el.removeEventListener('click', listener));
  }, [mediaObjects]);

  const hasMoreThanMaxInlineViewableImages = (mediaObjects?.length ?? 0) > MAX_INLINE_VIEWABLE_IMAGES;
  const lastInlineImage = hasMoreThanMaxInlineViewableImages ? mediaObjects[MAX_INLINE_VIEWABLE_IMAGES - 1] : undefined;

  return (
    <div className="platform-preview-gallery block tw-flex tw-gap-4 tw-flex-wrap">
      {mediaObjects && mediaObjects.length === 0
        ? 'Keine'
        : mediaObjects?.map(({ contentUrl, internalId }, i) => {
            return (
              <a
                key={`platform-preview-image-${internalId}`}
                href={contentUrl}
                data-gallery="mediakit-gallery"
                data-type="image"
                className={`lightbox-toggle ${
                  hasMoreThanMaxInlineViewableImages && i + 1 >= MAX_INLINE_VIEWABLE_IMAGES ? 'tw-hidden' : ''
                }`}
              >
                <img src={contentUrl} />
              </a>
            );
          })}
      {hasMoreThanMaxInlineViewableImages && (
        <a
          key={`platform-preview-image-show-more`}
          href={lastInlineImage?.contentUrl}
          data-gallery="mediakit-gallery"
          data-type="image"
          className="lightbox-toggle show-more-button tw-font-semibold tw-text-xl tw-flex tw-items-center tw-justify-center"
        >
          +{(mediaObjects?.length ?? 0) - MAX_INLINE_VIEWABLE_IMAGES + 1}
          {/* +1 because last image gets replaced with show more button */}
        </a>
      )}
    </div>
  );
};
