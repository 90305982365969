import { Col, Container, Modal, Row } from 'react-bootstrap';
import './fullscreen-modal.scss';

interface FullscreenModalProps {
  title: string;
  actions: React.JSX.Element;
  children: React.JSX.Element;
  open?: boolean;
}

export const FullscreenModal = ({ title, actions, children, open = true }: FullscreenModalProps) => {
  return (
    <Modal fullscreen={true} show={open} className="fullscreen-modal">
      <Modal.Body className={'p-0'}>
        <Container fluid={true} className={'ps-0 sticky-top'}>
          <Row className={'in-page-navbar border-bottom d-flex justify-content-between bg-white'}>
            <Col className={'py-3 ps-5'}>
              <strong>{title}</strong>
            </Col>
            <Col className={'text-end py-2 pe-5'}>{actions}</Col>
          </Row>
        </Container>
        {children}
      </Modal.Body>
    </Modal>
  );
};
