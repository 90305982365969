import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../utils/auth/use-auth';
import { httpFetch } from '../../utils/http-fetch';
import { USER_QUERY_KEY } from './use-get-current-php-user';

interface UseResendRegistrationConfirmEmailProps {
  onSuccess: () => void;
  onError: () => void;
}

export const useResendRegistrationConfirmEmail = ({ onSuccess, onError }: UseResendRegistrationConfirmEmailProps) => {
  const queryClient = useQueryClient();
  const { phpToken } = useAuth();

  const postResendRegistrationConfirmEmailHandler = () => {
    return httpFetch<{ conversationId: string }>(`/api/auth/resend_registration_confirm_email`, {
      init: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
      parseJsonResponse: false,
      isCustomErrorResponse: true,
      phpToken,
    });
  };

  return useMutation(postResendRegistrationConfirmEmailHandler, {
    onError,
    onSuccess: () => {
      void queryClient.refetchQueries([USER_QUERY_KEY]);
      onSuccess();
    },
  });
};
