import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HydraMember } from '../../api/type';
import { User } from '../../api/user/type';
import { useGetCurrentPhpUser } from '../../api/user/use-get-current-php-user';
import { LabeledFormGroupRow } from '../../components/library/labeled-form-group-row/labeled-form-group-row';
import { LoadingBoundary } from '../../components/library/loading-boundary';
import PageContainer from '../../components/page-container';

export const SettingsPage = () => {
  const navigate = useNavigate();

  const { isLoading, data: usersResponse, error, isError } = useGetCurrentPhpUser();

  const user: HydraMember<User> | undefined =
    usersResponse && usersResponse?.['hydra:member']?.length > 0 ? usersResponse?.['hydra:member'][0] : undefined;

  const onPasswordChangeClick = () => navigate(`change-password`);

  return (
    <PageContainer title="Einstellungen">
      <LoadingBoundary isLoading={isLoading} error={error} isError={isError}>
        <LabeledFormGroupRow title="Email-Adresse" className="border-bottom border-top pb-7 pt-6">
          <span>
            <a className="text-primary" href={`mailto:${user?.email}`}>
              {user?.email}
            </a>
          </span>
          <p className="text-size-2 pt-3 mb-0">Diese Email-Adresse kann nicht geändert werden.</p>
        </LabeledFormGroupRow>
        <LabeledFormGroupRow title="Passwort" className="border-bottom pb-7 pt-6">
          <Form.Control className="mb-3" type="password" value={'••••••••••••••••••••'} disabled />
          <Button onClick={onPasswordChangeClick} className={'btn-primary-gradient btn-wrap'}>
            Passwort ändern
          </Button>
        </LabeledFormGroupRow>
      </LoadingBoundary>
    </PageContainer>
  );
};
