import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetCooperation } from '../../api/cooperation/use-get-cooperation';
import { useGetInfluencer } from '../../api/influencer/use-get-influencer';
import { useGetMediakit } from '../../api/influencer/use-get-mediakit';
import { extractURLId } from '../../utils/extract-url-id';
import { generatePlatformHandleLink } from '../../utils/generate-platform-handle-link';
import { CampaignCategoryBadge } from '../campaign-category-badge';
import { CooperationInfluencerStats } from './components/cooperation-influencer-stats';
import { PlatformPreviewGallery } from './components/platform-preview-gallery';

interface MediakitProps {
  influencerId?: number;
  cooperationId: number;
  hideIdentity: boolean;
}

export const Mediakit = ({ influencerId, cooperationId, hideIdentity = false }: MediakitProps) => {
  const { t } = useTranslation();
  const { data: mediakitResponse } = useGetMediakit(influencerId);
  const { data: cooperationResponse } = useGetCooperation(cooperationId);
  const { data: influencerResponse } = useGetInfluencer(influencerId);

  const platformOfCooperation =
    cooperationResponse?.platform &&
    mediakitResponse?.platforms.find((platform) => platform.internalId === extractURLId(cooperationResponse.platform));

  return (
    <div className="px-5 py-3 tw-flex tw-flex-col tw-gap-y-10">
      {!platformOfCooperation ? (
        t('loading')
      ) : (
        <>
          {!hideIdentity && platformOfCooperation && (
            <div>
              <span className="block tw-flex tw-gap-1 tw-flex-wrap">
                <Button
                  key={`${platformOfCooperation.platformType}-link`}
                  href={generatePlatformHandleLink(platformOfCooperation.platformType, platformOfCooperation.handle)}
                  target="_blank"
                  className={'btn-primary-gradient'}
                >
                  {`${t(`platformType.${platformOfCooperation.platformType}`)} Profil`}
                </Button>
              </span>
            </div>
          )}
          {mediakitResponse?.campaignCategories && mediakitResponse?.campaignCategories.length > 0 && (
            <div>
              <h3>Bereiche</h3>
              <span className="block tw-mt-1 tw-flex tw-gap-1 tw-flex-wrap">
                {mediakitResponse?.campaignCategories.map((categoryUrl) => (
                  <CampaignCategoryBadge key={categoryUrl} categoryId={extractURLId(categoryUrl)} />
                ))}
              </span>
            </div>
          )}
          <div key={`${platformOfCooperation.platformType}-data`}>
            <h3>{`${t(`platformType.${platformOfCooperation.platformType}`)} Daten`}</h3>
            <CooperationInfluencerStats cooperationId={cooperationId} />
          </div>
          <div>
            <h3>Insights & Content Beispiele</h3>
            <PlatformPreviewGallery mediaObjects={platformOfCooperation?.mediaObjects} />
          </div>
          {!hideIdentity && (
            <>
              <div>
                <h3>Persönliche Informationen</h3>
                {!influencerResponse ? (
                  t('loading')
                ) : (
                  <>
                    {`Name: ${influencerResponse?.firstname} ${influencerResponse?.lastname}`}
                    <br />
                    {influencerResponse?.gender && (
                      <>
                        {`Geschlecht: ${t(`gender.${influencerResponse?.gender}`)}`}
                        <br />
                      </>
                    )}
                    {influencerResponse?.dateOfBirth && (
                      <>
                        {`Geburtstag: ${new Date(influencerResponse?.dateOfBirth).toLocaleDateString(undefined, {
                          dateStyle: 'long',
                        })}`}
                        <br />
                      </>
                    )}
                  </>
                )}
              </div>
              <div>
                <h3>Überweisungsinformationen</h3>
                {!influencerResponse ? (
                  t('loading')
                ) : !influencerResponse?.billingAddress ? (
                  'Rechnungsadresse unbekannt'
                ) : (
                  <>
                    {influencerResponse?.billingAddress?.street}
                    <br />
                    {influencerResponse?.billingAddress?.zipCode} {influencerResponse?.billingAddress?.city}
                    <br />
                    {t(`countryType.${influencerResponse?.billingAddress?.country}`, {
                      defaultValue: influencerResponse?.billingAddress?.country,
                    })}
                  </>
                )}
              </div>
              <div>
                <h3>Bankverbindung</h3>
                {!influencerResponse ? t('loading') : `IBAN: ${influencerResponse?.account?.iban ?? 'Unbekannt'}`}
              </div>
              <div>
                <h3>Umsatzsteuer</h3>
                {!influencerResponse
                  ? t('loading')
                  : `Umsatzsteuernummer: ${influencerResponse?.vatNumber ?? 'Unbekannt'}`}
              </div>
              <div>
                <h3>Weitere Informationen</h3>
                {!influencerResponse ? t('loading') : influencerResponse?.furtherInformation ?? 'Keine'}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
