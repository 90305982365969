import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetCooperations } from '../../../api/cooperation/use-get-cooperations';
import { useFetchLatestMessageTimestamps } from '../../../api/messaging/use-fetch-latest-message-timestamp';
import { useFetchMessageExistence } from '../../../api/messaging/use-fetch-message-existence';
import { NoResultMessage } from '../no-result-message/no-result-message';
import { CooperationConversationListItem } from './cooperation-conversation-list-item';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const CAMPAIGNS_PATH = '/app/campaigns';
const PAGE_SIZE = 20;

export const CooperationConversationList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadedPages, setLoadedPages] = useState<number>(1);
  const { data: cooperationsResponse, isLoading: loadingCooperations } = useGetCooperations({});

  const cooperations = cooperationsResponse?.['hydra:member'] ?? [];

  const { hasMessages, loadingHasMessages } = useFetchMessageExistence(
    cooperations.map(({ conversationId }) => conversationId),
  );

  const { latestMessageTimestamps, isLoading: loadingConversationTimestamps } = useFetchLatestMessageTimestamps(
    cooperations.map(({ conversationId }) => conversationId),
  );

  const noResultsMessage = (
    <NoResultMessage
      className="mt-6"
      message={
        <>
          <p>Du hast noch keine Nachrichten.</p>
          <p>
            Du kannst deinen akzeptierten Kollaborationspartnern via deinen aktiven Kampagnen Nachrichten schreiben.
          </p>
          <Button onClick={() => navigate(CAMPAIGNS_PATH)} className={'btn-primary-gradient btn-wrap mt-2'}>
            Zu meinen Kampagnen
          </Button>
        </>
      }
    />
  );

  const cooperationsEmpty = !loadingCooperations && cooperations.length === 0;

  // check existence of messages
  if ((!loadingCooperations && !loadingHasMessages && !hasMessages) || cooperationsEmpty) return noResultsMessage;

  const sortedFilteredCooperations = cooperations
    // sort cooperations by the latest message timestamp
    .sort((cooperationA, cooperationB) => {
      const cooperationALatestMessageTimestamp = latestMessageTimestamps[cooperationA.conversationId]?.seconds || 0;
      const cooperationBLatestMessageTimestamp = latestMessageTimestamps[cooperationB.conversationId]?.seconds || 0;
      if (cooperationBLatestMessageTimestamp > cooperationALatestMessageTimestamp) return 1;
      if (cooperationBLatestMessageTimestamp < cooperationALatestMessageTimestamp) return -1;
      return 0;
    })
    // filter out cooperations without messages
    .filter((cooperation) => !!latestMessageTimestamps[cooperation.conversationId]);

  const loadedCooperations = sortedFilteredCooperations.slice(0, loadedPages * PAGE_SIZE);

  const loadMore = () => setLoadedPages((n) => n + 1);

  return (
    <>
      {loadingCooperations || loadingConversationTimestamps || !latestMessageTimestamps ? (
        t('loading')
      ) : (
        <InfiniteScroll
          dataLength={loadedCooperations.length}
          next={loadMore}
          hasMore={loadedCooperations.length < sortedFilteredCooperations.length}
          loader={<>{t('loading')}</>}
          scrollableTarget="main"
        >
          {loadedCooperations.map((cooperation) => (
            <CooperationConversationListItem
              key={cooperation.internalId}
              cooperation={cooperation}
              latestMessageTimestamp={latestMessageTimestamps[cooperation.conversationId]?.seconds}
              className={`border-bottom`}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
