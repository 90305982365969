import { MouseEventHandler } from 'react';
import { Card } from 'react-bootstrap';
import './no-result-message.scss';

interface NoResultMessageProps {
  message: React.JSX.Element | string;
  action?: MouseEventHandler<HTMLAnchorElement>;
  actionLabel?: string;
  className?: string;
}

export const NoResultMessage = ({ message, action, actionLabel, className = '' }: NoResultMessageProps) => {
  return (
    <Card className={`no-result-message ${className}`}>
      <Card.Body>
        <strong>{message}</strong>
        {action && actionLabel && (
          <>
            <br />
            <a href="#" onClick={action}>
              {actionLabel}
            </a>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
