import { Link } from 'react-router-dom';
import { useAuthPagesContext } from '../../auth-pages-container/use-auth-pages-context';
import { useEffectOnce } from 'usehooks-ts';

export const SuccessfulRegisteredPage = () => {
  const { setEmail, setPassword } = useAuthPagesContext();

  // clear local password and email
  useEffectOnce(() => {
    setPassword('');
    setEmail('');
  });

  return (
    <div>
      <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.10365 54.6134C2.14308 55.7398 6.42443 53.7755 9.80372 52.2003C12.3359 51.0235 22.4692 46.8658 27.5061 44.7091C28.866 44.1276 30.8441 43.3674 32.2682 41.4946C33.532 39.8279 36.8838 32.7488 30.1344 25.5781C23.2842 18.2975 16.228 20.3077 13.5905 22.1759C12.0383 23.2749 10.7745 25.7521 10.2067 27.0113C7.80728 32.3321 4.38678 42.0807 2.99477 45.9134C1.97366 48.7386 0.0733817 53.4961 1.10365 54.6134Z"
          fill="#FFC107"
        />
        <path
          d="M9.52832 28.6143C9.59242 29.411 9.75269 30.7069 10.3022 33.2024C10.6776 34.9149 11.2912 36.7099 11.7903 37.8959C13.2877 41.4629 15.3894 42.9007 17.5186 44.0546C21.136 46.0144 23.595 46.3807 23.595 46.3807L20.6461 47.585C20.6461 47.585 18.8603 47.2141 16.4243 46.0144C14.1027 44.8696 11.685 42.9327 9.91753 39.1413C9.15284 37.4975 8.70868 35.904 8.45226 34.6402C8.13631 33.0742 8.08594 32.1859 8.08594 32.1859L9.52832 28.6143Z"
          fill="#FF8F00"
        />
        <path
          d="M5.9079 37.9004C5.9079 37.9004 6.27422 40.8722 8.72855 44.6223C11.6042 49.009 15.6199 49.7279 15.6199 49.7279L12.9504 50.8269C12.9504 50.8269 9.96946 49.9156 7.13507 46.0464C5.36758 43.6333 4.87305 40.7485 4.87305 40.7485L5.9079 37.9004Z"
          fill="#FF8F00"
        />
        <path
          d="M3.36604 44.9424C3.36604 44.9424 4.03457 47.5066 5.49984 49.4206C7.24443 51.7055 9.46524 52.3695 9.46524 52.3695L7.41844 53.2761C7.41844 53.2761 5.86616 52.951 4.16736 50.7989C2.87608 49.1642 2.50977 47.2868 2.50977 47.2868L3.36604 44.9424Z"
          fill="#FF8F00"
        />
        <path
          opacity="0.44"
          d="M2.25367 51.4542C2.16209 51.2481 2.16209 51.0146 2.25825 50.8131L13.9209 26.627L15.8395 33.8389L3.56784 51.5641C3.23815 52.0586 2.49636 51.9945 2.25367 51.4542Z"
          fill="#FFFDE7"
        />
        <path
          d="M16.764 36.2613C22.2129 42.6352 28.4175 41.8385 30.4826 40.2267C32.5523 38.6103 34.187 33.056 28.7609 26.7873C23.0738 20.2211 16.6357 22.0939 15.1476 23.5134C13.6594 24.9328 11.7637 30.4139 16.764 36.2613Z"
          fill="url(#paint0_linear_536_1751)"
        />
        <path
          d="M35.4793 38.8846C33.492 37.2179 32.4343 37.5155 31.0148 38.1062C29.1832 38.8663 26.303 39.4295 22.3926 38.1062L23.5694 35.2718C25.8909 36.0548 27.5714 35.6748 29.0229 34.8185C30.8912 33.7196 33.4462 32.2131 37.4208 35.5511C39.0784 36.9432 40.7772 37.8681 42.0227 37.4468C42.9293 37.1446 43.4101 35.7938 43.6528 34.7178C43.6757 34.6216 43.7123 34.3469 43.7398 34.1042C43.9596 32.4237 44.3259 28.7971 47.0275 26.9427C49.9168 24.96 52.9527 24.96 52.9527 24.96L53.5022 30.4181C52.1056 30.2121 51.1349 30.496 50.3152 30.9493C47.229 32.6664 49.9168 39.2601 45.1135 41.4764C40.4933 43.6239 36.7156 39.9195 35.4793 38.8846Z"
          fill="#03A9F4"
        />
        <path
          d="M18.4816 31.9247L16.4943 30.1434C20.1438 26.0682 19.1822 23.0735 18.4816 20.8939C18.3396 20.4543 18.2069 20.0376 18.1198 19.6393C17.8085 18.2289 17.7444 17.0018 17.8405 15.9303C16.4394 14.1857 15.8212 12.3587 15.78 12.235C14.9283 9.65707 15.5694 7.14321 17.0392 4.78504C20.011 0 25.3913 0 25.3913 0L27.1862 4.80335C25.8217 4.74841 21.348 4.81709 19.9743 6.98295C18.2389 9.71202 19.3791 11.3971 19.4615 11.5894C19.7958 11.1544 20.1346 10.8064 20.4368 10.5362C22.6301 8.59017 24.535 8.31085 25.7484 8.42075C27.113 8.54438 28.3493 9.23123 29.233 10.3577C30.1992 11.594 30.5976 13.2012 30.2908 14.6619C29.9932 16.086 29.0453 17.2902 27.6212 18.0549C25.1349 19.392 23.0652 19.2088 21.6777 18.7464C21.6869 18.7784 21.6915 18.815 21.7006 18.8471C21.751 19.076 21.8517 19.3966 21.9708 19.7675C22.7813 22.2768 24.2877 26.2605 18.4816 31.9247ZM21.8471 14.7397C22.1127 14.9321 22.392 15.0923 22.6805 15.2068C23.6421 15.5914 24.6907 15.4632 25.8812 14.8222C26.5818 14.4467 26.6642 14.0437 26.6917 13.911C26.7741 13.5126 26.6368 13.0043 26.3391 12.6243C26.0781 12.29 25.7759 12.116 25.4142 12.0794C24.7273 12.0198 23.7978 12.4548 22.8683 13.2836C22.4241 13.682 22.0852 14.172 21.8471 14.7397Z"
          fill="#F44336"
        />
        <path
          d="M26.4334 32.6715L23.5898 32.5936C23.5898 32.5936 24.9406 24.965 29.3136 23.6829C30.1332 23.4448 31.0307 23.2021 31.9328 23.0693C32.4685 22.9869 33.3156 22.8633 33.7323 22.7076C33.8285 21.9887 33.5262 21.0729 33.1874 20.0335C32.9218 19.2276 32.6471 18.3988 32.5005 17.4921C32.2166 15.7246 32.6883 14.1632 33.8284 13.0872C35.2205 11.7821 37.4687 11.3655 40.0055 11.9424C41.4525 12.2721 42.5194 12.9818 43.4581 13.6046C44.7997 14.4975 45.5827 14.9508 47.222 13.8473C49.2047 12.5102 46.613 7.27642 45.2347 4.25429L50.3769 2.11133C51.0683 3.62239 54.4064 11.3975 52.2039 15.8345C51.4621 17.3273 50.1846 18.3163 48.5087 18.6872C44.8638 19.5023 42.73 18.0828 41.1731 17.048C40.4359 16.558 39.7903 16.1734 39.0897 15.9719C34.2222 14.5845 41.0175 21.746 37.8351 24.965C35.9256 26.8928 31.2596 27.4011 30.9574 27.4743C27.9536 28.1978 26.4334 32.6715 26.4334 32.6715Z"
          fill="#F48FB1"
        />
        <path
          d="M17.836 15.9307C17.749 16.938 17.7078 17.5379 17.9688 18.8475C19.228 19.7724 21.9708 19.7724 21.9708 19.7724C21.8517 19.4015 21.7464 19.081 21.7006 18.8521C21.6915 18.82 21.6869 18.7834 21.6777 18.7513C18.8891 17.3593 17.836 15.9307 17.836 15.9307Z"
          fill="#C92B27"
        />
        <path d="M12.1312 20.4402L7.39648 18.1187L9.75466 14.7119L13.4682 17.1708L12.1312 20.4402Z" fill="#FFC107" />
        <path
          d="M5.15136 14.0109C2.73365 13.6858 0.27016 11.6344 0 11.4009L2.37649 8.6123C3.09539 9.22131 4.62019 10.2424 5.64131 10.3798L5.15136 14.0109Z"
          fill="#FB8C00"
        />
        <path
          d="M9.41948 7.908L5.93945 6.76783C6.33782 5.54982 6.44314 4.23565 6.23709 2.96727L9.85448 2.38574C10.1521 4.22649 10.001 6.13593 9.41948 7.908Z"
          fill="#03A9F4"
        />
        <path d="M31.1523 5.18555L34.7309 4.40272L35.7642 9.12643L32.1857 9.90926L31.1523 5.18555Z" fill="#FB8C00" />
        <path
          d="M40.0302 6.30565L37.5117 3.64526C38.8305 2.3952 39.1327 0.760503 39.1327 0.742188L42.7501 1.33288C42.7043 1.62135 42.2418 4.21306 40.0302 6.30565Z"
          fill="#FFC107"
        />
        <path d="M41.4277 20.4141L44.627 19.4142L45.7198 22.9107L42.5205 23.9106L41.4277 20.4141Z" fill="#FB8C00" />
        <path
          d="M42.3609 49.9243L38.7206 49.4938C38.8763 48.198 37.9101 46.6091 37.6445 46.2565L40.5751 44.0586C40.7949 44.3471 42.7043 46.9891 42.3609 49.9243Z"
          fill="#F44336"
        />
        <path
          d="M52.81 45.281C51.4409 45.0749 50.0397 44.9925 48.6568 45.0429L48.5332 41.3797C50.1404 41.3247 51.766 41.4163 53.3549 41.659L52.81 45.281Z"
          fill="#FB8C00"
        />
        <path d="M47.8848 50.3242L50.4585 47.7175L54.0004 51.2146L51.4267 53.8213L47.8848 50.3242Z" fill="#F48FB1" />
        <path d="M40.3223 27.168L42.9689 30.1941L39.9427 32.8407L37.2962 29.8146L40.3223 27.168Z" fill="#F44336" />
        <defs>
          <linearGradient
            id="paint0_linear_536_1751"
            x1="31.7529"
            y1="26.4844"
            x2="18.1225"
            y2="34.6627"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0235" stopColor="#8F4700" />
            <stop offset="1" stopColor="#703E2D" />
          </linearGradient>
        </defs>
      </svg>
      <p className={'h3 mt-3'}>Danke für deine Registrierung. Eine Bestätigungs-Email ist unterwegs zu dir.</p>
      <Link to="/auth" className={'btn btn-primary-gradient mt-6'}>
        Zur Anmeldung
      </Link>
    </div>
  );
};
