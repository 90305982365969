interface PageContainerProps {
  title?: string;
  action?: React.JSX.Element | React.JSX.Element[];
  children?: React.JSX.Element | React.JSX.Element[];
  useFullWidth?: boolean;
}

const PageContainer = ({ title, action, children, useFullWidth = false }: PageContainerProps) => {
  return (
    <main id="main" className={`tw-px-5 lg:tw-px-10 tw-overflow-auto tw-h-screen`}>
      <div className="tw-grid tw-gap-5 tw-mt-5 sm:tw-mt-10 tw-mb-14 tw-grid-cols-1 sm:tw-grid-cols-2">
        <div>{title && <h1>{title}</h1>}</div>
        <div className="sm:tw-text-end">{action}</div>
      </div>
      <div className={useFullWidth ? '' : 'tw-max-w-7xl'}>{children}</div>
    </main>
  );
};

export default PageContainer;
