import { CooperationStatus } from '../api/@types/cooperation-status';
import { Cooperation } from '../api/cooperation/type';
import { FilterOptionValue } from './get-cooperation-status-of-filter-value';

const sortByUpdatedAtDescending = (a: Cooperation, b: Cooperation): 1 | -1 | 0 =>
  a?.updatedAt && b?.updatedAt ? (new Date(a?.updatedAt) < new Date(b?.updatedAt) ? 1 : -1) : 0;
const sortByCreatedAtAscending = (a: Cooperation, b: Cooperation): 1 | -1 | 0 =>
  a?.createdAt && b?.createdAt ? (new Date(a?.createdAt) > new Date(b?.createdAt) ? 1 : -1) : 0;

export const cooperationSorterMap: Record<
  FilterOptionValue,
  (cooperationA: Cooperation, cooperationB: Cooperation) => number
> = {
  // sort ascending by creation date
  applied: (a, b) => (a?.createdAt && b?.createdAt ? (new Date(a?.createdAt) > new Date(b?.createdAt) ? 1 : -1) : 0),
  // primary sort by status, secondary sort ascending by creation date
  negotiating: (a, b) => {
    if (a.status === CooperationStatus.Negotiating && b.status === CooperationStatus.ProposalSubmitted) {
      return -1;
    } else if (b.status === CooperationStatus.Negotiating && a.status === CooperationStatus.ProposalSubmitted) {
      return 1;
    }
    return sortByCreatedAtAscending(a, b);
  },
  // primary sort by status, secondary sort ascending by creation date
  active: (a, b) => {
    if (a.status === CooperationStatus.ContentCreation && b.status === CooperationStatus.ContentPublished) {
      return 1;
    } else if (b.status === CooperationStatus.ContentCreation && a.status === CooperationStatus.ContentPublished) {
      return -1;
    }
    return sortByCreatedAtAscending(a, b);
  },
  // sort descending by updated date
  declined: sortByUpdatedAtDescending,
  // sort descending by updated date
  archived: sortByUpdatedAtDescending,
};
