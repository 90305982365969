import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../utils/auth/use-auth';
import { SubmitButton } from './submit-button';

interface UserDisabledModalProps {
  show: boolean;
}

export const UserDisabledModal = ({ show }: UserDisabledModalProps) => {
  const { logout } = useAuth();

  return (
    <Modal show={show} centered backdrop="static">
      <strong className="mb-4">Dein Login wurde deaktiviert.</strong>
      <div className="model-dialog-actions">
        <SubmitButton onClick={logout} variant={'primary-gradient'}>
          Abmelden
        </SubmitButton>
      </div>
    </Modal>
  );
};
