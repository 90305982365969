import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMultiResponse } from '../type';
import { User } from './type';

type Response = HydraMultiResponse<User>;

export const USER_QUERY_KEY = 'user';

export const useGetCurrentPhpUser = () => {
  const { phpToken } = useAuth();

  const getCurrentPhpUser = async () => {
    const user = await httpFetch<Response>(`/api/users.jsonld`, { phpToken });
    return user;
  };

  return useQuery<Response>([USER_QUERY_KEY], getCurrentPhpUser);
};
