import { useQuery } from '@tanstack/react-query';
import { collection, getCountFromServer, limit, orderBy, query } from 'firebase/firestore';
import { onlyUnique } from '../../utils/only-unique';
import { useAuth } from '../../utils/auth/use-auth';

export const useFetchMessageExistence = (conversationIds: string[]) => {
  const { db, firebaseUser } = useAuth();

  const fetchMessageExistence = async (): Promise<boolean> => {
    if (!db) throw new Error('Database not available');

    // do this in a for loop because possibility that a cooperation has a message is high
    for (const conversationId of conversationIds.filter(onlyUnique)) {
      try {
        if (conversationId) {
          const conversationMessagesRef = collection(db, 'conversations', conversationId, 'messages');
          if (conversationMessagesRef) {
            const q = query(conversationMessagesRef, orderBy('timestamp', 'desc'), limit(1));
            const snapshot = await getCountFromServer(q);
            const messageCount = snapshot.data().count;
            if (messageCount > 0) {
              return true; // Found a cooperation with messages
            }
          }
        }
      } catch (error) {
        console.error('Requested conversation document messages subcollection does not exist', { conversationId });
      }
    }

    return false; // No cooperations with messages found
  };

  const {
    data: hasMessages,
    isLoading: loadingHasMessages,
    isError,
  } = useQuery<boolean>(
    ['messageExistence', firebaseUser, conversationIds.join('-')], // Unique key for the query based on conversation IDs
    fetchMessageExistence,
    {
      enabled: !!conversationIds.length && !!db && !!firebaseUser,
    },
  );

  return {
    hasMessages,
    loadingHasMessages,
    isError,
  };
};
