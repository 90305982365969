import { SVGProps } from 'react';

export const PinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M15.3838 3.73328L12.266 0.615479C11.6121 -0.0383853 10.6202 -0.188795 9.85128 0.248739C9.19308 0.624875 8.8013 1.32118 8.82821 2.06841C8.82995 2.10822 8.8256 2.20769 8.74944 2.26474L6.25782 4.13448C5.5365 4.67497 4.67693 4.71566 4.08618 4.65165C3.20226 4.56174 2.35903 4.93789 1.83404 5.66531C1.14471 6.62192 1.28574 8.00389 2.1627 8.88084L4.18304 10.9012L0.19003 14.8935C-0.0633432 15.1468 -0.0633432 15.5577 0.19003 15.8111C0.31628 15.9374 0.48235 16.0014 0.648413 16.0014C0.814475 16.0014 0.980548 15.9382 1.1068 15.8111L5.09981 11.8188L7.11926 13.8383C7.62342 14.3424 8.29378 14.6036 8.95527 14.6036C9.44394 14.6036 9.92823 14.4608 10.3346 14.1676C11.0629 13.6427 11.4417 12.8003 11.3483 11.9147C11.286 11.3222 11.325 10.4652 11.8655 9.74301L13.7352 7.25139C13.7923 7.17611 13.8891 7.17263 13.9324 7.17263C14.6683 7.19681 15.3742 6.80762 15.7512 6.14956C16.1889 5.3825 16.0383 4.38956 15.3845 3.7357L15.3838 3.73328Z"
      fill="#212122"
    />
  </svg>
);
