import { Form } from 'react-bootstrap';
import { CampaignFormGroup } from './campaign-form-group';
import { RichTextEditor } from '../library/rich-text-editor/rich-text-editor';
import { UpdateFormFn } from './campaign-form';

interface CampaignProductInformationProps {
  value?: string;
  updateForm: UpdateFormFn;
  error?: string;
}

export const CampaignProductInformation = ({ value, updateForm, error }: CampaignProductInformationProps) => {
  return (
    <CampaignFormGroup title={'Produktinformationen'} controlId={'campaignProductInformation'} lastElement={true}>
      <RichTextEditor
        value={value ?? ''}
        onChange={(newValue) => {
          if (newValue !== value) {
            // only update if the value has changed
            updateForm({ name: 'productInformation', value: newValue });
          }
        }}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </CampaignFormGroup>
  );
};
