import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { getApiErrorViolationsMap } from '../../utils/get-api-error-violations-map';
import { FullscreenModal } from '../fullscreen-modal/fullscreen-modal';
import { LoadingBoundary } from '../library/loading-boundary';
import { SubmitButton } from '../library/submit-button';
import { OrganizationForm } from './organization-form';
import { useOrganizationEditModal } from './use-organization-edit-modal';

interface OrganizationEditModalProps {
  onSuccess: () => void;
  onCancel: () => void;
  open?: boolean;
}

export const OrganizationEditModal = ({ onSuccess, onCancel, open = true }: OrganizationEditModalProps) => {
  const {
    handleSubmit,
    isLoading,
    error,
    isError,
    publishingDisabled,
    isFirstTimeEditing,
    patchOrganizationErrors,
    isPatchLoading,
    setFormData,
    organizationWithChanges,
  } = useOrganizationEditModal({ onSuccess });

  const getActions = () => {
    return (
      <>
        <Button variant={'link'} onClick={onCancel}>
          Abbrechen
        </Button>
        <SubmitButton
          onClick={handleSubmit}
          loading={isPatchLoading}
          disabled={publishingDisabled || isPatchLoading || isLoading}
          variant={'primary-gradient'}
        >
          Speichern
        </SubmitButton>
      </>
    );
  };

  return (
    <FullscreenModal
      title={`Unternehmensdaten ${isFirstTimeEditing ? 'erfassen' : 'bearbeiten'}`}
      actions={getActions()}
      open={open}
    >
      <Container fluid={true} className="tw-mt-20">
        <Form>
          <Container>
            <Row className={'pb-7'}>
              <Col className={'pb-7 form-title'}>
                <h1>Unternehmensdaten</h1>
                <p>Bitte alle Felder ausfüllen</p>
              </Col>
            </Row>
            <LoadingBoundary error={error} isError={isError} isLoading={isLoading}>
              <OrganizationForm
                errors={getApiErrorViolationsMap(patchOrganizationErrors)}
                formData={organizationWithChanges}
                setFormData={setFormData}
              />
            </LoadingBoundary>
          </Container>
        </Form>
      </Container>
    </FullscreenModal>
  );
};
