import { onAuthStateChanged, signInWithCustomToken, User } from '@firebase/auth';
import { useQueryClient } from '@tanstack/react-query';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'usehooks-ts';
import { auth, db } from '../../firebase';
import { getCookie, setCookie, unsetCookie } from '../cookies';
import { useSearchParamValue } from '../use-search-param-value';
import { Firestore } from 'firebase/firestore';

const TERMS_URL = 'https://www.simply-hooked.com/agb';
const PRIVACY_URL = 'https://www.simply-hooked.com/datenschutz';
const LEGAL_NOTICE_URL = 'https://www.simply-hooked.com/impressum';

interface AuthProviderProps {
  children: ReactNode;
}

type OnLoggedInFn = (newTokens: { phpToken?: string; phpRefreshToken?: string; firebaseToken?: string }) => void;

export const AuthContext = createContext<{
  phpToken?: string;
  refreshToken?: string;
  firebaseUser?: User;
  db?: Firestore;
  termsUrl: string;
  privacyUrl: string;
  legalNoticeUrl: string;
  onLoggedIn: OnLoggedInFn;
  logout: () => void;
}>({
  logout: () => console.error('Called logout on uninitialized auth context.'),
  onLoggedIn: () => console.error('Called onLoggedIn on uninitialized auth context.'),
  termsUrl: TERMS_URL,
  privacyUrl: PRIVACY_URL,
  legalNoticeUrl: LEGAL_NOTICE_URL,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isInsideAdminPanel] = useSearchParamValue<boolean>('isInsideAdminPanel', { defaultValue: false });
  const [firebaseUser, setFirebaseUser] = useState<User>();
  const [phpToken, setPhpToken] = useState<string>();
  const [phpRefreshToken, setPhpRefreshToken] = useState<string>();
  const [firebaseToken, setFirebaseToken] = useState<string>();
  const queryClient = useQueryClient();

  const onLoggedIn: OnLoggedInFn = ({ firebaseToken, phpRefreshToken, phpToken }) => {
    setPhpToken(phpToken);
    setFirebaseToken(firebaseToken);
    setPhpRefreshToken(phpRefreshToken);
    setCookie('token', phpToken ?? '');
    setCookie('refresh_token', phpRefreshToken ?? '');
    setCookie('firebase_token', firebaseToken ?? '');
  };

  // read tokens from cookies
  useEffectOnce(() => {
    setPhpToken(getCookie('token'));
    setPhpRefreshToken(getCookie('refresh_token'));
    setFirebaseToken(getCookie('firebase_token'));
  });

  const logout = useCallback(() => {
    unsetCookie('token');
    unsetCookie('refresh_token');
    unsetCookie('firebase_token');
    void auth.signOut();
    setPhpToken(undefined);
    setFirebaseToken(undefined);
    setPhpRefreshToken(undefined);
    if (queryClient) {
      queryClient.clear();
      void queryClient.cancelQueries();
    }
  }, [queryClient]);

  // login or logout user depending on content of local firebase token
  useEffect(() => {
    if (firebaseToken) {
      signInWithCustomToken(auth, firebaseToken)
        .then((userCredential) => {
          console.info('Logged into firebase with UID: ' + userCredential?.user?.uid);
          setFirebaseUser(userCredential.user);
        })
        .catch((error) => {
          console.error('Login to firebase failed', error);
        });
    } else {
      void auth.signOut();
    }
  }, [firebaseToken]);

  // handle firebase auth state changes
  useEffect(() => {
    if (firebaseUser) {
      // only handle logout with onAuthStateChanged, login is handled with signInWithCustomToken
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setFirebaseUser(user ?? undefined);
      });

      return unsubscribe;
    }
  }, [firebaseUser]);

  const isTokenExpired = (token: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;
    } catch (error) {
      console.error(error);
      return true;
    }
  };

  const refreshTokenFunc = useCallback(
    async (refreshToken?: string) => {
      try {
        if (!refreshToken) refreshToken = getCookie('refresh_token') ?? phpRefreshToken;
        console.info('Refreshing token');
        const response = await fetch(import.meta.env.VITE_BACKEND_HOST + '/api/token/refresh', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refresh_token: refreshToken }),
        });

        if (response.ok) {
          const data = (await response.json()) as
            | {
                token?: string;
                firebase_token?: string;
                refresh_token?: string;
              }
            | undefined;
          onLoggedIn({
            phpToken: data?.token ?? phpToken,
            firebaseToken: data?.firebase_token ?? firebaseToken,
            phpRefreshToken: data?.refresh_token ?? phpRefreshToken,
          });
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (error) {
        console.error('Failed to refresh token', error);
        logout();
      }
    },
    [firebaseToken, logout, phpRefreshToken, phpToken],
  );

  const checkTokenStatus = useCallback(() => {
    const token = getCookie('token');
    if (token) {
      if (isTokenExpired(token)) {
        void refreshTokenFunc();
      }
    } else if (isInsideAdminPanel) {
      // if the client is embedded in an admin-panel iframe try to get the token via messages
      const messageListener = function (
        this: Window,
        event: MessageEvent<{ action?: string; refreshToken?: string }>,
      ): void {
        // verify the origin of the parent window
        if (event.origin !== import.meta.env.VITE_BACKEND_HOST) {
          return;
        }

        if (event.data?.action === 'tokens') {
          const refreshToken = event.data?.refreshToken;
          setCookie('refresh_token', refreshToken ?? '');
          setPhpRefreshToken(refreshToken);
          void refreshTokenFunc(refreshToken);
        }

        window.removeEventListener('message', messageListener);
      };
      window.addEventListener('message', messageListener);

      window.parent.postMessage(
        {
          action: 'getTokens',
        },
        import.meta.env.VITE_BACKEND_HOST,
      );
    } else if (getCookie('refresh_token')) {
      // try to get a new token
      void refreshTokenFunc();
    } else {
      logout();
    }
  }, [isInsideAdminPanel, logout, refreshTokenFunc]);

  // check php token status every second
  useEffect(() => {
    checkTokenStatus();
    const intervalId = setInterval(() => {
      checkTokenStatus();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [checkTokenStatus]);

  const checkAdminPanelLogout = useCallback(async () => {
    // logout if the user is logged into firebase on
    // the admin panel and not logged in as an admin
    const idToken = await firebaseUser?.getIdTokenResult();
    if (isInsideAdminPanel && phpToken && idToken && !idToken.claims?.hasAdminRole) {
      logout();
      toast('Nutzer wird ausgeloggt und Admin wird eingeloggt', { type: 'info' });
    }
  }, [isInsideAdminPanel, logout, phpToken, firebaseUser]);

  useEffect(() => {
    void checkAdminPanelLogout();
  }, [checkAdminPanelLogout, isInsideAdminPanel, phpToken, firebaseUser]);

  return (
    <AuthContext.Provider
      value={{
        phpToken: phpToken ?? getCookie('token'),
        refreshToken: phpRefreshToken ?? getCookie('refresh_token'),
        onLoggedIn,
        logout,
        firebaseUser,
        db,
        legalNoticeUrl: LEGAL_NOTICE_URL,
        privacyUrl: PRIVACY_URL,
        termsUrl: TERMS_URL
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
