import { PlatformType } from '../api/@types/platform-type';

export const generatePlatformHandleLink = (platform: PlatformType, handle: string) => {
  switch (platform) {
    case PlatformType.Instagram:
      return `https://www.instagram.com/${handle}`;
    case PlatformType.Facebook:
      return `https://www.facebook.com/${handle}`;
    case PlatformType.Tiktok:
      return `https://www.tiktok.com/${handle}`;
    case PlatformType.Youtube:
      return `https://www.youtube.com/user/${handle}`;
    case PlatformType.Snapchat:
      return `https://www.snapchat.com/add/${handle}`;
    case PlatformType.Pinterest:
      return `https://www.pinterest.com/${handle}`;
  }
};
