import React, { MouseEventHandler, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CampaignRemunerationType } from '../../../api/@types/campaign-remuneration-type';
import { Campaign, PartialCampaign } from '../../../api/campaign/type';
import { usePatchCampaign } from '../../../api/campaign/use-patch-campaign';
import { usePostCampaign } from '../../../api/campaign/use-post-campaign';
import { usePostCampaignMediaObject } from '../../../api/campaign/use-post-campaign-media-object';
import { MediaObject } from '../../../api/mediaobject/type';
import { HydraMember } from '../../../api/type';
import { CampaignForm } from '../../../components/campaign-form/campaign-form';
import { FullscreenModal } from '../../../components/fullscreen-modal/fullscreen-modal';
import { SubmitButton } from '../../../components/library/submit-button';
import { getApiErrorViolationsMap } from '../../../utils/get-api-error-violations-map';

export const CreateCampaignPage = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [formData, setFormData] = useState<PartialCampaign>({});

  const { name, campaignType, campaignRemunerationType, platformType, startDate, endDate, remuneration, categories, currency } = formData;

  const publishingDisabled =
    !name?.trim() ||
    !campaignType ||
    !campaignRemunerationType ||
    !platformType ||
    !startDate ||
    !endDate ||
    !remuneration?.trim() ||
    categories?.length === 0 ||
    !selectedImage ||
    // disabled when remuneration type is fixed remuneration and currency is not set
    // when type is not fixed remuneration only remuneration needs to be set
    (campaignRemunerationType == CampaignRemunerationType.FixedRemuneration && !currency);

  const onPostCampaignError = (error: unknown) => {
    console.error('Error creating campaign:', error);
    toast('Beim veröffentlichen der Kampagne gab es einen Fehler. Bitte prüfe deine Eingabe', { type: 'error' });
  };

  const [createdCampaign, setCreatedCampaign] = useState<Campaign | undefined>();

  const onPostCampaignSuccess = (campaign: Campaign) => {
    setCreatedCampaign(campaign);
    if (selectedImage) postCampaignMediaObject(selectedImage);
  };

  const {
    mutate: postCampaign,
    isLoading: isPostingCampaign,
    error: postCampaignError,
    reset: resetCreateMutation,
  } = usePostCampaign({ onError: onPostCampaignError, onSuccess: onPostCampaignSuccess });

  const onPatchCampaignError = () => {
    console.error('Failed to patch campaign.');
  };

  const onPatchCampaignSuccess = (campaign: Campaign) => {
    toast('Die Kampagne wurde erfolgreich veröffentlicht!', { type: 'success' });
    navigate(`/app/campaigns/${campaign.internalId}`);
  };

  const { mutate: patchCampaign, isLoading: isPatchingCampaign } = usePatchCampaign({
    onError: onPatchCampaignError,
    onSuccess: onPatchCampaignSuccess,
  });

  const onPostCampaignMediaObjectError = () => {
    console.error('Failed to post campaign media object.');
  };

  const onPostCampaignMediaObjectSuccess = (mediaobject: HydraMember<MediaObject>) => {
    patchCampaign({
      teaserImage: `/api/media-objects/${mediaobject.internalId}`,
      internalId: createdCampaign?.internalId,
    });
  };

  const { mutate: postCampaignMediaObject, isLoading: isPostingMediaObject } = usePostCampaignMediaObject({
    onError: onPostCampaignMediaObjectError,
    onSuccess: onPostCampaignMediaObjectSuccess,
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    resetCreateMutation();

    postCampaign({
      currency: '',
      ...formData,
      categories: formData?.categories?.map((category) => `/api/campaign-categories/${category}`),
    });
  };

  const onCancelClick = () => navigate('/app/campaigns');

  const isLoading = isPostingCampaign || isPatchingCampaign || isPostingMediaObject;

  const getActions = () => {
    return (
      <React.Fragment>
        <Button variant={'link'} onClick={onCancelClick} disabled={isLoading}>
          Abbrechen
        </Button>
        <SubmitButton
          onClick={handleSubmit}
          loading={isLoading}
          variant={'primary-gradient'}
          disabled={isLoading || publishingDisabled}
        >
          Veröffentlichen
        </SubmitButton>
      </React.Fragment>
    );
  };

  return (
    <FullscreenModal title="Neue Kampagne" actions={getActions()}>
      <Container fluid={true}>
        <CampaignForm
          errors={getApiErrorViolationsMap(postCampaignError)}
          formData={formData}
          setFormData={setFormData}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </Container>
    </FullscreenModal>
  );
};
