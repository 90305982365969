import { useEffect, useRef, useState } from 'react';

interface OverflowTooltipProps {
  children: React.JSX.Element | string;
  title?: string;
  className?: string;
}

export const OverflowTooltip = ({ children, title, className = '' }: OverflowTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const measuringRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (measuringRef.current) {
      // show the tooltip only if there is an overflow
      // (meaning if the children rendered without line clamping would overflow)
      setShowTooltip(measuringRef.current.scrollHeight > measuringRef.current.offsetHeight);
    }
  }, [children]);

  return (
    <div
      className={`${className} tw-relative tw-w-auto align-middle tw-text-ellipsis tw-line-clamp-2`}
      title={showTooltip ? title : ''}
    >
      {children}
      {/* hidden element to measure element height if there was no line clamping */}
      <div
        ref={measuringRef}
        className={`${className} tw-absolute tw-top-0 tw-left-0 tw-w-auto tw-h-full tw-overflow-auto tw-invisible`}
      >
        {children}
      </div>
    </div>
  );
};
