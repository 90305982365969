import { Form } from 'react-bootstrap';
import { CampaignFormGroup } from '../campaign-form/campaign-form-group';
import { ChangeEventHandler } from 'react';
import { UpdateFormFn } from './organization-form';

interface OrganizationContactProps {
  contactValue?: string;
  emailValue?: string;
  updateForm: UpdateFormFn;
  contactError: string;
  emailError: string;
}

export const OrganizationContact = ({
  contactValue,
  emailValue,
  updateForm,
  contactError,
  emailError,
}: OrganizationContactProps) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateForm({ name: e.target.name, value: e.target.value });
  };

  return (
    <CampaignFormGroup title={'Kontaktperson'} controlId={'contact'} lastElement hideBorder>
      <Form.Control
        type="text"
        value={contactValue ?? ''}
        placeholder="Vorname Nachname ..."
        onChange={onChange}
        name={'contact'}
        isInvalid={!!contactError}
        required
        className="mb-3"
      />
      <Form.Control.Feedback type="invalid">{contactError}</Form.Control.Feedback>
      <Form.Control
        type="email"
        value={emailValue ?? ''}
        placeholder="Email-Adresse ..."
        onChange={onChange}
        name={'email'}
        isInvalid={!!emailError}
        required
      />
      <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
    </CampaignFormGroup>
  );
};
