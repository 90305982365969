import { ChangeEventHandler } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetCurrencies } from '../../api/common/use-get-currencies';
import { UpdateFormFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';
import {CampaignRemunerationType} from "../../api/@types/campaign-remuneration-type.ts";

interface CampaignRemunerationProps {
  campaignRemunerationType?: CampaignRemunerationType;
  value?: string | number;
  currency?: string;
  updateForm: UpdateFormFn;
  error: string;
  isPublished: boolean;
}

const NAME = 'remuneration';

export const CampaignRemuneration = ({
  campaignRemunerationType,
  value,
  currency,
  updateForm,
  error,
  isPublished,
}: CampaignRemunerationProps) => {
  const { t } = useTranslation();
  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCurrencies();

  const currentCurrency = currencies?.find(({ name }) => name === currency);

  const isFixedRemuneration = campaignRemunerationType == CampaignRemunerationType.FixedRemuneration;

  const onChange: ChangeEventHandler<HTMLInputElement> & ChangeEventHandler<HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    updateForm({ name, value });
  };

  return (
    <CampaignFormGroup title={'Vergütung'} controlId={'campaignRemuneration'} required={true}>
      <Container fluid={true}>
        <Row className="align-items-center">
          <Col lg={isFixedRemuneration ? 6 : 12} className="p-0">
            <Form.Control
              type={"text"}
              value={value}
              placeholder="Betrag/Provision/Produkt"
              onChange={onChange}
              name={NAME}
              isInvalid={!!error}
              required
            />
          </Col>
          {isFixedRemuneration && (
            <Col lg={4}>
              {isPublished ? (
                <span className={'text-size-2'}>{`${isLoadingCurrencies ? t("loading") : currentCurrency?.name ?? ''}`}</span>
              ) : (
                <Form.Select aria-label="Währung wählen ..." name={'currency'} value={currency} onChange={onChange}>
                  <option value="">Währung wählen ...</option>
                  {isLoadingCurrencies
                    ? <option value="" disabled>
                      {t("loading")}
                    </option>
                    : currencies?.map(({ name, }) => (
                      <option key={`currency-option-${name}`} value={name}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
              )}
            </Col>
          )}
        </Row>
      </Container>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      <>{!isPublished && <p className={'text-size-2 pt-3 mb-0'}>Beschreibe hier deine Vergütung.</p>}</>
    </CampaignFormGroup>
  );
};
