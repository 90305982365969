import { DetailedHTMLProps, HTMLAttributes } from 'react';
import './info-card.scss';

interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.JSX.Element;
}

export const InfoCard = (props: CardProps) => {
  const { children, className } = props;

  return (
    <div {...props} className={`info-card ${className}`}>
      {children}
    </div>
  );
};
