import { useTranslation } from 'react-i18next';
import { useGetCooperation } from '../../../api/cooperation/use-get-cooperation';
import { InfoCard } from '../../library/info-card/info-card';

interface CooperationInfluencerStatsProps {
  cooperationId: number;
}

export const CooperationInfluencerStats = ({ cooperationId }: CooperationInfluencerStatsProps) => {
  const { t } = useTranslation();
  const { data: cooperationResponse } = useGetCooperation(cooperationId);

  return (
    <span className="block tw-flex tw-gap-4">
      {cooperationResponse
        ? [
            {
              title: 'Follower',
              value: cooperationResponse?.currentFollowerCount
                ? cooperationResponse?.currentFollowerCount?.toLocaleString()
                : '',
            },
            {
              title: 'Impressionen',
              value: cooperationResponse?.currentImpressions
                ? cooperationResponse?.currentImpressions?.toLocaleString()
                : '',
            },
            {
              title: 'Engagementrate',
              value: cooperationResponse?.currentEngagementRate
                ? `${cooperationResponse?.currentEngagementRate.toFixed(1)}%`
                : '',
            },
          ]
            .sort((a, b) => {
              // sort data to show stats with values first
              if (!a.value) return 1;
              if (!b.value) return -1;
              return 0;
            })
            .map(({ title, value }) => (
              <InfoCard
                key={title}
                className={`tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-9 
                                                  ${!value ? 'invisible' : ''}`}
              >
                <>
                  <h3>{value}</h3>
                  {title}
                </>
              </InfoCard>
            ))
        : t('loading')}
    </span>
  );
};
