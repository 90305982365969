import { Campaign } from '../../api/campaign/type';
import { Cooperation } from '../../api/cooperation/type';
import { useResponsive } from '../../utils/use-responsive';
import { LoadingBoundary } from '../library/loading-boundary';
import { NoResultMessage } from '../library/no-result-message/no-result-message';
import { CooperationItem } from './cooperation-item/cooperation-item';

interface CooperationListProps {
  cooperations: Cooperation[];
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  campaign: Campaign;
}

export const CooperationList = ({ campaign, cooperations, isLoading, isError, error }: CooperationListProps) => {
  const { isDesktop } = useResponsive();

  const cooperationItems = cooperations.map((cooperation, idx) => (
    <CooperationItem
      key={cooperation.internalId}
      cooperation={cooperation}
      campaign={campaign}
      isLastRow={idx === cooperations.length - 1}
    />
  ));

  return (
    <LoadingBoundary isLoading={isLoading} isError={isError} error={error}>
      {cooperations.length === 0 ? (
        <NoResultMessage message={'Keine Kooperation gefunden'} />
      ) : isDesktop ? (
        // show table on desktop
        <table className="tw-w-full tw-table-auto">
          <tbody>{cooperationItems}</tbody>
        </table>
      ) : (
        // show list on mobile
        <div className="tw-w-full">{cooperationItems}</div>
      )}
    </LoadingBoundary>
  );
};
