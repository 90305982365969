import { format } from 'date-fns';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { PartialCampaign } from '../../api/campaign/type';
import { CampaignDraftWarning } from '../campaign-draft-warning/campaign-draft-warning';
import { CampaignApproach } from './campaign-approach';
import { CampaignCategories } from './campaign-categories';
import { CampaignDateSelector } from './campaign-date-selector';
import { CampaignPlatformType } from './campaign-platform-type';
import { CampaignProductInformation } from './campaign-product-information';
import { CampaignRemuneration } from './campaign-remuneration';
import { CampaignRequirements } from './campaign-requirements';
import { CampaignTeaserImage } from './campaign-teaser-image';
import { CampaignTitle } from './campaign-title';
import { CampaignType } from './campaign-type';
import { CampaignRemunerationType } from "./campaign-remuneration-type.tsx";

export type FormToggleChangeHandler = (event: { target: { name: string; value: string } }) => void;

interface CampaignFormProps {
  errors: Record<string, string>;
  formData: PartialCampaign;
  setFormData: Dispatch<SetStateAction<PartialCampaign>>;
  selectedImage?: File;
  setSelectedImage: (image?: File) => void;
}

type UpdateFn<T> = (e: { name: string; value: T }) => void;
export type UpdateFormFn = UpdateFn<string>;
export type UpdateFormCategoriesFn = UpdateFn<string[]>;
export type UpdateFormDatesFn = (e: { fromDate?: Date; endDate?: Date }) => void;

export const CampaignForm = ({ errors, formData, setFormData, selectedImage, setSelectedImage }: CampaignFormProps) => {
  const isPublished = !!(formData.status && formData.status !== 'draft');

  const onImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      setSelectedImage(event.target.files[0]);
    } else {
      setSelectedImage(undefined);
    }
  };

  const updateForm: UpdateFormFn & UpdateFormCategoriesFn = ({ name, value }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateFormDates: UpdateFormDatesFn = ({ fromDate, endDate }) => {
    setFormData((prevState) => ({
      ...prevState,
      startDate: fromDate ? format(fromDate, 'yyyy-MM-dd') : undefined,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : undefined,
    }));
  };

  return (
    <Form>
      <Container className={'pt-8'}>
        <Row>
          <Col>
            <CampaignDraftWarning className="pb-7" />
          </Col>
        </Row>
        <Row className={'pb-7'}>
          <Col className={'border-bottom pb-7'}>
            <h1>Basisinformationen</h1>
            <p>Bitte alle Felder ausfüllen</p>
          </Col>
        </Row>
        <CampaignTitle value={formData.name} updateForm={updateForm} error={errors.name} />
        <CampaignTeaserImage
          onChange={onImageChange}
          selectedImage={selectedImage}
          existingImage={formData.teaserImage?.contentUrl}
        />
        <CampaignType value={formData.campaignType} updateForm={updateForm} isPublished={isPublished} />
        <CampaignRemunerationType value={formData.campaignRemunerationType} updateForm={updateForm} isPublished={isPublished} />
        <CampaignPlatformType value={formData.platformType} updateForm={updateForm} isPublished={isPublished} />
        <CampaignDateSelector
          updateFormDates={updateFormDates}
          value={{ startDate: formData?.startDate, endDate: formData?.endDate }}
          isPublished={isPublished}
        />
        <CampaignRemuneration
          campaignRemunerationType={formData?.campaignRemunerationType}
          value={formData.remuneration}
          currency={formData.currency}
          updateForm={updateForm}
          error={errors.remuneration}
          isPublished={isPublished}
        />
        <CampaignCategories updateFormCategories={updateForm} value={formData.categories} />
      </Container>
      <hr />
      <Container className={'pt-7'}>
        <Row className={'pb-7'}>
          <Col className={'border-bottom pb-7'}>
            <h1>Kampagnenbriefing</h1>
            <p>Alle Felder sind optional. Achtung: zu viele Anforderungen können abschreckend auf Influencer wirken.</p>
          </Col>
        </Row>
        <CampaignApproach value={formData.approach} updateForm={updateForm} error={errors.approach} />
        <CampaignRequirements value={formData.requirements} updateForm={updateForm} error={errors.requirements} />
        <CampaignProductInformation
          value={formData.productInformation}
          updateForm={updateForm}
          error={errors.productInformation}
        />
        {errors.general && <Alert variant="danger">{errors.general}</Alert>}
      </Container>
    </Form>
  );
};
