import { useState } from 'react';
import { Badge, Offcanvas } from 'react-bootstrap';
import { Chat } from '../chat/chat';
import './support-conversation-list-item.scss';
import { Influencer } from '../../../../api/influencer/type';
import { useUnreadMessageCount } from '../../../../api/messaging/use-unread-message-count';
import { Organization } from '../../../../api/organization/type';
import { formatTimestamp } from '../../../../utils/format-timestamp';

interface SupportConversationListItemProps {
  className?: string;
  latestMessageTimestamp?: number;
  influencer?: Influencer;
  organization?: Organization;
}

export const SupportConversationListItem = ({
  className = '',
  latestMessageTimestamp,
  influencer,
  organization,
}: SupportConversationListItemProps) => {
  const conversationId = influencer?.supportConversationId ?? organization?.supportConversationId;
  const conversationPartnerName = influencer
    ? `${influencer?.firstname} ${influencer?.lastname}`
    : `${organization?.name} (${organization?.contact ?? 'Kontaktperson unbekannt'})`;

  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const { count: unreadMessageCount } = useUnreadMessageCount({ conversationId });

  const hideChat = () => setChatOpen(false);
  const showChat = () => setChatOpen(true);

  const unreadMessageBadge = !!(unreadMessageCount && unreadMessageCount !== 0) && (
    <Badge bg="primary">{unreadMessageCount}</Badge>
  );

  return (
    <div className={`conversation-list-item d-flex justify-content-between ${className}`}>
      <div onClick={showChat} className="d-flex pt-4 pb-4 gap-5 w-100">
        <div>
          <h4 className="d-flex align-items-center gap-1">
            {conversationPartnerName} {unreadMessageBadge}
          </h4>
          <p className="text-size-2 text-color-gray m-0">
            {!!latestMessageTimestamp && formatTimestamp(latestMessageTimestamp)}
          </p>
        </div>
      </div>
      <Offcanvas show={chatOpen} onHide={hideChat} placement={'end'}>
        {conversationId && (
          <Chat
            conversationId={conversationId}
            onClose={hideChat}
            conversationPartnerName={conversationPartnerName}
            hideMediakit={true}
          />
        )}
      </Offcanvas>
    </div>
  );
};
