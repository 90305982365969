import { useEffect, useState } from 'react';
import { useGetCampaign } from '../api/campaign/use-get-campaign';
import { Cooperation } from '../api/cooperation/type';
import { useGetInfluencer } from '../api/influencer/use-get-influencer';
import { useGetOrganization } from '../api/organization/use-get-organization';
import { useGetCurrentPhpUser } from '../api/user/use-get-current-php-user';
import { extractURLId } from './extract-url-id';

type UseCooperationPartner = ({ cooperation }: { cooperation: Cooperation }) => {
  cooperationPartnerName?: string;
  cooperationPartnerUID?: number;
};

/**
 * Hook to fetch the not logged in participants name of a cooperation.
 * - If the logged in participant is an organization, it returns the full name of the involved influencer.
 * - If the logged in participant is an influencer, it returns the full name of the involved organization contact.
 */
export const useCooperationPartner: UseCooperationPartner = ({ cooperation }) => {
  const [influencerId, setInfluencerId] = useState<number | undefined>();
  const [campaignId, setCampaignId] = useState<number | undefined>();
  const [organizationId, setOrganizationId] = useState<number | undefined>();

  const { data: userResponse } = useGetCurrentPhpUser();
  const { data: influencerResponse } = useGetInfluencer(influencerId);
  const { data: campaignResponse } = useGetCampaign(campaignId);
  const { data: organizationResponse } = useGetOrganization(organizationId);

  useEffect(() => {
    if (campaignResponse && campaignResponse.organization) {
      setOrganizationId(extractURLId(campaignResponse.organization));
    }
  }, [campaignResponse]);

  useEffect(() => {
    if (userResponse && userResponse?.['hydra:member']?.length > 0) {
      const user = userResponse['hydra:member'][0];
      const isUserOrganization = user?.organization;

      if (isUserOrganization) {
        // user is an organization => find the influencer's name
        const influencerId = extractURLId(cooperation.influencer);
        setInfluencerId(influencerId);
      } else {
        // user is an influencer => find the organization's name

        // get the organizationId via the campaign
        setCampaignId(extractURLId(cooperation.campaign));
      }
    }
  }, [cooperation.campaign, cooperation.influencer, userResponse]);

  return influencerResponse
    ? {
        cooperationPartnerUID: extractURLId(influencerResponse?.user),
        cooperationPartnerName: influencerResponse?.firstname
          ? `${influencerResponse?.firstname} ${influencerResponse?.lastname ?? ''}`
          : 'Name unbekannt',
      }
    : organizationResponse
      ? {
          cooperationPartnerUID: extractURLId(organizationResponse?.user),
          cooperationPartnerName: organizationResponse.contact || organizationResponse.name,
        }
      : {};
};
