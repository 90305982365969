import { useMutation } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { HydraMember } from '../type';
import { MediaObject } from './type';

interface UsePostMediaObjectProps {
  onSuccess: (mediaobject: HydraMember<MediaObject>) => void;
  onError: () => void;
}

export const usePostMediaObject = ({ onSuccess, onError }: UsePostMediaObjectProps) => {
  const { phpToken } = useAuth();

  const postMediaObject = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return httpFetch<HydraMember<MediaObject>>(`/api/media-objects`, {
      init: {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/ld+json',
        },
      },
      phpToken,
    });
  };

  return useMutation(postMediaObject, {
    onError: () => onError(),
    onSuccess: (mediaobject) => {
      onSuccess(mediaobject);
    },
  });
};
